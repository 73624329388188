import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import { getLanguages, changeLanguage, getCurrentLanguage } from '../../i18n';
import styles from './Language.module.scss';

interface Props {
  readonly?: boolean;
}

const Language: React.FC<Props> = ({ readonly }) => {
  const [language, setLanguage] = useState<string>();
  // const location = window.location;

  const handleLanguageSelected = (lang: string) => {
    changeLanguage(lang);
    setLanguage(lang);
  };

  useEffect(() => {
    //   let currentLng = getCurrentLanguage();
    //   if (!currentLng) {
    //     const defaultLanguage =
    //       new URLSearchParams(location.search).get('lang') ||
    //       new URLSearchParams(location.search).get('Lang') ||
    //       'fr';

    //     changeLanguage(defaultLanguage);
    //   }
    setLanguage(getCurrentLanguage());
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      <ButtonGroup className='mr-2' aria-label='First group'>
        {getLanguages().map((lang) => (
          <Button
            disabled={readonly}
            onClick={() => handleLanguageSelected(lang)}
            key={lang}
            variant={lang === language ? 'primary' : 'secondary'}
          >
            {lang}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default Language;
