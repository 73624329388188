// @ts-nocheck
import React, { useContext } from 'react';
import styles from './DropDownContexts.module.scss';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { useTranslation } from 'react-i18next';
import { SphereIcon, MyselfIcon } from '../header/Header';
import { ArrowDropDownLineIcon } from '../RemixIcons';
import SessionIcon from '../session/SessionIcon';
import { getSessionName } from '../../tools/sessionTools';
import AbilityContext from '../../context/AbilityContext';
import LayoutContext from '../../context/layout/LayoutContext';

const DropDownToggle = React.forwardRef(({ children, onClick }, ref) => {
  const layoutContext = useContext(LayoutContext);
  const { state: globalState } = useContext(GlobalStateContext);
  const { t } = useTranslation('i18n');
  const ability = useContext(AbilityContext);

  let icon: any;
  let title: string;
  if (globalState.context.sphereCtx) {
    const sphere = globalState.context.sphereCtx.sphere;
    if (
      sphere.photoUrls &&
      sphere.photoUrls.length > 0 &&
      sphere.photoUrls[0]
    ) {
      icon = (
        <img alt='logo' src={sphere.photoUrls[0]} className={styles.logo} />
      );
    } else {
      icon = <SphereIcon />;
    }

    title = t('app.sphere.title', {
      sphere: globalState.context.sphereCtx.sphere.name,
    });
  } else if (globalState.context.sessionCtx) {
    const session = globalState.context.sessionCtx.session;
    const sphere =
      globalState.spheres.find((s) => s.id === session.sphere.id) ||
      session.sphere;

    if (
      sphere.photoUrls &&
      sphere.photoUrls.length > 0 &&
      sphere.photoUrls[0]
    ) {
      icon = (
        <img alt='logo' src={sphere.photoUrls[0]} className={styles.logo} />
      );
    } else {
      icon = <SessionIcon session={session} />;
    }

    title = t('app.session.title', {
      session: `${getSessionName(
        globalState.context.sessionCtx.session,
        ability
      )}`,
    });
  } else {
    icon = <MyselfIcon />;
    title = t('app.myself.title');
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href=''
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        layoutContext.toggleContextMenu();
      }}
    >
      <div className={styles.titleContainer}>
        <span className={styles.itemIcon}>{icon}</span>
        <span className={styles.title}>{title}</span>
        <ArrowDropDownLineIcon />
      </div>
    </a>
  );
});
export default DropDownToggle;
