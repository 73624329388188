import React, { useState, useMemo } from 'react';
import ErrorNotificationContext from '../context/errorNotificationContext';
import { ErrorNotification } from '../@types/seen-apps';

function withErrorNotifications<T extends Record<string, any>>(
  WrappedComponent: React.ComponentType<T>
) {
  return (props: T) => {
    const [error, setError] = useState<ErrorNotification | undefined>(
      undefined
    );
    const ctx = useMemo(() => ({ error, setError }), [error]);
    return (
      <ErrorNotificationContext.Provider value={ctx}>
        <WrappedComponent {...props} />
      </ErrorNotificationContext.Provider>
    );
  };
}

export default withErrorNotifications;
