import { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import GoalForm from './GoalForm';
import { FeelingGoal } from '../../../@types/seen-apps';
import styles from './GoalForm.module.scss';

type Props = {
  show: boolean;
  onClose: () => void;
  value: FeelingGoal;
  onChange: (value: FeelingGoal) => void;
  onDelete: () => void;
};

const GoalFormModal: FC<Props> = ({
  show,
  value,
  onClose,
  onChange,
  onDelete,
}) => {
  const [valueInEdition, setValueInEdition] = useState<FeelingGoal>(value);

  useEffect(() => {
    setValueInEdition({ ...value, validated: false });
  }, [value]);

  const handleChange = (value: FeelingGoal) => {
    if (value.validated) {
      onChange(value);
    } else {
      setValueInEdition(value);
    }
  };

  return (
    <Modal
      className={styles.modal}
      show={show}
      onHide={onClose}
      size='xl'
      centered
    >
      <div className={styles.modalContent}>
        <GoalForm
          onChange={handleChange}
          onDelete={onDelete}
          value={valueInEdition}
        />
      </div>
    </Modal>
  );
};

export default GoalFormModal;
