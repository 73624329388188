import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { MemoryRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';
import './index.css';
import './custom_theme.scss';
import 'rc-tree/assets/index.css';
import './styles/rc-tree.scss';
import './styles/react-toastify.scss';
import './styles/react-tags-input.scss';
import './styles/react-datepicker.scss';

import 'react-date-range/dist/styles.css'; // main style file
import './styles/react-date-range.scss';
import './styles/reactdata-grid-blue-dark.css';

import config from './config';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ServiceWorkerProvider } from './context/ServiceWorkerProvider';

const isLocal = config.Env === 'local';

if (!isLocal) {
  Sentry.init({
    dsn: 'https://e10c3909288049f5b3adbdfb6e786e31@o311291.ingest.sentry.io/6095112',
    environment: config.Env,
    release: `${process.env.REACT_APP_VERSION}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <MemoryRouter>
      <ServiceWorkerProvider>
        <App />
      </ServiceWorkerProvider>
    </MemoryRouter>
  </I18nextProvider>,
  document.getElementById('root')
);
