import React from 'react';
import {
  StrategyCustomIcon,
  StrategyGoalIcon,
  StrategyIcon,
  StrategyMissionIcon,
  StrategyStrategicAxisIcon,
  StrategyActionIcon,
  StrategyVisionIcon,
} from '../components/RemixIcons';

export const getStrategyIcon = (strategyType: string, props?: any) => {

  let icon: React.FC;
  if (strategyType === 'custom') {
    icon = StrategyCustomIcon;
  } else if (strategyType === 'mission') {
    icon = StrategyMissionIcon;
  } else if (strategyType === 'goal') {
    icon = StrategyGoalIcon;
  } else if (strategyType === 'strategicAxis') {
    icon = StrategyStrategicAxisIcon;
  } else if (strategyType === 'action') {
    icon = StrategyActionIcon;
  } else if (strategyType === 'vision') {
    icon = StrategyVisionIcon;
  } else {
    icon = StrategyIcon;
  }
  return React.createElement(icon, props);
};
