import { useContext, useEffect, useState } from 'react';
import { Filters, FacetValue } from '../../@types/seen-apps';
import MultiSelectFacet, { OptionType } from './MultiSelectFacet';
import { useTranslation } from 'react-i18next';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';

const GoalMultiSelectFacet = () => {
  const { t } = useTranslation('i18n');

  const {
    state: { goalRankings },
  } = useContext(GlobalStateContext);

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(!goalRankings);
  }, [goalRankings]);

  return (
    <MultiSelectFacet
      label={t('common.filters.feeling.goals.goals')}
      placeholder=''
      isLoading={isLoading}
      isMulti={true}
      isClearable={true}
      isSearchable={true}
      facetName='goalIds'
      convertFacetsToOptions={async (facets: FacetValue[]) => {
        const data = [...facets];

        if (goalRankings) {
          data.sort((f1, f2) => {
            const rank1 = goalRankings[`goal_${f1.value}`] || 20;
            const rank2 = goalRankings[`goal_${f2.value}`] || 20;

            return rank1 - rank2;
          });
        }

        return data.map((f) => {
          return {
            count: f.count,
            label: t(`report.goalForm.axes.${f.label || f.value}.label`),
            value: f.value,
          };
        });
      }}
      updateFilters={(filters: Filters, options: OptionType[]) => {
        const updates = {
          ...filters,
          feeling: {
            ...(filters.feeling || {}),
            goalIds: options.map((o) => o.value),
          },
        };
        return updates;
      }}
      optionsSelected={(filters: Filters, options: OptionType[]) => {
        const goalIds = (filters.feeling.goalIds || []) as string[];
        return options.filter((option) =>
          goalIds.some((id) => option.value === id)
        );
      }}
    />
  );
};

export default GoalMultiSelectFacet;
