import React, { useState } from 'react';
import styles from './QrcodeLink.module.scss';
import {
  Container,
  Row,
  Col,
  Form,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import QrCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import { captureException } from '@sentry/minimal';
import RoundedButton from '../RoundedButton/RoundedButton';
import { DownloadIcon, LinkIcon, ScanIcon } from '../RemixIcons';

declare class ClipboardItem {
  constructor(data: { [mimeType: string]: Blob });
}

interface Props {
  link: string;
  compact?: boolean;
  linkTitle?: string;
  qrCodeTitle?: string;
  subTitleTop?: string;
  subTitleBottom?: string;
  renderMenu?: (items: React.ReactElement[]) => React.ReactElement[];
}

const QrcodeLink = ({
  linkTitle,
  qrCodeTitle,
  link,
  subTitleBottom,
  subTitleTop,
  compact,
  renderMenu,
}: Props) => {
  const { t } = useTranslation('i18n');

  const [linkKey] = useState(`lnk_${uuid()}`.replace(/-/gi, ''));

  const downloadQRCode = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const doc = document.getElementById(`QrCode${linkKey}`);
    if (!doc) {
      return;
    }

    const classNames = doc.className;
    doc.className = classNames
      .split(' ')
      .filter((cn: string) => cn.indexOf('hiddenComponent') < 0)
      .join(' ');

    html2canvas(doc).then(function (canvas) {
      var anchorTag = document.createElement('a');
      document.body.appendChild(anchorTag);
      anchorTag.download = 'seen-app-link.png';
      anchorTag.href = canvas.toDataURL();
      anchorTag.target = '_blank';
      anchorTag.click();

      doc.className = classNames;
    });
  };
  const copyQRCode = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const doc: any = document.getElementById(`QrCode${linkKey}`);
    const classNames = doc.className;
    doc.className = classNames
      .split(' ')
      .filter((cn: string) => cn.indexOf('hiddenComponent') < 0)
      .join(' ');
    html2canvas(doc).then(function (canvas) {
      var anchorTag = document.createElement('a');
      document.body.appendChild(anchorTag);
      anchorTag.download = 'seen-app-link.png';
      canvas.toBlob(
        async (blob: any) => {
          try {
            let anyNavigator: any;
            anyNavigator = window.navigator;
            const clipboardItemInput = new ClipboardItem({
              'image/png': blob,
            });
            await anyNavigator.clipboard.write([clipboardItemInput]);
            toast.success(t('common.copied'));
            doc.className = classNames;
          } catch (error) {
            captureException(error);
            toast.success(t('common.error'));
            doc.className = classNames;
          }
        },
        'image/png',
        1
      );
    });
  };

  const copyLink = () => {
    var copyText: any = document.querySelector(`#${linkKey}`);

    if (copyText) {
      const classNames = copyText.className;
      copyText.className = classNames
        .split(' ')
        .filter((cn: string) => cn.indexOf('hiddenComponent') < 0)
        .join(' ');
      copyText.select();
      document.execCommand('copy');
      toast.success(t('common.copied'));
      copyText.className = classNames;
    }
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const menus = [
    <Dropdown.Item key={`copy-link`} onClick={copyLink}>
      <LinkIcon /> {t('common.copyLinkToClipboard')}
    </Dropdown.Item>,
    <Dropdown.Item
      as={Link}
      key={`copy-image`}
      to='/'
      target='_blank'
      className={`btn btn-outline-primary ${styles.qrCodeDownload}`}
      onClick={copyQRCode}
    >
      <ScanIcon /> {t('common.copyImageToClipboard')}
    </Dropdown.Item>,
    <Dropdown.Item
      as={Link}
      key={`download-image`}
      to='/'
      target='_blank'
      className={`btn btn-outline-primary ${styles.qrCodeDownload}`}
      onClick={downloadQRCode}
    >
      <DownloadIcon /> {t('common.downloadImage')}
    </Dropdown.Item>,
  ];

  return (
    <Container className={styles.noMargin}>
      <Row className={styles.noMargin}>
        <Col sm={12} className={styles.noMargin}>
          {linkTitle}
        </Col>
      </Row>
      <Row className={styles.noMargin}>
        <Col sm={12} className={`${styles.noMargin} ${styles.linkContainer}`}>
          <Form.Control
            id={linkKey}
            type='text'
            value={link}
            readOnly={true}
            className={compact ? styles.hiddenComponent : ''}
          />

          {compact ? (
            <DropdownButton
              variant='outline'
              title={''}
              id='copyOptions'
              as={RoundedButton}
              onClick={handleClick}
            >
              {renderMenu ? renderMenu(menus) : menus}
            </DropdownButton>
          ) : (
            <button
              type='button'
              className={`btn btn-outline-primary ${styles.copyButton}`}
              onClick={copyLink}
            >
              {t('common.copyToClipboard')}
            </button>
          )}
        </Col>
      </Row>
      {qrCodeTitle && (
        <>
          <hr />
          <Row className={styles.noMargin}>
            <Col sm={12} className={styles.noMargin}>
              {qrCodeTitle}
            </Col>
          </Row>
          <hr />
        </>
      )}

      <Row className={styles.noMargin}>
        <Col
          sm={12}
          className={`${styles.noMargin} ${
            compact ? styles.qrCodeContainerCompact : styles.qrCodeContainer
          }`}
        >
          <div
            className={`${styles.qrcodeBlock} ${
              compact ? styles.hiddenComponent : ''
            }`}
            id={`QrCode${linkKey}`}
          >
            {subTitleTop && (
              <span className={styles.subtitleTop}>{subTitleTop}</span>
            )}
            <QrCode
              className={styles.qrcode}
              value={link}
              size={256}
              bgColor={'#ffffff'}
              fgColor={'#0d1939'}
              level={'L'}
              includeMargin={false}
              imageSettings={{
                src: '/logo.png',
                height: 70,
                width: 70,
                excavate: false,
              }}
            />
            {subTitleBottom && (
              <span className={styles.subtitleBottom}>{subTitleBottom}</span>
            )}
          </div>
          {!compact && (
            <>
              <div className={styles.separator}></div>
              <div className={styles.qrCodeActions}>
                <Link
                  to='/'
                  target='_blank'
                  className={`btn btn-outline-primary ${styles.qrCodeDownload}`}
                  onClick={downloadQRCode}
                >
                  {t('common.download')}
                </Link>

                <Link
                  to='/'
                  target='_blank'
                  className={`btn btn-outline-primary ${styles.qrCodeDownload}`}
                  onClick={copyQRCode}
                >
                  {t('common.copyToClipboard')}
                </Link>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default QrcodeLink;
