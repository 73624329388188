import React, { useEffect, useState } from 'react';
import { Container, Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import styles from './EnterCode.module.scss';
import { toast } from 'react-toastify';
import config from '../../config';

interface EnterCodePageProps {
  size?: 'small' | 'large';
}

const EnterCode: React.FC<EnterCodePageProps> = ({ size }) => {
  const { t } = useTranslation('i18n');

  const [code, setCode] = useState<string>('');
  const [invalid, setInvalid] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const handleInputChange = (value: string) => {
    setCode(value);
    setInvalid(false);
  };

  const handleCheckCode = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setSaving(true);

    window.location.href = `${config.shortenUrl}/${code}`;

    setSaving(false);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const errorCode = url.searchParams.get('error') || '';
    if (errorCode) {
      toast.error(t(`connection.code.error.${errorCode}`));
      setInvalid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form onSubmit={handleCheckCode}>
      {size === 'small' ? (
        <InputGroup>
          <Form.Control
            type='text'
            id='code'
            placeholder={t('connection.code.title')}
            value={code}
            onChange={(event) => handleInputChange(event.target.value)}
            autoFocus
            isInvalid={invalid}
          />
          <InputGroup.Append>
            <Button
              disabled={saving || !code}
              variant='primary'
              className={styles.left}
              type='submit'
            >
              {saving && (
                <div className={styles.submitContent}>
                  <ReactLoading
                    type={'bars'}
                    color={'#DDD'}
                    height={'30px'}
                    width={'40px'}
                  />
                  <div className={styles.submitText}>
                    {t('connection.code.checking')}
                  </div>
                </div>
              )}
              {!saving && t('connection.code.check')}
            </Button>
          </InputGroup.Append>
        </InputGroup>
      ) : (
        <Container className={styles.noMargin}>
          <Row className={styles.noMargin}>
            <Col sm={12} className={styles.noMargin}>
              <Form.Group controlId='code'>
                <Form.Label>{t('connection.code.title')}</Form.Label>
                <Form.Control
                  type='text'
                  id='code'
                  placeholder={t('connection.code.placeHolder')}
                  value={code}
                  onChange={(event) => handleInputChange(event.target.value)}
                  autoFocus
                  isInvalid={invalid}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className={styles.noMargin}>
            <Col className={styles.noMargin}>
              <Button
                disabled={saving || !code}
                variant='primary'
                className={styles.left}
                type='submit'
              >
                {saving && (
                  <div className={styles.submitContent}>
                    <ReactLoading
                      type={'bars'}
                      color={'#DDD'}
                      height={'30px'}
                      width={'40px'}
                    />
                    <div className={styles.submitText}>
                      {t('connection.code.checking')}
                    </div>
                  </div>
                )}
                {!saving && t('connection.code.check')}
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </Form>
  );
};

export default EnterCode;
