import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CopyIcon } from '../RemixIcons';

type Props = {
  value: string;
  hideLabel?: boolean;
  className?: string;
};

const IdentifierInput: React.FC<Props> = ({ value, hideLabel, ...props }) => {
  const { t } = useTranslation('i18n');

  const copyLink = () => {
    navigator.clipboard.writeText(value);
    toast.success(t('common.copied'));
  };

  return (
    <Form.Group controlId='id' {...props}>
      {!hideLabel && <Form.Label>{t('common.id')}</Form.Label>}
      <InputGroup className='mb-3'>
        <Form.Control type='text' value={value} disabled />
        <Button onClick={copyLink} variant='outline-primary' id='button-copy'>
          <CopyIcon />
        </Button>
      </InputGroup>
    </Form.Group>
  );
};

export default IdentifierInput;
