import styles from './PreviewContainer.module.scss';
import { PreviewAccessIcon } from '../RemixIcons';

const PreviewContainer = ({ children, className, ...props }: any) => {
  return (
    <div className={`${styles.root} ${className || ''}`} {...props}>
      <span className={styles.title}>
        <PreviewAccessIcon /> Preview
      </span>
      {children}
    </div>
  );
};

export default PreviewContainer;
