import {
  DisplayCondition,
  EqualsCondition,
  ExistCondition,
} from '../@types/form-api';
import { uniq } from 'lodash';

const GET_KEY_BETWEEN_BRACKET = /\{(.*?)\}/g;

export const evaluateExistsCondition = (
  condition: ExistCondition,
  data: Record<string, any>
): any => {
  const keys = Array.isArray(condition.exists)
    ? condition.exists
    : [condition.exists];
  return keys.reduce((acc, key) => {
    const value = data[key];
    return acc && value !== undefined;
  }, true);
};

export const evaluateEqualsCondition = (
  condition: EqualsCondition,
  data: Record<string, any>
): any => {
  return Object.keys(condition.equals).reduce((acc, key) => {
    const value = data[key];
    return acc && value === condition.equals[key];
  }, true);
};

export const evaluateConditions = (
  conditions: DisplayCondition | DisplayCondition[] | undefined,
  data: Record<string, any> | undefined
): any => {
  if (!conditions) {
    return true;
  }
  const conditionsToEval = Array.isArray(conditions)
    ? conditions
    : [conditions];
  return conditionsToEval.some((condition) =>
    evaluateCondition(condition, data || {})
  );
};

const evaluateCondition = (
  condition: DisplayCondition,
  data: Record<string, any>
): any => {
  let result = true;
  if ((condition as ExistCondition).exists) {
    result = evaluateExistsCondition(condition as ExistCondition, data);
  }
  if ((condition as EqualsCondition).equals) {
    result =
      result && evaluateEqualsCondition(condition as EqualsCondition, data);
  }
  return result;
};

export const replaceDynamicValues = (
  text: string | undefined,
  data: Record<string, any>
) => {
  if (!text) {
    return '';
  }
  const matches = Array.from(text.matchAll(GET_KEY_BETWEEN_BRACKET));
  const keys = uniq(matches.map((match) => match[1]));

  let result = keys.reduce((acc, key) => {
    return acc.replace(`{${key}}`, `${data[key] || ''}`.trim());
  }, text || '');

  return result || '';
};

export const hasDynamicValues = (text: string | undefined) => {
  if (!text) {
    return false;
  }
  const matches = Array.from(text.matchAll(GET_KEY_BETWEEN_BRACKET));
  const keys = uniq(matches.map((match) => match[1]));

  return keys.length > 0;
};

