import { useTranslation } from 'react-i18next';
import Select, { FormatOptionLabelMeta } from 'react-select';
import { GOALS_AND_SUB } from '../../../tools/goalTools';
import { useContext, useEffect, useState } from 'react';

import selectStyles from '../../../styles/react-select.module.scss';
import styles from './GoalForm.module.scss';
import api from '../../../api/Api';
import moment from 'moment-timezone';
import { Alert, Badge } from 'react-bootstrap';
import MyselfAddFormModal from '../../../pages/feeling/MyselfAddFormModal';
import { FormSurvey } from '../../../@types/form-api';
import GlobalStateContext from '../../../context/globalState/GlobalStateContext';
import { SET_GOAL_RANKINGS } from '../../../context/globalState/globalStateReducer';

export type Option = {
  label: string;
  value: string;
  ranking: number;
};

type GoalSelectProps = {
  className?: string;
  disabled?: boolean;
  value: string[];
  onChange: (value: string[]) => void;
};

const MultiGoalSelect = ({
  disabled,
  onChange,
  className,
  value,
}: GoalSelectProps) => {
  const { t } = useTranslation('i18n');

  const {
    state: { goalRankings },
    dispatch,
  } = useContext(GlobalStateContext);

  const [initialized, setInitialized] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [formGoalVisible, setFormGoalVisible] = useState(false);
  const [goalCompleted, setGoalCompleted] = useState(true);

  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    const loadGoals = async () => {
      const [survey] = await api.getFormSurveys(
        moment().startOf('year').subtract(3, 'year').toDate(),
        moment().endOf('year').toDate(),
        {
          formId: 'AQ8579T6LZR',
          max: 1,
        }
      );
      dispatch({
        type: SET_GOAL_RANKINGS,
        payload: survey?.ranking || {},
      });
    };

    if (!goalRankings) {
      loadGoals();
    } else {
      setInitialized(true);
    }
  }, [dispatch, goalRankings]);

  useEffect(() => {
    setGoalCompleted(!!Object.keys(goalRankings || {})?.length);
  }, [goalRankings]);
  useEffect(() => {
    const selectedOptions = value
      .map((goalId) => options.find((option) => goalId === option.value))
      .filter((o) => !!o);
    setSelectedOptions(selectedOptions as Option[]);
  }, [value, options]);

  useEffect(() => {
    if (!goalRankings) {
      return;
    }
    const options = GOALS_AND_SUB.map((goal) => {
      return {
        value: goal.id,
        label: t(`report.goalForm.axes.${goal.id}.label`),
        ranking: goalRankings[`goal_${goal.id}`] || 20,
      };
    });

    options.sort((a, b) => a.ranking - b.ranking);
    setOptions(options);
    setInitialized(true);
  }, [goalRankings, t]);

  const formatOptionLabel = (
    option: Option,
    labelMeta: FormatOptionLabelMeta<Option, true>
  ): React.ReactNode => {
    let label: string;
    if (option.value) {
      label = option.label;
    } else {
      label = '';
    }

    return labelMeta.context === 'menu' ? (
      <div className={styles.item}>
        <span className={styles.title}>{label} </span>
        {!!option.ranking && option.ranking <= 5 && (
          <Badge variant='secondary' className={styles.ranking}>
            {option.ranking}
          </Badge>
        )}
      </div>
    ) : (
      <div className={styles.itemValue}>
        <span className={styles.titleValue}>{label} </span>
        {!!option.ranking && (
          <Badge variant='secondary' className={styles.rankingValue}>
            {option.ranking}
          </Badge>
        )}
      </div>
    );
  };

  const handleOnChange = (selected: Option[]) => {
    onChange(selected?.map((s) => s.value) || []);
  };

  const handleFormClose = (survey?: FormSurvey) => {
    if (survey) {
      dispatch({
        type: SET_GOAL_RANKINGS,
        payload: survey.ranking,
      });
    }
    setFormGoalVisible(false);
  };

  return !goalCompleted ? (
    <div className={styles.form}>
      <Alert
        className={styles.formLink}
        variant='info'
        onClick={() => setFormGoalVisible(true)}
      >
        {t(`report.goalForm.notCompleted`)}
      </Alert>
      {formGoalVisible && (
        <MyselfAddFormModal
          formId='AQ8579T6LZR'
          show={formGoalVisible}
          onClose={handleFormClose}
        />
      )}
    </div>
  ) : (
    <Select
      key={`goal-selection-${options.length}`}
      isMulti
      options={options}
      isDisabled={disabled || !initialized}
      isLoading={!initialized}
      className={`${selectStyles.reactSelect} ${className || ''}`}
      classNamePrefix='custom-react-select'
      onChange={handleOnChange as any}
      isSearchable={true}
      value={selectedOptions as any}
      noOptionsMessage={() => t('common.noValue')}
      placeholder={''}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export default MultiGoalSelect;
