import React from 'react';
import pageStyles from './StrategyAdminPage.module.scss';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'react-bootstrap';

import StrategiesForm from '../../components/strategy/StrategiesForm';

import stylesModal from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import { StrategyVisionIcon } from '../../components/RemixIcons';

const styles = { ...stylesModal, ...pageStyles };

const dicoPrefix = 'pages.myself-strategy';

const MyselfStrategyPage: React.FC<{ onSubmit?: () => void }> = ({
  onSubmit,
}) => {
  const { t } = useTranslation('i18n');

  return (
    <div className={styles.page}>
      <PageTitle
        rendertitle={() => (
          <div className={styles.titleContainer}>
            <StrategyVisionIcon />
            {t(`pages.myself-strategy.title`)}
          </div>
        )}
      />
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <Alert className={styles.description} variant='info'>
            {t(`${dicoPrefix}.description.myself`)}
          </Alert>

          <StrategiesForm isMyself />
          <div className={styles.buttons}>
            <Button
              variant='secondary'
              className={styles.left}
              onClick={onSubmit}
            >
              {t('common.action.close')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyselfStrategyPage;
