import { useContext, useState, FC, useEffect } from 'react';
import stylesUpdateProfile from './UpdateStrategyAssessmentPage.module.scss';
import stylesModal from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import { useTranslation } from 'react-i18next';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { useLocation } from 'react-router-dom';
import { Container, Col, Row, Button } from 'react-bootstrap';
import api from '../../api/Api';
import { toast } from 'react-toastify';
import { UPDATE_THINKING } from '../../context/globalState/globalStateReducer';
import moment from 'moment';
import SphereNameView from '../../components/sphere/SphereNameView';
import Question, {
  getStrategyAssessmentOptions,
} from '../../components/question/Question';
import LoadingPage from '../loading/LoadingPage';
import DateView from '../../components/date/DateView';
import { isNil } from 'lodash';
import PageTitle from '../../components/PageTitle/PageTitle';
import SessionNameView from '../../components/session/SessionNameView';
import { captureException } from '@sentry/minimal';
import { isMobile } from 'react-device-detect';
import { StrategyAssessment } from '../../@types/strategy-api';
import { Sphere } from '../../@types/sphere-api';
import { getRelevantTranslationFor } from '../../tools/multiLingualTools';

const styles = { ...stylesModal, ...stylesUpdateProfile };

type Props = {
  onSubmit?: (state?: any) => void;
};

const StrategyAssessmentPage: FC<Props> = ({ onSubmit = () => {} }) => {
  const { state, dispatch } = useContext(GlobalStateContext);
  const location = useLocation();
  const { t } = useTranslation('i18n');
  const assessmentId =
    new URLSearchParams(location.search).get('assessmentId') || '';
  const [assessment, setAssessment] = useState<StrategyAssessment | undefined>(
    undefined
  );
  const [sphere, setSphere] = useState<Sphere | undefined>(undefined);
  const [values, setValues] = useState<{ id: string; value?: number }[]>([]);
  const [couldUpdate, setCouldUpdate] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dicoPrefix, setDicoPrefix] = useState('pages.strategy');
  const [options] = useState(getStrategyAssessmentOptions({ t }));

  useEffect(() => {
    const loadAssessment = async (assessmentId: string) => {
      try {
        const assessmentResponse = await api.getStrategyAssessment(
          assessmentId
        );
        const couldUpdate =
          !!assessmentResponse &&
          !!assessmentResponse.freezeDate &&
          !!moment(assessmentResponse.freezeDate).isAfter(moment());
        setCouldUpdate(couldUpdate);
        setAssessment(assessmentResponse);
        setSphere(
          assessmentResponse?.sphere
            ? state.spheres.find((s) => s.id === assessmentResponse!.sphere!.id)
            : undefined
        );
        const values = Object.entries(assessmentResponse?.values || {}).reduce(
          (acc, [id, value]) => {
            return [...acc, { id, value }];
          },
          [] as { id: string; value?: number }[]
        );
        setValues(values);
        setLoading(false);
        setDicoPrefix(
          assessmentResponse.directLinkTo === 'myself'
            ? 'pages.myself-strategy'
            : 'pages.strategy'
        );
      } catch (error) {
        captureException(error);
        toast.error(t('common.error'));
        onSubmit();
      }
    };

    if (!loading) {
      setLoading(true);
      loadAssessment(assessmentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentId]);

  const handleSaveAssessment = async (assessmeent: StrategyAssessment) => {
    try {
      const assessmentValue = values.reduce(
        (acc, strategyValue) =>
          isNil(strategyValue.value)
            ? acc
            : { ...acc, [strategyValue.id]: strategyValue.value },
        {} as Record<string, number>
      );
      const result = await api.updateStrategyAssessment(assessmeent.id, {
        values: assessmentValue,
      });
      toast.success(t('pages.strategyAssessment.updated.success'));
      dispatch({
        type: UPDATE_THINKING,
        payload: result,
      });
      onSubmit();
    } catch (error) {
      captureException(error);
      toast.error(t('pages.strategyAssessment.updated.error', { error }));
    }
  };

  const handleSelection = (id: string, value?: number) => {
    const newAxes = [...values];
    const axisIndex = values.findIndex((a) => id === a.id);
    if (axisIndex >= 0) {
      const axis = newAxes[axisIndex];
      newAxes[axisIndex] = { ...axis, value };
    } else {
      newAxes.push({
        id,
        value,
      });
    }
    setValues(newAxes);
  };

  if (loading) {
    return <LoadingPage />;
  }
  if (!assessment) {
    return <div>{t('common.not-found.strategyAssessment')}</div>;
  }
  return (
    <div className={styles.page}>
      <PageTitle title={t(`pages.strategyAssessment.update.title`)} />
      <div className={styles.content}>
        <Container className={styles.updateThinkingContainer}>
          <Row className={styles.updateThinkingRow}>
            <Col className={styles.updateThinkingCol}>
              <div className={styles.thinkingDetails}>
                <DateView date={assessment.creationDate} full />
                {assessment.session && (
                  <SessionNameView session={assessment.session} />
                )}
                <SphereNameView
                  sphere={sphere}
                  full
                  sphereName={assessment.sphere?.name}
                />
              </div>
            </Col>
          </Row>
          {(assessment.strategies || []).map((strategy) => {
            const axis = values.find((a) => strategy.id === a.id);
            return (
              <Question
                key={`axis-${strategy.id}`}
                title={getRelevantTranslationFor(strategy.title)}
                superTitle={
                  strategy.type === 'custom'
                    ? undefined
                    : t(`${dicoPrefix}.type.${strategy.type}`)
                }
                options={options}
                questionLabel={getRelevantTranslationFor(strategy.description)}
                value={axis ? axis.value : undefined}
                onValueChange={(value) => handleSelection(strategy.id, value)}
                disabled={!couldUpdate}
                isMobile={isMobile}
              />
            );
          })}
          <Row className={styles.updateThinkingRow}>
            <Col sm={12} className={styles.buttons}>
              <Button variant='secondary' onClick={() => onSubmit()}>
                {t('common.action.close')}
              </Button>
              {assessment && couldUpdate && (
                <Button
                  variant='primary'
                  onClick={() => handleSaveAssessment(assessment)}
                >
                  {t('common.action.save')}
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default StrategyAssessmentPage;
