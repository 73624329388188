import React, { useState, useContext, FC, useRef } from 'react';
import feelingStyles from './FeelingConsumerPage.module.scss';
import modalStyles from '../../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import Histogram from '../../../components/histogram/Histogram';
import HappyFeelingIcon from '../../../assets/images/happyFeeling.svg';
import SadFeelingIcon from '../../../assets/images/sadFeeling.svg';
import happyFace from '../../../assets/images/Happy_64.png';
import notHappyFace from '../../../assets/images/NotHappy_64.png';
import { toast } from 'react-toastify';

import battery from '../../../assets/images/battery.png';
import { DeviceUUID } from 'device-uuid';
import runIcon from '../../../assets/images/run.svg';
import { useTranslation } from 'react-i18next';
import api from '../../../api/Api';

import { Container, Button } from 'react-bootstrap';
import { ADD_FEELING } from '../../../context/globalState/globalStateReducer';
import GlobalStateContext from '../../../context/globalState/GlobalStateContext';
import { m as motion } from 'framer-motion';
import { HotelBedFillIcon } from '../../../components/RemixIcons';
import { withGeoPosition } from '../../../tools/withGeoPosition';
import { WithGeoPoint } from '../../../@types/seen-apps';
import { feelingCategory } from '../../../@types/webapp-api';
import { getFeelingUpdateRoute } from '../../../tools/routeTools';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastOnClose } from '../../../components/ModalWrapper/ModalSlideShowWrapper';
import { getErrorTranslation } from '../../../tools/errorTools';
import { captureException } from '@sentry/minimal';
import {
  CampaignResponse,
  FeelingWizard,
} from '../../../@types/shorten-url-api';
import MarkdownComponent from '../../../components/Markdown/MarkdownComponent';

const styles = { ...modalStyles, ...feelingStyles };

type Props = {
  mode: 'good' | 'bad';
  session: any;
  tagIds?: string;
  campaign?: CampaignResponse;
  sphere: any;
  context: Record<string, string>;
  groupId?: string;
  validationCode: string;
  onSubmit?: (stateProps: any) => void;
  variants?: any;
  memberCategory?: feelingCategory;
  onCloseNotifications: ToastOnClose[];
  disableThinkingCreation?: boolean;
  wizzard?: FeelingWizard;
};

const FeelingConsumerPage: FC<Props & WithGeoPoint> = ({
  mode,
  sphere,
  campaign,
  tagIds,
  session,
  context,
  validationCode,
  geoPoint,
  onSubmit = () => {},
  variants,
  memberCategory = 'consumer',
  onCloseNotifications,
  disableThinkingCreation,
}) => {
  const { dispatch } = useContext(GlobalStateContext);

  const [feelingSelectedIndex, setFeelingSelectedIndex] = useState<number>(-1);
  const [energySelectedIndex, setEnergySelectedIndex] = useState<number>(-1);
  const { t } = useTranslation('i18n');
  const location = useLocation();
  const submitBtnRef = useRef(null);
  const history = useHistory();

  const goodFeeling = mode === 'good';

  const handleFeelingSelectionChange = (index: number) =>
    setFeelingSelectedIndex(index);
  const handleEnergySelectionChange = (index: number) =>
    setEnergySelectedIndex(index);

  const handleSaveClick = async () => {
    //avoid double click
    if (submitBtnRef.current) {
      (submitBtnRef.current as any).setAttribute('disabled', 'disabled');
    }

    let value = (feelingSelectedIndex + 1) / 10;
    if (!goodFeeling) {
      value = -value;
    }
    try {
      const result = await api.addFeeling({
        campaignId: campaign?.id,
        sessionId: session?.id,
        sphereId: !campaign && !session && sphere ? sphere.id : undefined,
        value,
        geoPoint,
        energy: (energySelectedIndex + 1) / 10,
        deviceCode: new DeviceUUID().get(),
        category: memberCategory,
        stepNumber: campaign?.campaign?.stepNumber || context?.stepNumber,
        validationCode,
        disableThinkingCreation,
        fromDirectLink: true,
        specificTagIds: tagIds,
      });
      dispatch({
        type: ADD_FEELING,
        payload: result,
      });

      // add notification to display on close
      const link = getFeelingUpdateRoute({
        callerUrl: location.pathname,
        feelingId: result.id,
      });

      onCloseNotifications.push({
        content: (
          <div className={styles.feelingNotif}>
            {t('pages.feeling.added.success')} {t('pages.feeling.emotion')}{' '}
            <button
              onClick={() => history.push(link)}
              className={styles.wordLink}
            >
              {t(`words.${result.wordId}`)}
            </button>
          </div>
        ),
        options: {
          autoClose: 20000,
          type: 'success',
        },
      });

      //go to next page or close
      onSubmit({
        feelingId: result.id,
        memberCategory,
        validationCode,
        context,
        sphere,
        session,
      });
    } catch (error: any) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          error,
          t,
          defaultKey: 'pages.feeling.added.error',
        })
      );
      onSubmit({});
    }
  };

  return (
    <motion.div
      variants={variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={styles.page}
    >
      <div className={styles.header}>
        <img
          className={styles.feelingImage}
          src={mode === 'good' ? happyFace : notHappyFace}
          alt='nothappyface'
        />
      </div>
      <div className={styles.content}>
        <Container>
          <div className={styles.subtitle}>
            <MarkdownComponent
              text={t(`pages.feeling.subTitle.feeling.${mode}`)}
            />
          </div>
          <div className={styles.container}>
            <Histogram
              title={t(`pages.feeling.histogram.title.${mode}`)}
              selectedIndex={feelingSelectedIndex}
              onSelectedIndexChange={handleFeelingSelectionChange}
              colorScheme={goodFeeling ? 'green' : 'red'}
              srcIcon={goodFeeling ? HappyFeelingIcon : SadFeelingIcon}
              legendLowComp={t(
                `pages.feeling.histogram.legend${
                  goodFeeling ? 'Good' : 'Bad'
                }.low`
              )}
              legendHighComp={t(
                `pages.feeling.histogram.legend${
                  goodFeeling ? 'Good' : 'Bad'
                }.high`
              )}
            />
            <hr />
            <Histogram
              title={t(`pages.feeling.histogram.title.energy`)}
              selectedIndex={energySelectedIndex}
              onSelectedIndexChange={handleEnergySelectionChange}
              colorScheme='blue'
              srcIcon={battery}
              legendLowComp={
                <HotelBedFillIcon
                  style={{ fontSize: '30px', color: '#A7B6D1' }}
                />
              }
              legendHighComp={
                <img src={runIcon} alt='icon' style={{ width: '30px' }} />
              }
            />
            <div className={styles.footer}>
              <Button
                ref={submitBtnRef}
                disabled={
                  feelingSelectedIndex === -1 || energySelectedIndex === -1
                }
                onClick={handleSaveClick}
              >
                {t('common.action.save')}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </motion.div>
  );
};

export default withGeoPosition(FeelingConsumerPage as FC<WithGeoPoint>);
