import { useEffect, useState } from 'react';
import { GoalHit } from '../../../@types/seen-apps';
import { GOALS_AND_SUB as GOALS_AND_SUBS } from '../../../tools/goalTools';
import { Button, Col, Container, Row } from 'react-bootstrap';
import styles from './GoalForm.module.scss';
import { useTranslation } from 'react-i18next';
import { Slider } from '@material-ui/core';

type SubGoalFormProps = {
  disabled?: boolean;
  goal: string;
  level: number;
  ranking: number;
  values?: GoalHit[];
  onMainGoalChange: (values: GoalHit) => void;
  onChange: (values: GoalHit[]) => void;
};

const SubGoalSelection = ({
  isMainGoal,
  goal,
  value,
  onChange,
  disabled,
  className,
}: {
  className?: string;
  isMainGoal?: boolean;
  disabled?: boolean;
  goal: string;
  value: GoalHit;
  onChange: (value: GoalHit) => void;
}) => {
  const { t } = useTranslation('i18n');

  const handleClicked = (level: number | undefined) => {
    onChange({ ...value, level: level || 0 });
  };

  return (
    <div className={className}>
      <div className={isMainGoal ? styles.goal : styles.subGoal}>
        <div className={styles.subGoalTitle}>
          {isMainGoal
            ? t(`report.goalForm.axes.${value.id}.label`)
            : t(`report.goalForm.axes.${goal}.sub.${value.id}`)}
        </div>
        <Slider
          disabled={disabled}
          className={`${styles.subGoalSlider} ${styles[`level${value.level}`]}`}
          step={1}
          max={2}
          min={-2}
          value={value.level}
          onChange={(_event, value) =>
            handleClicked(Array.isArray(value) ? value[0] : value)
          }
        />
        <div className={styles.subGoalSliderLegend}>
          <div
            className={`${styles.subGoalSliderLegendAway} ${
              value.level < 0
                ? styles.subGoalLegendActive
                : styles.subGoalLegendNotActive
            }`}
            onClick={() => handleClicked(value.level === -1 ? -2 : -1)}
          >
            {t('pages.feeling.goal.legend.away')}
          </div>
          <div
            className={`${styles.subGoalSliderLegendNone} ${
              value.level === 0
                ? styles.subGoalLegendActive
                : styles.subGoalLegendNotActive
            }`}
            onClick={() => handleClicked(0)}
          >
            {t('pages.feeling.goal.legend.none')}
          </div>
          <div
            className={`${styles.subGoalSliderLegendCloser} ${
              value.level > 0
                ? styles.subGoalLegendActive
                : styles.subGoalLegendNotActive
            }`}
            onClick={() => handleClicked(value.level === 1 ? 2 : 1)}
          >
            {t('pages.feeling.goal.legend.closer')}
          </div>
        </div>
      </div>
    </div>
  );
};

const SubGoalForm = ({
  goal,
  ranking,
  level,
  values,
  onChange,
  disabled,
  onMainGoalChange,
}: SubGoalFormProps) => {
  const [grid, setGrid] = useState<GoalHit[][]>([]);
  const [showSubGoals, setShowSubGoals] = useState<boolean>(
    !!values?.some((v) => v.level !== 0)
  );
  const [subGoalsCount, setSubGoalsCount] = useState<number>();

  const { t } = useTranslation('i18n');

  useEffect(() => {
    const subGoals = GOALS_AND_SUBS.find((g) => g.id === goal);
    const grid =
      subGoals?.sub.reduce((acc, subGoal, index) => {
        const value = values?.find((v) => v.id === subGoal);
        if (disabled && !value?.level) {
          return acc;
        }
        const item = {
          id: subGoal,
          level: value?.level || 0,
          ranking,
        };
        if (index % 3 === 0) {
          return [...acc, [item]];
        }
        acc[acc.length - 1].push(item);
        return acc;
      }, [] as GoalHit[][]) || [];

    setSubGoalsCount(subGoals?.sub?.length);
    setGrid(grid);
  }, [disabled, goal, ranking, values]);

  const handleOnChange = (value: GoalHit) => {
    const selected = values?.filter((v) => v.id !== value.id) || [];

    if (value.level !== 0) {
      selected.push(value);
    }
    onChange(selected);
  };

  return (
    <Container className={styles.noMargin}>
      <Row key={`goal-row-${goal}`} className={styles.noMargin}>
        <Col sm={12} className={styles.noMargin}>
          <div className={styles.question}>
            <div>{t('pages.feeling.goal.question')}</div>
          </div>
        </Col>
      </Row>
      <Row className={styles.noMargin}>
        <Col lg={4} className={styles.noMargin}>
          <SubGoalSelection
            isMainGoal
            goal={goal}
            disabled={disabled}
            value={{
              id: goal,
              level,
              ranking,
            }}
            onChange={onMainGoalChange}
            className={styles.goalContainer}
          />
        </Col>
        {!showSubGoals && subGoalsCount && subGoalsCount > 0 && (
          <Col lg={8} className={styles.noMargin}>
            <div className={styles.goalContainer}>
              <Button
                variant='outline-primary'
                onClick={() => setShowSubGoals(true)}
              >
                {t(`pages.feeling.goal.questionSub`, { count: subGoalsCount })}
              </Button>
            </div>
          </Col>
        )}
      </Row>

      {showSubGoals &&
        grid.map((row, index) => (
          <Row key={`sub-goal-row-${index}`} className={styles.noMargin}>
            {row.map((col) => (
              <Col
                lg={4}
                key={`sub-goal-col-${col.id}`}
                className={styles.noMargin}
              >
                <SubGoalSelection
                  goal={goal}
                  disabled={disabled}
                  value={col}
                  onChange={handleOnChange}
                  className={styles.goalContainer}
                />
              </Col>
            ))}
          </Row>
        ))}
    </Container>
  );
};

export default SubGoalForm;
