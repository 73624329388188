import React, { FC } from 'react';
import Language from '../Language/Language';
import styles from './Welcome.module.scss';

export type Props = { readonly?: boolean };

const Welcome: FC<Props> = ({ readonly }) => {
  return (
    <div>
      <h3 className={styles.title}>
        <img className={styles.logo} src='/logo.png' alt='logo' />
        Seen-apps
      </h3>
      <Language readonly={readonly} />
    </div>
  );
};

export default Welcome;
