// @ts-nocheck
import React from 'react';
import styles from './DropDownContexts.module.scss';

type Props = {
  style: any;
  children: any;
  className: any;
};

const DropDownMenu = React.forwardRef(
  ({ children, style, className }: Props, ref) => {
    return (
      <div ref={ref} style={style} className={className}>
        <ul className={styles.listContexts}>{children}</ul>
      </div>
    );
  }
);

export default DropDownMenu;
