/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styles from './Notes.module.scss';
import { useTranslation } from 'react-i18next';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Icon } from '@material-ui/core';
import { captureException } from '@sentry/minimal';
import { Note } from '../../../@types/seen-apps';
import NoteCard from './NoteCard';
import { NoteIcon, PersonalNoteIcon } from '../../RemixIcons';

const Notes: React.FC<{
  notes: (Note & { updated?: boolean })[];
  isPersonal?: boolean;
  disabled?: boolean;
  onChange: (notes: (Note & { updated?: boolean })[]) => void;
}> = ({ notes, disabled, onChange, isPersonal }) => {
  const { t } = useTranslation('i18n');

  const getId = () => {
    const lastId = notes?.reduce((acc, note) => Math.max(acc, note.id), 0) || 0;
    return lastId + 1;
  };

  const handleAddNote = (event: any) => {
    event.stopPropagation();

    const newNote: Note & { updated?: boolean } = {
      id: getId(),
      updated: true,
      value: '',
    };
    onChange([...notes, newNote]);
  };

  const handleDelete = (note: Note, index: number) => {
    const copy = [...notes];
    copy.splice(index, 1);
    onChange(copy);
  };

  const handleChange = (note: Note, index: number) => {
    const copy = [...notes];
    copy[index] = { ...note, updated: true };
    onChange(copy);
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (result.destination) {
      try {
        const copy = notes.map((note, index) => ({
          ...note,
          updated: index === result.source.index ? true : note.updated,
        }));
        copy.splice(
          result.destination.index,
          0,
          copy.splice(result.source.index, 1)[0]
        );
        onChange(copy);
      } catch (error) {
        captureException(error);
        console.log(`Error during dropîng element`, error);
      }
    }
  };

  return (
    <Container className={styles.noMargin}>
      {!disabled && (
        <div className={styles.actions}>
          <Button onClick={handleAddNote}>
            {isPersonal ? <PersonalNoteIcon /> : <NoteIcon />}
            <span className={styles.addIcon}>
              {' '}
              {isPersonal
                ? t(`pages.note.personal.add`)
                : t(`pages.note.personal.add`)}
            </span>
          </Button>
        </div>
      )}
      <Row className={styles.noMargin}>
        <Col sm={12} className={styles.noMargin}>
          {notes.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='droppable' isDropDisabled={disabled}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef}>
                    {notes?.map((note, index) => (
                      <Draggable
                        key={note.id}
                        draggableId={`${note.id}`}
                        index={index}
                        isDragDisabled={disabled}
                      >
                        {
                          // tslint:disable-next-line:no-shadowed-variable
                          (provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={styles.card}
                            >
                              <div className={styles.card}>
                                {!disabled && (
                                  <Icon className={styles.icon}>
                                    drag_indicator
                                  </Icon>
                                )}
                                <NoteCard
                                  variant={
                                    note.updated ? 'secondary' : 'primary'
                                  }
                                  updated={note.updated}
                                  disabled={disabled}
                                  note={note}
                                  onDelete={() => {
                                    handleDelete(note, index);
                                  }}
                                  onChange={(note) => {
                                    handleChange(note, index);
                                  }}
                                />

                                {(provided as any).placeholder}
                              </div>
                            </div>
                          )
                        }
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <span>{t('pages.note.personal.empty')}</span>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Notes;
