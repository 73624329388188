import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import './MarkdownComponent.module.scss';

type Props = {
  text?: string;
  className?: string;
};

const MarkdownComponent: React.FC<Props> = ({ text, className }) => {
  return (
    <ReactMarkdown
      children={text || ''}
      className={className}
      linkTarget='_blank'
      rehypePlugins={[remarkMath]}
      remarkPlugins={[remarkGfm]}
    />
  );
};

export default MarkdownComponent;
