import { captureException } from '@sentry/minimal';
import React, { useEffect, useState } from 'react';
import { GeoPoint } from '../@types/webapp-api';

export function withGeoPosition(
  WrappedComponent: React.FC<{
    geoPoint: GeoPoint;
  }>
) {
  return (props: any) => {
    const [geoPoint, setGeoPoint] = useState<GeoPoint>({
      latitude: 0,
      longitude: 0,
    });

    const initGeoPosition = async () => {
      try {
        const response = (await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve as any, reject, {
            timeout: 20000,
            enableHighAccuracy: true,
          });
        })) as { coords: GeoPoint };
        setGeoPoint({
          latitude: response.coords.latitude,
          longitude: response.coords.longitude,
        });
      } catch (e) {
        captureException(e);
        console.log(`Error retrieving position`, e);
        setGeoPoint({ latitude: 0, longitude: 0 });
      }
    };

    useEffect(() => {
      initGeoPosition();
    }, []);

    return <WrappedComponent geoPoint={geoPoint} {...props} />;
  };
}
