import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { SphereIcon } from '../header/Header';
import styles from './SphereNameView.module.scss';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { Sphere } from '../../@types/sphere-api';
import { StudyProgramIcon } from '../RemixIcons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getSphereContributionRoute } from '../../tools/routeTools';

interface Props {
  full?: boolean;
  sphere?: Sphere;
  sphereName?: string;
  disableLink?: boolean;
}

const SphereNameView = ({ sphere, full, sphereName, disableLink }: Props) => {
  const { state: globalState } = useContext(GlobalStateContext);
  const { t } = useTranslation('i18n');
  const [isMember, setIsMember] = useState<boolean>(false);

  useEffect(() => {
    const member = globalState.members.find(
      (m) => m.sphereId && m.sphereId === sphere?.id
    );

    setIsMember(!!member);
  }, [globalState.members, globalState.profile, sphere]);

  if (!sphere && sphereName) {
    return (
      <Container className={styles.noMargin}>
        <Row className={styles.noMargin}>
          <Col xs={1} className={styles.iconCol}>
            <SphereIcon className={styles.icon} />
          </Col>
          <Col className={styles.noMarginText}>{sphereName}</Col>
        </Row>
      </Container>
    );
  }
  if (!sphere) {
    return <div></div>;
  }

  const label = (
    <Row className={styles.noMargin}>
      <Col xs={1} className={styles.iconCol}>
        {full || !sphere.isStudyProgram ? (
          <SphereIcon className={styles.icon} />
        ) : (
          <StudyProgramIcon className={`${styles.icon} ${styles.studyIcon}`} />
        )}
      </Col>
      <Col className={styles.noMarginText}>{sphere.name}</Col>
    </Row>
  );

  return (
    <Container className={styles.noMargin}>
      {isMember && !disableLink ? (
        <Link
          to={getSphereContributionRoute({
            sphereId: sphere.id,
          })}
          className={styles.link}
        >
          {label}
        </Link>
      ) : (
        label
      )}

      {full && (
        <Row className={styles.noMargin}>
          <Col xs={1} className={styles.iconCol}></Col>
        </Row>
      )}
      {full && sphere.isStudyProgram && (
        <Row className={styles.noMargin}>
          <Col xs={1} className={styles.iconCol}>
            <StudyProgramIcon />
          </Col>
          <Col className={styles.noMarginText}>
            <Badge variant='warning'>{t(`common.sphere.studyProgram`)}</Badge>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default SphereNameView;
