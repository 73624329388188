/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, FC, useState, useContext, ReactNode } from 'react';
import { Button, Container } from 'react-bootstrap';
import { m as motion } from 'framer-motion';
import api, { DEFAULT_GROUP_ID } from '../../api/Api';
import { toast } from 'react-toastify';
import thinkingStyles from './ThinkingPage.module.scss';
import modalStyles from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import Question, { THINKING_OPTIONS } from '../../components/question/Question';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { useTranslation } from 'react-i18next';
import { ADD_THINKING } from '../../context/globalState/globalStateReducer';
import LoadingPage from '../loading/LoadingPage';
import { Sphere } from '../../@types/sphere-api';
import SphereNameView from '../../components/sphere/SphereNameView';
import ThinkingReminder from './ThinkingReminder';
import { WithGeoPoint } from '../../@types/seen-apps';
import { withGeoPosition } from '../../tools/withGeoPosition';
import { feelingCategory } from '../../@types/webapp-api';
import { getValue } from '../../tools/thinkingTools';
import moment from 'moment-timezone';
import { Session } from '../../@types/session-api';
import { SphereAxeResponse } from '../../@types/thinking-api';
import { getSessionName } from '../../tools/sessionTools';
import AbilityContext from '../../context/AbilityContext';
import { captureException } from '@sentry/minimal';
import { ToastOnClose } from '../../components/ModalWrapper/ModalSlideShowWrapper';
import SessionNameView from '../../components/session/SessionNameView';
import PageTitle from '../../components/PageTitle/PageTitle';
import { CampaignResponse, ThinkingWizard } from '../../@types/shorten-url-api';
import { getRelevantTranslationFor } from '../../tools/multiLingualTools';
import CampaignNameView from '../../components/campaign/CampaignNameView';
import { isMobile } from 'react-device-detect';

const styles = { ...modalStyles, ...thinkingStyles };

type Props = {
  sphere?: Sphere;
  session?: Session;
  campaign?: CampaignResponse;
  sphereId?: string;
  sessionId?: string;
  tagIds?: string;
  feelingId?: string;
  validationCode?: string;
  context?: Record<string, string>;
  currentPageIdx?: number;
  link?: boolean | number;
  pageElements?: ReactNode[];
  onSubmit?: () => void;
  variants?: any;
  memberCategory?: feelingCategory;
  onCloseNotifications?: ToastOnClose[];
  onClose?: () => {};
};

const ThinkingPage: FC<Props & WithGeoPoint> = ({
  sphereId,
  sessionId,
  geoPoint,
  onSubmit = () => {},
  variants,
  memberCategory: memberCategoryFromContext,
  sphere: sphereFromContext1,
  session: sessionFromContext1,
  campaign: campaignFromContext1,
  context,
  tagIds,
  link,
  validationCode,
  onCloseNotifications = [],
  onClose = () => {},
}) => {
  const [memberCategory, setMemberCategory] = useState<string>(
    memberCategoryFromContext || 'contributor'
  );
  const ability = useContext(AbilityContext);
  const [thinkingAxes, setThinkingAxes] = useState<any[]>([]);
  const [values, setValues] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [title, setTitle] = useState<JSX.Element>();
  const [isStudyProgram, setStudyProgram] = useState<boolean>(false);
  const [autoFillData, setAutoFillData] = useState<Record<string, string>>({
    SPHERE_NAME: '???',
  });

  const { t } = useTranslation('i18n');
  const { state, dispatch } = useContext(GlobalStateContext);

  useEffect(() => {
    const forbidden = (message: string) => {
      onCloseNotifications?.push({
        content: message,
        options: {
          type: 'error',
        },
      });
      onClose();
    };

    const fetchThinkingAxes = async () => {
      /**
       * 2 differents triggers :
       * - Feeling with choice (context), user could choose sphere or session
       * - Direct thinking with sphereId or sessionId or campaignId
       *
       * Priority to context
       */
      let name: string = '';
      let sessionId: string | undefined = undefined;
      let sphereId: string | undefined = undefined;
      let category: string | undefined = undefined;
      let groupId: string | undefined = DEFAULT_GROUP_ID;

      let session: Session | undefined = undefined;
      let sphere: Sphere | undefined = undefined;

      if (campaignFromContext1) {
        name = getRelevantTranslationFor(campaignFromContext1.providerName);
        sphereId = campaignFromContext1.sphereId;
        sessionId = campaignFromContext1.sessionId;
        category = campaignFromContext1.category;

        const thinkingWizardProps =
          campaignFromContext1.campaign?.wizards?.find(
            (w) => w.type === 'thinking'
          ) as ThinkingWizard | undefined;
        groupId = thinkingWizardProps?.groupId || DEFAULT_GROUP_ID;
        setTitle(<CampaignNameView campaign={campaignFromContext1} />);
      } else if (sessionFromContext1) {
        session = sessionFromContext1;
        name = getSessionName(sessionFromContext1, ability);
        sphereId = sessionFromContext1.sphere.id;
        sessionId = sessionFromContext1.id;

        setTitle(<SessionNameView disableLink session={session} />);
      } else if (sphereFromContext1) {
        sphere = sphereFromContext1;
        name = sphereFromContext1.name;
        sphereId = sphereFromContext1.id;
        setTitle(<SphereNameView disableLink sphere={sphere} />);
        setStudyProgram(sphere.isStudyProgram);
      } else if (sessionId) {
        session = state.sessions.find((s) => s.id === sessionId);
        if (!session) {
          const session = await api.getSession(sessionId);
          if (!session) {
            forbidden(t('common.not-found.session'));
            return;
          }
        }
        name = getSessionName(session, ability);
        sphereId = session?.sphere.id;
        sessionId = session?.id;
        setTitle(<SessionNameView disableLink session={session} />);
      } else if (sphereId) {
        sphere = state.spheres.find((s) => s.id === sphereId);
        if (!sphere) {
          sphere = await api.getSphere(sphereId);
          if (!sphere) {
            forbidden(t('common.not-found.sphere'));
            return;
          }
        }
        sphereId = sphere.id;
        name = sphere.name;
        setTitle(<SphereNameView disableLink sphere={sphere} />);
        setStudyProgram(sphere.isStudyProgram);
      }

      // Check permission
      if (memberCategory === 'contributor' && session && !session.isMember) {
        forbidden(t('pages.thinking.errors.sessionForbidden'));
        return;
      } else if (
        memberCategory === 'contributor' &&
        sphere &&
        !sphere.isMember
      ) {
        forbidden(t('pages.thinking.errors.sphereForbidden'));
        return;
      }

      setAutoFillData({
        SPHERE_NAME: name,
      });

      let thinkingAxes: SphereAxeResponse[] = [];
      if (sessionId) {
        const [sessionAxes, sphereAxes] = await Promise.all([
          api.getSessionThinkingAxes(sessionId, groupId),
          api.getSphereThinkingAxes(category, sphereId),
        ]);
        thinkingAxes = sessionAxes.length > 0 ? sessionAxes : sphereAxes;
      } else if (sphereId) {
        thinkingAxes = await api.getSphereThinkingAxes(
          memberCategory,
          sphereId
        );
      }
      if (category) {
        setMemberCategory(category);
      }
      setThinkingAxes(thinkingAxes);
      setLoading(false);

      if (thinkingAxes.length === 0) {
        onSubmit();
      }
    };

    fetchThinkingAxes().catch((error) => {
      captureException(error);
      forbidden(t('pages.feeling.added.error'));
    });
  }, []);

  const handleSelection = (axeId: number, value?: number) => {
    setValues({
      ...values,
      [axeId]: value,
    });
  };

  const handleSaveClick = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const axes = Object.keys(values)
      .map((key) => ({
        id: key,
        value: values[key],
      }))
      .filter((axe) => axe.value || axe.value === 0);

    try {
      const locale = moment.tz.guess();
      const result = await api.addThinking({
        axes,
        memberCategory:
          !sessionId && !campaignFromContext1 ? memberCategory : undefined,
        sphereId,
        sessionId,
        campaignId: campaignFromContext1?.id,
        groupId: context?.groupId,
        geoPoint,
        validationCode,
        locale,
        fromDirectLink: !!link,
        specificTagIds: tagIds,
      });
      dispatch({
        type: ADD_THINKING,
        payload: result,
      });
      onSubmit();
    } catch (error) {
      captureException(error);
      toast.error(t('pages.feeling.added.error', { error }));
    }
  };
  return (
    <motion.div
      variants={variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={styles.page}
    >
      <PageTitle
        rendertitle={() => <div className={styles.header}>{title}</div>}
      />

      <div className={styles.content}>
        {loading ? (
          <LoadingPage />
        ) : (
          <Container>
            <h3>{t(`pages.thinking.title`)}</h3>
            {thinkingAxes.map((axe) => (
              <Question
                key={axe.id}
                title={getValue(axe.name, autoFillData)}
                questionLabel={getValue(axe.description, autoFillData)}
                options={THINKING_OPTIONS}
                value={values[axe.id]}
                onValueChange={(value) => handleSelection(axe.id, value)}
                isMobile={isMobile}
                forceHorizontal
              />
            ))}
            <div className={styles.footer}>
              <Button
                variant='primary'
                disabled={Object.keys(values).length === 0}
                onClick={handleSaveClick}
              >
                {t('common.action.save')}
              </Button>
            </div>
            <ThinkingReminder sphere={{ isStudyProgram }} />
          </Container>
        )}
      </div>
    </motion.div>
  );
};

export default withGeoPosition(ThinkingPage);
