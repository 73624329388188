import React, { useState, useContext, useEffect } from 'react';
import { FeelingWord, Word } from '../../@types/seen-apps';
import styles from './WordGrid.module.scss';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import LoadingPage from '../../pages/loading/LoadingPage';

type Matrix = {
  id: number;
  words: (
    | {
        id: string;
        selected: boolean;
        word: Word;
        energy: number;
        feelingValue: number;
        color: string;
      }
    | {
        id: string;
        word: null;
        selected: boolean;
      }
  )[];
}[];

const computeKey = (value: number, stepSize: number) => {
  const totalStep = 1 / stepSize;
  const stepNumber = Math.trunc((value * 100) / (100 / totalStep));
  return Math.trunc(stepNumber * stepSize * 100) / 100;
};

const roundUp = (number: number) => computeKey(number, 0.1);

const findWord = (
  words: FeelingWord[],
  feeling: number,
  energy: number
): FeelingWord => {
  const correspondingElement = words.find(
    (w) => w.energy === energy && w.feelingValue === feeling
  );
  if (!correspondingElement) {
    const nextValue = feeling < 0 ? -0.1 : 0.1;
    return findWord(words, roundUp(feeling + nextValue), energy);
  }
  return correspondingElement;
};

const getFeeling = (feeling: number, column: number) => {
  if (column > 1) {
    return feeling + 0.2;
  }
  if (column < 1) {
    return feeling - 0.2;
  }
  return feeling;
};

const getEnergy = (energy: number, row: number) => {
  if (row > 1) {
    return energy - 0.1;
  }
  if (row < 1) {
    return energy + 0.1;
  }
  return energy;
};

const getWord = (
  words: FeelingWord[],
  center: FeelingWord,
  row: number,
  column: number
) => {
  const feeling = roundUp(getFeeling(center.feelingValue, column));
  const energy = roundUp(getEnergy(center.energy, row));
  const word = words.find(
    (w) => w.energy === energy && w.feelingValue === feeling
  );

  if (word) {
    return {
      ...word,
      id: `${row}${column}`,
      selected: word.word.id === center.word.id,
    };
  }

  return {
    id: `${row}${column}`,
    word: null,
    selected: false,
  };
};

const buildWordsMatrix = (
  words: FeelingWord[],
  feeling: number,
  energy: number
): Matrix => {
  const correspondingElement = findWord(words, feeling, energy);
  return [...Array(3)].map((_, row) => ({
    id: row,
    words: [...Array(3)].map((v, column) =>
      getWord(words, correspondingElement, row, column)
    ),
  }));
};

interface Props extends RouteComponentProps {
  feelingValue: number;
  energyValue: number;
  wordId: string;
  disabled?: boolean;
  onWordSelected: (wordId: string) => void;
}

const WordGrid = ({
  feelingValue,
  energyValue,
  wordId,
  disabled,
  onWordSelected,
}: Props) => {
  const { state } = useContext(GlobalStateContext);
  const { t } = useTranslation('i18n');

  const [wordSelected, setWordSelected] = useState<string>();
  const [words, setWords] = useState<FeelingWord[]>([]);
  const [matrix, setMatrix] = useState<Matrix>();

  useEffect(() => {
    if (!state.definition.feelingWords) {
      return;
    }
    const initialState = state.definition.feelingWords.find(
      (w) => w.word.id === wordId
    );
    const wordSelected = initialState?.word.id;
    setWordSelected(wordSelected);
    const words = state.definition.feelingWords;
    setWords(words);

    if (words.length) {
      const matrix = buildWordsMatrix(words, feelingValue, energyValue);
      setMatrix(matrix);
    }
  }, [energyValue, feelingValue, state.definition, wordId, words]);

  const handleButtonPress = (wordId: string) => {
    setWordSelected(wordId);
    onWordSelected(wordId);
  };

  const getButtonVariant = (wordId: string | undefined): string => {
    if (!wordId || (wordSelected && wordSelected === wordId)) {
      return 'secondary';
    }

    const word = words.find((w) => w.word.id === wordId);
    let variant = 'secondary';
    if (word) {
      switch (word.color) {
        case 'green':
          variant = 'success';
          break;
        case 'red':
          variant = 'danger';
          break;
        case 'blue':
          variant = 'primary';
          break;
        case 'yellow':
          variant = 'warning';
          break;
        default:
          variant = 'secondary';
      }
    }
    return variant;
  };

  return !matrix ? (
    <LoadingPage />
  ) : (
    <Container className={styles.wordContainer}>
      {matrix.map((row) => (
        <Row key={row.id} className={styles.wordRow}>
          {row.words.map((col) => {
            const wordLabel = col.word ? t(`words.${col.word.id}`) : '-';

            const btnColor = getButtonVariant(col.word?.id);
            return (
              <Col
                key={col.id}
                className={cn(styles.wordCol, {
                  [styles.disabled]: !col.word || disabled,
                })}
              >
                <Button
                  className={styles.wordButton}
                  variant={btnColor}
                  disabled={!col.word}
                  active={!!wordSelected && wordSelected === col.word?.id}
                  onClick={() => handleButtonPress(col.word!.id)}
                >
                  {wordLabel}
                </Button>
              </Col>
            );
          })}
        </Row>
      ))}
    </Container>
  );
};

export default withRouter(WordGrid);
