const config = {
  development: {
    Env: 'local',
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_eFcXxNE1i',
      userPoolWebClientId: '228mtgc36li71a7c5uujjff2ss',
    },
    whiteList: ['emotion.vip', 'snapps.link', 'vax.place', 'app.seen-apps.com'],
    shortenUrl: 'https://emotion.vip',
    API: {
      endpoints: [
        {
          name: 'seenapps',
          endpoint: 'https://app-dev.seen-apps.com',
          region: 'eu-west-1',
        },
      ],
    },
  },
  //used to deploy dev environment
  production: {
    Env: 'production',
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_b0aeTeD6g',
      userPoolWebClientId: '21pcgl1hj38g0ned1u03ma3jeh',
    },
    whiteList: ['emotion.vip', 'snapps.link', 'vax.place', 'app.seen-apps.com'],
    shortenUrl: 'https://emotion.vip',
    API: {
      endpoints: [
        {
          name: 'seenapps',
          endpoint: 'https://app.seen-apps.com',
          region: 'eu-west-1',
        },
      ],
    },
  },
};

export default config[process.env.NODE_ENV];
