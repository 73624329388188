import React, { useContext, FC, useRef, useState, useEffect } from 'react';
import linkStyles from './AddOrUpdateCampaign.module.scss';
import selectStyles from '../../styles/react-select.module.scss';
import modalStyles from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { captureException } from '@sentry/minimal';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import { getErrorTranslation } from '../../tools/errorTools';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { ShowDialog } from '../../tools/routeTools';
import FeelingEvaluationPeriodInput from '../../components/feeling/FeelingEvaluationPeriodInput';

import AsyncSelect from 'react-select/async';
import api, { DEFAULT_GROUP_ID } from '../../api/Api';
import { Session } from '../../@types/session-api';
import { MultiLingual, Sphere } from '../../@types/sphere-api';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { SET_URL_ALIASES } from '../../context/globalState/globalStateReducer';
import { uniqBy } from 'lodash';
import { getCurrentLanguage, getLanguages } from '../../i18n';
import {
  FeelingWizard,
  FormWizard,
  ProfileWizard,
  SessionStrategyAssessmentWizard,
  SphereStrategyAssessmentWizard,
  ThinkingWizard,
  UrlAlias,
  Wizard,
} from '../../@types/shorten-url-api';
import MultiLingualInput from '../../components/MulitLingual/MultiLingualInput';
import { FILESTACK } from '../../App';
import * as filestack from 'filestack-js';
import { feelingCategory } from '../../@types/webapp-api';
import AutoHeightTextarea from '../../components/input/AutoHeightTextarea';
import { getRelevantTranslationFor } from '../../tools/multiLingualTools';
import { confirmWrapper } from '../../tools/confirm';
import Select from 'react-select';
import TagsInput from '../../components/input/TagsInput';
import AdvancedForm from '../../components/forms/AdvancedForm';
import { useLocation } from 'react-router-dom';
import { useAbility } from '@casl/react';
import AbilityContext from '../../context/AbilityContext';
import { isFeatureLimitedForSession } from '../../tools/sessionTools';

const styles = { ...modalStyles, ...linkStyles };
const dicoPrefix = 'pages.sphere.urlAliases';

type Props = {
  onClose?: () => void;
  onSubmit?: (stateProps: any) => void;
};

type Step = { value: string; label: string };

type UpdateProps = Props & {
  urlAlias: UrlAlias;
  mode: 'update';
};

type CreationProps = Props & {
  category: feelingCategory;
  mode: 'creation';
};

const AddOrUpdateCampaign: FC<UpdateProps | CreationProps> = ({
  onSubmit = () => {},
  onClose,
  ...props
}) => {
  const {
    dispatch,
    state: { context, urlAliases, spheres },
  } = useContext(GlobalStateContext);

  const ability = useAbility(AbilityContext);
  const { t } = useTranslation('i18n');
  const submitBtnRef = useRef(null);
  const location = useLocation();

  const [errors, setErrors] = useState<Record<string, string | undefined>>({});

  const [category] = useState<feelingCategory>(
    props.mode === 'creation'
      ? props.category
      : (props.urlAlias.category as feelingCategory)
  );
  const [name, setName] = useState<MultiLingual>();
  const [messageForUser, setMessageForUser] = useState<MultiLingual>();
  const [endMessageForUser, setEndMessageForUser] = useState<MultiLingual>();
  const [iconUrl, setIconUrl] = useState<string>();
  const [tags, setTags] = useState<{ value: string; label: string }[]>([]);
  const [defaultLanguage, setDefaultLanguage] = useState<string>(
    getCurrentLanguage()
  );

  const [activeExpirationDate, setActiveExpirationDate] =
    useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<Date>();
  const [description, setDescription] = useState<string>();
  const [activeFeeling, setActiveFeeling] = useState<boolean>(false);
  const [feelingPeriod, setFeelingPeriod] = useState<string>();
  const [activeThinking, setActiveThinking] = useState<boolean>(false);
  const [activeProfile, setActiveProfile] = useState<boolean>(false);
  const [activeForm, setActiveForm] = useState<boolean>(false);
  const [activeSphereStrategyAssessment, setActiveSphereStrategyAssessment] =
    useState<boolean>(false);
  const [activeSessionStrategyAssessment, setActiveSessionStrategyAssessment] =
    useState<boolean>(false);
  const [form, setForm] = useState<{ value: string; label: string }>();
  const [saving, setSaving] = useState<boolean>(false);

  const [stepSelected, setStepSelected] = useState<Step>();
  const [steps, setSteps] = useState<Step[]>();
  const [strategyEnabled, setStrategyEnabled] = useState<boolean>(false);
  const [isFeatureLimited, setIsFeaturesLimited] = useState<boolean>(true);

  //initialize
  useEffect(() => {
    let urlAlias = (location.state as any)?.urlAlias as UrlAlias;
    //Used during duplication
    if (!urlAlias && props.mode === 'creation') {
      return;
    } else if (props.mode === 'update') {
      urlAlias = props.urlAlias;
    }

    setName(urlAlias.name);
    setDescription(urlAlias.description);
    setMessageForUser(urlAlias.campaign?.messageForUser);
    setEndMessageForUser(urlAlias.campaign?.endMessageForUser);
    setIconUrl(urlAlias.campaign?.iconUrl);
    setDefaultLanguage(urlAlias.campaign?.defaultLanguage || defaultLanguage);

    if (
      urlAlias.expiredAt &&
      moment(urlAlias.expiredAt).isBefore(moment().add(20, 'years'))
    ) {
      setActiveExpirationDate(true);
      setExpirationDate(moment(urlAlias.expiredAt).toDate());
    }

    const feelingWizard = urlAlias.campaign?.wizards?.find(
      (w) => w.type === 'feeling'
    ) as FeelingWizard;
    if (feelingWizard) {
      setActiveFeeling(true);
      setFeelingPeriod(feelingWizard.period);
    }
    const thinkingWizard = urlAlias.campaign?.wizards?.find(
      (w) => w.type === 'thinking'
    ) as ThinkingWizard;
    if (thinkingWizard) {
      setActiveThinking(true);
    }
    const formWizard = urlAlias.campaign?.wizards?.find(
      (w) => w.type === 'form'
    ) as FormWizard;
    if (formWizard) {
      setActiveForm(true);
      initializeForm(formWizard.formId);
    }
    const profileWizard = urlAlias.campaign?.wizards?.find(
      (w) => w.type === 'profile'
    ) as ProfileWizard;
    if (profileWizard) {
      setActiveProfile(true);
    }
    const sphereStrategyAssessmentWizard = urlAlias.campaign?.wizards?.find(
      (w) => w.type === 'sphereStrategyAssessment'
    ) as SphereStrategyAssessmentWizard;
    if (sphereStrategyAssessmentWizard) {
      setActiveSphereStrategyAssessment(true);
    }
    const sessionStrategyAssessmentWizard = urlAlias.campaign?.wizards?.find(
      (w) => w.type === 'sessionStrategyAssessment'
    ) as SessionStrategyAssessmentWizard;
    if (sessionStrategyAssessmentWizard) {
      setActiveSessionStrategyAssessment(true);
    }

    const tagIds = urlAlias.campaign?.specificTagIds || [];
    if (urlAlias.sphereId && tagIds.length > 0) {
      api
        .getSphereTags({
          max: tagIds.length,
          offset: 0,
          filters: [`id=${tagIds.join(',')}`],
          sphereId: urlAlias.sphereId,
        })
        .then((tags) => {
          setTags(
            tags.items.map((t) => ({
              label: getRelevantTranslationFor(t.name),
              value: t.id,
            }))
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let strategyEnabled = false;
    if (category === 'contributor' && context.sphereCtx) {
      strategyEnabled = context.sphereCtx.sphere.strategyEnabled || false;
    } else if (category === 'contributor' && context.sessionCtx) {
      let sphere = spheres.find(
        (s) => s.id === context.sessionCtx?.session.sphere.id
      );
      const isSuperAdmin = ability.can('manage', 'sphere');
      if (!sphere && isSuperAdmin) {
        api
          .getSphere(context.sessionCtx.session.sphere.id)
          .then((sphere) => setStrategyEnabled(sphere.strategyEnabled))
          .catch(() => {});
      } else {
        strategyEnabled = !!sphere?.strategyEnabled;
      }
    }
    setStrategyEnabled(strategyEnabled);

    setIsFeaturesLimited(
      isFeatureLimitedForSession(context.sessionCtx?.session)
    );
  }, [ability, category, context.sessionCtx, context.sphereCtx, spheres]);

  const initializeForm = async (formId: string) => {
    const response = await api.getForm(formId);
    if (response) {
      setForm({
        label: response.title,
        value: response.id,
      });
    }
  };

  const handleFormSelected = (formSelected: any) => {
    setForm(formSelected);

    const { form, ...others } = errors;
    setErrors(others);
  };

  const handleChangeIcon = () => {
    const client = filestack.init(FILESTACK);
    const options: filestack.PickerOptions = {
      uploadConfig: {
        tags: {
          sphereId: context.sphereCtx?.sphere.id || '',
          sessionId: context.sessionCtx?.session.id || '',
          campaign: 'true',
        },
      },
      fromSources: ['local_file_system', 'url', 'imagesearch'],
      accept: ['image/jpeg', 'image/png'],
      transformations: {
        crop: {
          aspectRatio: 1,
          force: true,
        },
        circle: true,
      },
      onFileUploadFinished: (file: filestack.PickerFileMetadata) => {
        setIconUrl(file.url);
      },
    };

    client.picker(options).open();
  };
  const handleResetIcon = () => {
    setIconUrl(undefined);
  };

  const fetchSphereForms = async ({
    sphere,
    search,
  }: {
    sphere: Sphere;
    search?: string;
  }) => {
    const response = await api.getFormEntitlements({
      sphereId: sphere.id,
      max: 200,
      searchQuery: search,
      offset: 0,
    });
    const items = response.items
      .filter((e) => e.availabilitiesThrough === 'sphereMember')
      .map((entitlement) => {
        return {
          label: entitlement.title,
          value: entitlement.formId,
        };
      });
    return items;
  };
  const fetchSessionForms = async ({
    session,
    search,
  }: {
    session: Session;
    search?: string;
  }) => {
    const [entitlementsFromSession, entitlementsFromSphere] = await Promise.all(
      [
        api.getFormEntitlements({
          sphereId: session.sphere.id,
          max: 200,
          searchQuery: search,
          filters: [
            'availabilitiesThrough=sessionMember',
            `sessionIds=${session.id}`,
          ],
          offset: 0,
        }),
        api.getFormEntitlements({
          sphereId: session.sphere.id,
          max: 200,
          searchQuery: search,
          filters: ['availabilitiesThrough=sphereMember'],
          offset: 0,
        }),
      ]
    );

    const items = [
      ...entitlementsFromSession.items,
      ...entitlementsFromSphere.items,
    ].map((entitlement) => {
      return {
        label: entitlement.title,
        value: entitlement.formId,
      };
    });
    return uniqBy(items, 'value');
  };

  const loadFormsOptions = (
    search: string,
    callback: (data: { label: string; value: string }[]) => void
  ) => {
    if (context.sessionCtx) {
      fetchSessionForms({ session: context.sessionCtx.session, search }).then(
        callback
      );
    } else if (context.sphereCtx) {
      fetchSphereForms({ sphere: context.sphereCtx.sphere, search }).then(
        callback
      );
    }
  };

  const handleSaveClick = async () => {
    //avoid double click
    if (submitBtnRef.current) {
      (submitBtnRef.current as any).setAttribute('disabled', 'disabled');
    }

    const error: Record<string, string> = {};
    if (!name?.default) {
      error.name = t('common.validations.required');
    }
    if (activeForm && !form) {
      error.form = t('common.validations.required');
    }
    setErrors(error);
    if (Object.keys(error).length > 0) {
      return;
    }

    const linkAttributes: string[] = [];
    const show: string[] = [];
    linkAttributes.push(`link=1`);
    if (activeFeeling) {
      show.push(ShowDialog.feeling);
      if (feelingPeriod) {
        linkAttributes.push(`duration=${encodeURIComponent(feelingPeriod)}`);
      }
    }
    if (activeThinking) {
      show.push(ShowDialog.thinking);
    }
    if (activeForm) {
      show.push(ShowDialog.selectFormContext);
      show.push(ShowDialog.addForm);
      if (form) {
        linkAttributes.push(`formId=${form.value}`);
      }
    }
    if (activeProfile) {
      show.push(ShowDialog.profile);
    }
    if (show.length > 0) {
      linkAttributes.push(`show=${show.join(',')}`);
      if (context.sphereCtx) {
        linkAttributes.push(`sphereId=${context.sphereCtx.sphere.id}`);
        linkAttributes.push(`sphereSelected=true`);
      }
      if (context.sessionCtx) {
        linkAttributes.push(`sessionId=${context.sessionCtx.session.id}`);
      }
    }

    const wizards: Wizard[] = [];
    if (activeFeeling) {
      wizards.push({ type: 'feeling', period: feelingPeriod });
    }
    if (activeThinking) {
      wizards.push({ type: 'thinking', groupId: DEFAULT_GROUP_ID });
    }
    if (activeForm) {
      wizards.push({ type: 'form', formId: form?.value || '' });
    }
    if (activeSphereStrategyAssessment) {
      wizards.push({ type: 'sphereStrategyAssessment' });
    }
    if (activeSessionStrategyAssessment) {
      wizards.push({ type: 'sessionStrategyAssessment' });
    }
    if (activeProfile) {
      wizards.push({ type: 'profile' });
    }

    let stepNumber: number | undefined = undefined;
    try {
      stepNumber = stepSelected
        ? Number.parseInt(stepSelected.value)
        : undefined;
      if (Number.isNaN(stepNumber)) {
        stepNumber = 0;
      }
    } catch (error) {}

    try {
      let urlAlias: UrlAlias;
      setSaving(true);
      if (props.mode === 'creation') {
        urlAlias = await api.createUrlAlias({
          expiredAt:
            expirationDate?.toISOString() ||
            moment().add(100, 'years').toISOString(),
          name: name!,
          description,
          url: `${window.location.origin}?show=campaign&campaignId=$$ID$$&lang=${defaultLanguage}`,
          category: props.category,
          sessionId: context.sessionCtx?.session.id,
          sphereId: context.sphereCtx?.sphere.id,
          campaign: {
            iconUrl,
            specificTagIds: tags?.map((t) => t.value),
            wizards,
            defaultLanguage,
            messageForUser,
            endMessageForUser,
            stepNumber,
          },
        });
        dispatch({
          type: SET_URL_ALIASES,
          payload: [...(urlAliases || []), urlAlias],
        });
      } else {
        urlAlias = await api.updateUrlAlias({
          id: props.urlAlias.id,
          expiredAt: expirationDate?.toISOString(),
          name: name!,
          description,
          campaign: {
            iconUrl,
            specificTagIds: tags?.map((t) => t.value),
            wizards,
            messageForUser,
            endMessageForUser,
            stepNumber,
          },
        });
        dispatch({
          type: SET_URL_ALIASES,
          payload: [
            ...(urlAliases || []).filter((u) => u.id !== urlAlias.id),
            urlAlias,
          ],
        });
      }
      //go to next page or close
      onSubmit({ urlAlias, category: urlAlias.category! });
    } catch (error: any) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          error,
          t,
          defaultKey: `${dicoPrefix}.section.${
            props.mode === 'creation' ? 'add' : 'update'
          }.error`,
        })
      );
    }
    setSaving(false);
  };

  const handleExpirationDateChange = (value: Date) => {
    setExpirationDate(value);
  };
  const handleDelete = async () => {
    if (
      props.mode === 'update' &&
      (await confirmWrapper(t(`${dicoPrefix}.section.delete.confirm`), {
        btnPrimaryLabel: t(`common.action.delete`),
        btnPrimaryVariant: 'danger',
        title: t(`${dicoPrefix}.section.delete.confirmAction`),
      }))
    ) {
      try {
        await api.deleteUrlAlias(props.urlAlias.id);
        toast.success(t(`${dicoPrefix}.section.delete.succeed`));

        dispatch({
          type: SET_URL_ALIASES,
          payload: [
            ...(urlAliases || []).filter((u) => u.id !== props.urlAlias.id),
          ],
        });
        onSubmit({});
      } catch (error) {
        captureException(error);
        toast.error(t('common.error'));
      }
    }
  };

  const handleStepChange = (step: any) => {
    setStepSelected(step);
  };

  useEffect(() => {
    const steps: Step[] = [
      { value: '0', label: t(`${dicoPrefix}.section.form.step.first`) },
    ];
    let inc = 0;
    const stepNumberValue = context.sessionCtx?.session.stepNumber || 0;
    while (inc < stepNumberValue) {
      inc++;
      steps.push({
        value: `${inc}`,
        label: t(`${dicoPrefix}.section.form.step.middle`, { stepNumber: inc }),
      });
    }

    steps.push({
      value: '100',
      label: t(`${dicoPrefix}.section.form.step.last`),
    });
    setSteps(steps);

    setStepSelected(steps[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.sessionCtx, t]);

  return (
    <Form>
      <Container className={styles.root}>
        <Row>
          <Col sm={12}>
            <MultiLingualInput
              autoFocus
              required
              label={t(`${dicoPrefix}.section.form.name`)}
              value={name}
              onChange={setName}
              maxLength={300}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Group controlId='description'>
              <Form.Label>
                {t(`${dicoPrefix}.section.form.description`)}
              </Form.Label>
              <AutoHeightTextarea
                placeholder={t(`${dicoPrefix}.section.form.description`)}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                maxLength={2000}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <MultiLingualInput
              multiLine
              label={t(`${dicoPrefix}.section.form.messageForUser`)}
              value={messageForUser}
              onChange={setMessageForUser}
              maxLength={250}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <MultiLingualInput
              multiLine
              label={t(`${dicoPrefix}.section.form.endMessageForUser`)}
              value={endMessageForUser}
              onChange={setEndMessageForUser}
              maxLength={250}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Label>{t(`${dicoPrefix}.section.form.logo`)}</Form.Label>
            <div className={styles.logoContainer}>
              <Form.Group controlId='logo'>
                {iconUrl ? (
                  <img
                    alt={t(`${dicoPrefix}.logo`)}
                    src={iconUrl}
                    height='45'
                    className={styles.logo}
                  />
                ) : (
                  <div></div>
                )}
                <Button
                  className={styles.updateLogo}
                  onClick={handleChangeIcon}
                >
                  {t(`${dicoPrefix}.section.form.updateLogo`)}
                </Button>
                <Button
                  className={styles.updateLogo}
                  onClick={handleResetIcon}
                  disabled={!iconUrl}
                >
                  {t(`${dicoPrefix}.section.form.sphereLogo`)}
                </Button>
              </Form.Group>
            </div>
          </Col>
        </Row>
        {category !== 'contributor' && (
          <Row>
            <Col sm={6}>
              <Form.Group controlId='locale'>
                <Form.Label>
                  {t(`${dicoPrefix}.section.form.locale`)}
                </Form.Label>
                <Form.Control
                  as='select'
                  onChange={(event) => {
                    setDefaultLanguage(event.target.value);
                  }}
                  value={defaultLanguage}
                  disabled={props.mode === 'creation'}
                >
                  {getLanguages().map((value) => (
                    <option key={`locale-${value}`} value={value}>
                      {value ? t(`pages.user.localeValues.${value}`) : ''}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6}>
            <Form.Group controlId='feeling' className={styles.feeling}>
              <Form.Check
                className={styles.checkbox}
                id={`activate-feeling`}
                type='switch'
                label={<>{t(`${dicoPrefix}.section.form.feeling`)}</>}
                checked={activeFeeling}
                onChange={(event: any) =>
                  setActiveFeeling(event.target.checked)
                }
              />

              {activeFeeling && category === 'contributor' && (
                <div className={styles.feelingPeriod}>
                  <FeelingEvaluationPeriodInput
                    onChange={setFeelingPeriod}
                    selectedTime={feelingPeriod}
                  />
                </div>
              )}
            </Form.Group>
          </Col>
        </Row>
        {!isFeatureLimited && (
          <Row>
            <Col sm={6}>
              <Form.Group controlId='thinking'>
                <Form.Check
                  className={styles.checkbox}
                  id={`activate-thinking`}
                  type='switch'
                  label={<>{t(`${dicoPrefix}.section.form.thinking`)}</>}
                  checked={activeThinking}
                  onChange={(event: any) =>
                    setActiveThinking(event.target.checked)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        {!isFeatureLimited && category === 'contributor' && (
          <Row>
            <Col sm={6}>
              <Form.Group controlId='form'>
                <Form.Check
                  className={styles.checkbox}
                  id={`activate-form`}
                  type='switch'
                  label={<>{t(`${dicoPrefix}.section.form.form`)}</>}
                  checked={activeForm}
                  onChange={(event: any) => setActiveForm(event.target.checked)}
                />

                {activeForm && (
                  <AsyncSelect
                    className={`${selectStyles.reactSelect} ${
                      styles.formSelect
                    } ${!!errors.form ? styles.errorSelect : ''}`}
                    classNamePrefix='custom-react-select'
                    loadOptions={loadFormsOptions}
                    defaultOptions
                    onChange={handleFormSelected}
                    placeholder={t(`${dicoPrefix}.section.form.placeholder`)}
                    value={form}
                    required
                    autoFocus={false}
                    noOptionsMessage={() => t('common.noValue')}
                  />
                )}
                {!!errors.form && (
                  <div className={styles.invalid}>{errors.form}</div>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
        {!isFeatureLimited && strategyEnabled && (
          <Row>
            <Col sm={6}>
              <Form.Group controlId='sphereStrategyAssessment'>
                <Form.Check
                  className={styles.checkbox}
                  id={`activate-sphereStrategyAssessment`}
                  type='switch'
                  label={
                    <>
                      {t(
                        `${dicoPrefix}.section.form.strategyAssessment.sphere`
                      )}
                    </>
                  }
                  checked={activeSphereStrategyAssessment}
                  onChange={(event: any) =>
                    setActiveSphereStrategyAssessment(event.target.checked)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        {!isFeatureLimited && strategyEnabled && (
          <Row>
            <Col sm={6}>
              <Form.Group controlId='sessionStrategyAssessment'>
                <Form.Check
                  className={styles.checkbox}
                  id={`activate-sessionStrategyAssessment`}
                  type='switch'
                  label={
                    <>
                      {t(
                        `${dicoPrefix}.section.form.strategyAssessment.session`
                      )}
                    </>
                  }
                  checked={activeSessionStrategyAssessment}
                  onChange={(event: any) =>
                    setActiveSessionStrategyAssessment(event.target.checked)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6}>
            <Form.Group controlId='profile'>
              <Form.Check
                className={styles.checkbox}
                id={`activate-profile`}
                type='switch'
                label={<>{t(`${dicoPrefix}.section.form.profile`)}</>}
                checked={activeProfile}
                onChange={(event: any) =>
                  setActiveProfile(event.target.checked)
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <AdvancedForm defaultStatus='closed'>
              <Container className={styles.noMargin}>
                {(context.sessionCtx || context.sphereCtx) && (
                  <Row className={styles.noMargin}>
                    <Col sm={6} className={styles.noMargin}>
                      <Form.Group controlId='tags'>
                        <Form.Label>
                          {t(`pages.sphere.sessions.tags`)}
                        </Form.Label>
                        <TagsInput
                          module={`campaign-${category}`}
                          sphereId={
                            context.sessionCtx?.session.sphere.id ||
                            context.sphereCtx?.sphere.id ||
                            ''
                          }
                          onChange={setTags}
                          tagsSelected={tags}
                          defaultValueTagWhenCreation={{
                            name: name,
                            modules: [`campaign-${category}`],
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {context.sessionCtx && category === 'consumer' && (
                  <Row className={styles.noMargin}>
                    <Col sm={6} className={styles.noMargin}>
                      {steps && (
                        <Form.Group controlId='step'>
                          <Form.Label>
                            {t(`${dicoPrefix}.section.form.stepNumber`)}
                          </Form.Label>

                          <Select
                            className={selectStyles.reactSelect}
                            classNamePrefix='custom-react-select'
                            defaultValue={steps[0]}
                            options={steps}
                            onChange={handleStepChange}
                          />
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                )}
                <Row className={styles.noMargin}>
                  <Col className={styles.noMargin} sm={6}>
                    <Form.Group controlId='expiration'>
                      <Form.Check
                        className={styles.checkbox}
                        id={`activate-expiration`}
                        type='switch'
                        label={
                          <>{t(`${dicoPrefix}.section.form.linkExpiration`)}</>
                        }
                        checked={activeExpirationDate}
                        onChange={(event: any) =>
                          setActiveExpirationDate(event.target.checked)
                        }
                      />
                    </Form.Group>
                    {activeExpirationDate && (
                      <DatePicker
                        customInput={
                          <Form.Group controlId='logo'>
                            <Form.Control
                              type='text'
                              placeholder={t(`${dicoPrefix}.logo`)}
                              value={moment(expirationDate).format(
                                'DD MMMM yyyy'
                              )}
                              onChange={() => {}}
                            />
                          </Form.Group>
                        }
                        selected={expirationDate}
                        onChange={handleExpirationDateChange}
                        minDate={moment().add(1, 'day').toDate()}
                        dateFormat='dd MMMM yyyy hh:mm'
                        className={styles.datePicker}
                        yearDropdownItemNumber={10}
                        showYearDropdown
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </AdvancedForm>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={12} className={styles.buttons}>
            {props.mode === 'update' && (
              <Button
                variant='danger'
                className={styles.right}
                disabled={saving}
                onClick={() => handleDelete()}
              >
                {t('common.action.delete')}
              </Button>
            )}

            <Button
              variant='secondary'
              className={styles.right}
              onClick={onClose}
            >
              {t('common.action.close')}
            </Button>

            <Button
              variant='primary'
              className={styles.left}
              disabled={saving}
              onClick={handleSaveClick}
            >
              {saving && (
                <div className={styles.submitContent}>
                  <ReactLoading
                    type={'bars'}
                    color={'#DDD'}
                    height={'30px'}
                    width={'40px'}
                  />
                  <div className={styles.submitText}>
                    {t('common.action.saving')}
                  </div>
                </div>
              )}
              {!saving && t('common.action.save')}
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default AddOrUpdateCampaign;
