import React, { useContext } from 'react';
import stylesTag from './AddOrUpdateTagPage.module.scss';
import stylesModal from '../../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import GlobalStateContext from '../../../context/globalState/GlobalStateContext';
import { Tag } from '../../../@types/sphere-api';
import { SET_SPHERE_TAG } from '../../../context/globalState/globalStateReducer';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { getUrlParams } from '../../../tools/routeTools';
import {
  addOrUpdateToPaginableList,
  removeFromPaginableList,
} from '../../../tools/paginationTools';
import AddOrUpdateTagForm from './AddOrUpdateTagForm';

const styles = { ...stylesModal, ...stylesTag };

type Props = {
  currentPageIdx?: number;
  onSubmit?: (state?: any) => void;
};

const AddOrUpdateTagPage: React.FC<Props> = ({ onSubmit = () => {} }) => {
  const { t } = useTranslation('i18n');
  const location = useLocation();
  let tagId = new URLSearchParams(location.search).get('tagId') || undefined;

  const {
    state: { sphereTags },
    dispatch,
  } = useContext(GlobalStateContext);
  const { sphereId } = getUrlParams(location.pathname);

  const handleClose = () => {
    onSubmit();
  };

  const handleTagSaved = async (tag: Tag) => {
    const result = addOrUpdateToPaginableList(sphereTags, tag);
    dispatch({
      type: SET_SPHERE_TAG,
      payload: result,
    });

    onSubmit();
  };
  const handleTagArchived = async (tag: Tag) => {
    const result = removeFromPaginableList(sphereTags, tag);
    dispatch({
      type: SET_SPHERE_TAG,
      payload: result,
    });

    onSubmit();
  };

  return (
    <div className={styles.page}>
      <PageTitle
        title={t(`pages.sphere.tags.section.${tagId ? 'update' : 'add'}.title`)}
      />
      <AddOrUpdateTagForm
        sphereId={sphereId}
        tagId={tagId}
        onArchived={handleTagArchived}
        onClose={handleClose}
        onSaved={handleTagSaved}
      />
    </div>
  );
};

export default AddOrUpdateTagPage;
