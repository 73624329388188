import VerificationInput, {
  VerificationInputProps,
} from 'react-verification-input';
import styles from './CodeValidationInput.module.scss';

const CodeValidationInput: React.FC<VerificationInputProps> = (
  props: VerificationInputProps
) => {
  return (
    <VerificationInput
      classNames={{
        container: styles.container,
        character: styles.character,
        characterInactive: styles.characterInactive,
        characterSelected: styles.characterSelected,
        characterFilled: styles.characterFilled,
      }}
      length={6}
      validChars='0-9'
      inputProps={{
        inputMode: 'numeric',
        onPaste: (e) => {
          if (props.onChange) {
            let clipboard = e.clipboardData.getData('Text');
            if (!clipboard) {
              return;
            }
            clipboard = `${props.value}${clipboard}`
              .split('')
              .filter((character) => /^[0-9]/.test(character))
              .join('')
              .slice(-6);

            props.onChange(clipboard);
            if (clipboard.length === 6 && props.onComplete) {
              props.onComplete(clipboard);
            }
          }
        },
      }}
      {...props}
    />
  );
};

export default CodeValidationInput;
