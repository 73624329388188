import React, { useEffect, useRef, useState } from 'react';
import styles from './AutoHeightTextarea.module.scss';

const AutoHeightTextarea = ({
  className,
  onChange,
  value,
  isInvalid,
  singleLine,
  disabled,
  autoFocus,
  ...etc
}: React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & { isInvalid?: boolean; singleLine?: boolean }) => {
  const textareaRef = useRef(null);
  const [currentValue, setCurrentValue] = useState(''); // you can manage data with it

  useEffect(() => {
    const node = textareaRef.current as any;
    if (!node || !node.style || !node.scrollHeight) {
      return;
    }

    const defaultHeight = disabled ? 0 : singleLine ? 30 : 60;

    node.style.height = '0px';
    const scrollHeight = node.scrollHeight;
    node.style.height = Math.max(defaultHeight, scrollHeight) + 'px';
  }, [currentValue, singleLine, disabled]);

  useEffect(() => {
    setCurrentValue(`${value || ''}`);
  }, [value]);

  return (
    <textarea
      autoFocus={autoFocus}
      ref={textareaRef}
      className={`${styles.textarea} ${className} ${
        isInvalid ? styles.error : ''
      }`}
      {...etc}
      disabled={disabled}
      value={currentValue}
      onChange={(e) => {
        let value = e.target.value || '';
        if (singleLine) {
          value = value.replace(/(\r\n|\n|\r)/gm, '');
        }
        e.target.value = value;

        setCurrentValue(value);
        if (onChange) {
          onChange(e);
        }
      }}
      onKeyPress={
        !singleLine
          ? undefined
          : (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }
      }
    />
  );
};
export default AutoHeightTextarea;
