/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useContext, useState, useEffect } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import GlobalStateContext from '../context/globalState/GlobalStateContext';
import {
  getSessionNotAllowedRoute,
  getDefaultPage,
  getSessionJoinRoute,
} from './routeTools';
import LoadingPage from '../pages/loading/LoadingPage';
import api from '../api/Api';
import {
  ADD_SESSION,
  SET_CONTEXT,
} from '../context/globalState/globalStateReducer';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { WithSessionAccess } from '../@types/seen-apps';
import { subject } from '@casl/ability';
import { Session } from '../@types/session-api';
import AbilityContext from '../context/AbilityContext';
import { captureException } from '@sentry/minimal';

type UrlParams = {
  sessionId: string;
  accessCode: string;
};

export function withSessionAccess(
  WrappedComponent: React.ComponentType<WithSessionAccess>,
  options?: {
    onlyOwner?: boolean;
    publicAccess?: boolean;
  }
) {
  return () => {
    const { state: globalState, dispatch } = useContext(GlobalStateContext);
    const ability = useContext(AbilityContext);
    const location = useLocation();
    const { sessionId, accessCode } = useParams<UrlParams>();
    const [redirectUrl, setRedirectUrl] = useState<string | undefined>(
      undefined
    );
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation('i18n');

    const [session, setSession] = useState<Session | undefined>(undefined);

    useEffect(() => {
      if (!sessionId) {
        toast.error(t('common.notAllowed'));
        setRedirectUrl(getDefaultPage());
        return;
      }

      if (session && session.id === sessionId) {
        return;
      }

      const sessionCache = globalState.sessions.find((s) => s.id === sessionId);
      setSession(sessionCache);

      if (!sessionCache) {
        loadSession();
      }
    }, [sessionId]);

    useEffect(() => {
      if (!session) {
        return;
      }

      const isAdmin =
        ability.can('put', subject('session', session)) ||
        ability.can('manage', 'session');

      if (!isAdmin) {
        const mySession = globalState.mySessions.find(
          (s) => s.id === sessionId
        );
        if (
          !options?.publicAccess &&
          !mySession &&
          getSessionJoinRoute({ sessionId, accessCode }) !== location.pathname
        ) {
          toast.error(t('common.notAllowed'));
          setRedirectUrl(
            getSessionNotAllowedRoute({ callerUrl: getDefaultPage() })
          );
          return;
        }
      }

      dispatch({
        type: SET_CONTEXT,
        payload: {
          sessionCtx: {
            session,
          },
        },
      });
    }, [session]);

    const loadSession = async () => {
      setLoading(true);
      try {
        const session =
          globalState.sessions.find((s) => s.id === sessionId) ||
          (await api.getSession(sessionId));
        if (!session) {
          setRedirectUrl(getDefaultPage());
        }
        setSession(session);
        dispatch({
          type: ADD_SESSION,
          payload: session,
        });
      } catch (error: any) {
        captureException(error);
        let message;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message;
        } else {
          message = 'pages.sphere.sessions.error.get';
        }
        toast.error(t(message));
      }
      setLoading(false);
    };

    return (
      <>
        {redirectUrl && <Redirect to={redirectUrl} />}
        {loading && <LoadingPage />}
        {globalState.context.sessionCtx &&
          session &&
          globalState.context.sessionCtx.session.id === session.id && (
            <WrappedComponent session={session} accessCode={accessCode} />
          )}
      </>
    );
  };
}
