import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { m as motion } from 'framer-motion';
import stylesCampaign from './CampaignPage.module.scss';
import stylesModal from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import { useTranslation } from 'react-i18next';
import { ToastOnClose } from '../../components/ModalWrapper/ModalSlideShowWrapper';
import CampaignNameView from '../../components/campaign/CampaignNameView';
import { CampaignResponse } from '../../@types/shorten-url-api';
import { getRelevantTranslationFor } from '../../tools/multiLingualTools';
import MarkdownComponent from '../../components/Markdown/MarkdownComponent';

const styles = { ...stylesModal, ...stylesCampaign };

type Props = {
  onSubmit?: () => void;
  onClose?: () => void;
  variants?: any;
  context?: Record<string, string>;
  campaign?: CampaignResponse;
  onCloseNotifications?: ToastOnClose[];
};

const CampaignEndPage: React.FC<Props> = ({
  variants,
  onSubmit = () => {},
  campaign,
}) => {
  const [message, setMessage] = useState('');
  const { t } = useTranslation('i18n');

  useEffect(() => {
    setMessage(
      getRelevantTranslationFor(campaign?.campaign?.endMessageForUser || '') ||
        t(`pages.sphere.urlAliases.section.form.defaultEndMessageForUser`)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  if (!campaign) {
    onSubmit();
    return <></>;
  }

  return (
    <motion.div
      variants={variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={styles.page}
    >
      <div className={styles.content}>
        <Container className={styles.campaignContainer}>
          <div className={styles.sphereTitle}>
            <CampaignNameView campaign={campaign} />
          </div>

          {message && (
            <div className={styles.message}>
              <MarkdownComponent text={message} />
            </div>
          )}
          <div className={styles.campaignContent}>
            <Button onClick={() => onSubmit()}>
              {t('common.action.close')}
            </Button>
          </div>
        </Container>
      </div>
    </motion.div>
  );
};

export default CampaignEndPage;
