import React, { FC, memo } from 'react';
import { Feeling } from '../../@types/seen-apps';
import styles from './IELink.module.scss';

import { useTranslation } from 'react-i18next';

import { Textfit } from 'react-textfit';

type Props = {
  feeling?: Feeling;
  size?: 'big' | 'small';
};

const IELink: FC<Props> = ({ feeling, size }) => {
  const { t } = useTranslation('i18n');

  return (
    <>
      <Textfit
        forceSingleModeWidth={false}
        mode='single'
        className={styles.link}
      >
        {t(`common.ie.more`)}
      </Textfit>
    </>
  );
};

export default memo(IELink);
