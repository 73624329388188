import menuFactory from "react-burger-menu/lib/menuFactory.js";

const styles = {
  pageWrap: (isOpen:boolean, width:string, right:boolean) => {
    return {
        transform: isOpen ? '' : right ? 'translate3d(-' + width + ', 0, 0)' : 'translate3d(' + width + ', 0, 0)',
        '-webkit-transition': 'all 0.5s',
        transition: 'all 0.5s',
        width: isOpen ? '100%' : 'calc(100% - ' + width + ')',
    };
  },
  outerContainer(isOpen: boolean) {
      return {
          overflow: isOpen ? '' : 'hidden'
      };
  }
};

export default menuFactory(styles);