/* eslint react-hooks/exhaustive-deps: 0 */
import { useEffect, FC, useState, useContext, ReactNode } from 'react';
import { Button, Container } from 'react-bootstrap';
import { m as motion } from 'framer-motion';
import api from '../../api/Api';
import { toast } from 'react-toastify';
import assessmentStyles from './StrategyAssessmentPage.module.scss';
import modalStyles from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import Question, {
  getStrategyAssessmentOptions,
} from '../../components/question/Question';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { useTranslation } from 'react-i18next';
import { ADD_OR_UPDATE_STRATEGY_ASSESSMENTS } from '../../context/globalState/globalStateReducer';
import LoadingPage from '../loading/LoadingPage';
import moment from 'moment-timezone';
import { captureException } from '@sentry/minimal';
import { ToastOnClose } from '../../components/ModalWrapper/ModalSlideShowWrapper';
import PageTitle from '../../components/PageTitle/PageTitle';
import {
  CampaignResponse,
  SessionStrategyAssessmentWizard,
  SphereStrategyAssessmentWizard,
} from '../../@types/shorten-url-api';
import { getRelevantTranslationFor } from '../../tools/multiLingualTools';
import CampaignNameView from '../../components/campaign/CampaignNameView';
import { isMobile } from 'react-device-detect';
import { Strategy } from '../../@types/strategy-api';

const styles = { ...modalStyles, ...assessmentStyles };

type Props = {
  campaign?: CampaignResponse;
  tagIds?: string;
  validationCode?: string;
  context?: Record<string, string>;
  currentPageIdx?: number;
  link?: boolean | number;
  pageElements?: ReactNode[];
  wizzard?: SessionStrategyAssessmentWizard | SphereStrategyAssessmentWizard;
  onSubmit?: () => void;
  variants?: any;
  onCloseNotifications?: ToastOnClose[];
  onClose?: () => {};
  linkTo: 'sphere' | 'session' | 'user';
};

const StrategyAssessmentPage: FC<Props> = ({
  onSubmit = () => {},
  variants,
  campaign: campaignFromContext1,
  wizzard,
  linkTo,
  tagIds,
  link,
  validationCode,
  onCloseNotifications = [],
  onClose = () => {},
}) => {
  const { t } = useTranslation('i18n');
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [values, setValues] = useState<Record<string, number | undefined>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [title, setTitle] = useState<JSX.Element>();
  const [dicoPrefix, setDicoPrefix] = useState('pages.strategy');
  const [name, setName] = useState('');
  const [options] = useState(getStrategyAssessmentOptions({ t }));

  const { dispatch } = useContext(GlobalStateContext);

  useEffect(() => {
    const forbidden = (message: string) => {
      onCloseNotifications?.push({
        content: message,
        options: {
          type: 'error',
        },
      });
      onClose();
    };

    const fetchStrategies = async () => {
      /**
       * 2 differents triggers :
       * 1. life compass
       * 2. Campaign
       *  - strategies from sphere
       *  - strategies from session
       * Priority to context
       */
      if (campaignFromContext1) {
        setTitle(<CampaignNameView campaign={campaignFromContext1} />);
      }

      let name = '';
      if (wizzard?.type === 'sessionStrategyAssessment') {
        name = getRelevantTranslationFor(wizzard.sessionName);
      } else if (wizzard?.type === 'sphereStrategyAssessment') {
        name = wizzard.sphereName || '';
      }
      setName(name);
      if (wizzard) {
      }

      let strategies: Strategy[];
      if (wizzard) {
        strategies = wizzard.strategies || [];
        setDicoPrefix('pages.strategy');
      } else {
        setDicoPrefix('pages.myself-strategy');
        strategies = (
          await api.getStrategies({
            linkTo,
            max: 1000,
          })
        ).items;
      }
      setStrategies(strategies);
      setLoading(false);

      if (strategies.length === 0) {
        onSubmit();
      }
    };

    fetchStrategies().catch((error) => {
      captureException(error);
      forbidden(t('pages.feeling.added.error'));
    });
  }, []);

  const handleSelection = (strategyId: string, value?: number) => {
    setValues({
      ...values,
      [strategyId]: value,
    });
  };

  const handleSaveClick = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const assessmentValues = Object.entries(values).reduce(
      (acc, [strategyId, value]) => {
        if (value === null || value === undefined) {
          return acc;
        }
        return { ...acc, [strategyId]: value };
      },
      {} as Record<string, number>
    );

    try {
      const locale = moment.tz.guess();
      const result = await api.addStrategyAssessment({
        sphereId: campaignFromContext1?.sphereId,
        sessionId:
          wizzard?.type === 'sessionStrategyAssessment'
            ? wizzard.sessionId
            : undefined,
        campaignId: campaignFromContext1?.id,
        validationCode,
        locale,
        fromDirectLink: !!link,
        specificTagIds: tagIds?.split(',') || [],
        values: assessmentValues,
      });
      dispatch({
        type: ADD_OR_UPDATE_STRATEGY_ASSESSMENTS,
        payload: [result],
      });
      onSubmit();
    } catch (error) {
      captureException(error);
      toast.error(t('pages.strategyAssessment.added.error', { error }));
    }
  };
  return (
    <motion.div
      variants={variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={styles.page}
    >
      <PageTitle
        rendertitle={() => <div className={styles.header}>{title}</div>}
      />

      <div className={styles.content}>
        {loading ? (
          <LoadingPage />
        ) : (
          <Container>
            <h3 className={styles.title}>
              {t(
                `pages.strategyAssessment.answer.${
                  linkTo === 'user' ? 'myself' : 'others'
                }`,
                { name: name }
              )}
            </h3>

            {strategies
              .filter((s) => !s.isHidden)
              .map((strategy) => {
                return (
                  <Question
                    key={strategy.id}
                    title={getRelevantTranslationFor(strategy.title)}
                    superTitle={
                      strategy.type === 'custom'
                        ? undefined
                        : t(`${dicoPrefix}.type.${strategy.type}`)
                    }
                    questionLabel={getRelevantTranslationFor(
                      strategy.description
                    )}
                    options={options}
                    value={values[strategy.id]}
                    onValueChange={(value) =>
                      handleSelection(strategy.id, value)
                    }
                    isMobile={isMobile}
                  />
                );
              })}
            <div className={styles.footer}>
              <Button
                variant='primary'
                disabled={Object.keys(values).length === 0}
                onClick={handleSaveClick}
              >
                {t('common.action.save')}
              </Button>
            </div>
          </Container>
        )}
      </div>
    </motion.div>
  );
};

export default StrategyAssessmentPage;
