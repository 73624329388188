import { useContext, useState, FC, useEffect } from 'react';
import stylesUpdateProfile from './UpdateThinkingPage.module.scss';
import stylesModal from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import { useTranslation } from 'react-i18next';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { useLocation } from 'react-router-dom';
import { getColor, getEmptyColor } from '../../tools/colors';
import { Container, Col, Row, Button } from 'react-bootstrap';
import {
  getReferenceAxis,
  getAxisName,
  getAxisDescription,
} from '../../tools/thinkingTools';
import api from '../../api/Api';
import { toast } from 'react-toastify';
import { UPDATE_THINKING } from '../../context/globalState/globalStateReducer';
import moment from 'moment';
import SphereNameView from '../../components/sphere/SphereNameView';
import { Thinking, AxisValue } from '../../@types/thinking-api';
import Question, { THINKING_OPTIONS } from '../../components/question/Question';
import LoadingPage from '../loading/LoadingPage';
import DateView from '../../components/date/DateView';
import { isNil } from 'lodash';
import { Sphere } from '../../@types/sphere-api';
import PageTitle from '../../components/PageTitle/PageTitle';
import ThinkingReminder from './ThinkingReminder';
import { withGeoPosition } from '../../tools/withGeoPosition';
import { WithGeoPoint } from '../../@types/seen-apps';
import SessionNameView from '../../components/session/SessionNameView';
import AbilityContext from '../../context/AbilityContext';
import { captureException } from '@sentry/minimal';
import { isMobile } from 'react-device-detect';

const styles = { ...stylesModal, ...stylesUpdateProfile };

type Props = {
  onSubmit?: (state?: any) => void;
};

const UpdateThinkingPage: FC<Props & WithGeoPoint> = ({
  onSubmit = () => {},
  geoPoint,
}) => {
  const { state, dispatch } = useContext(GlobalStateContext);
  const ability = useContext(AbilityContext);
  const location = useLocation();
  const { t } = useTranslation('i18n');
  const thinkingId =
    new URLSearchParams(location.search).get('thinkingId') || '';
  const [thinking, setThinking] = useState<Thinking | undefined>(undefined);
  const [thinkingData, setThinkingData] = useState<
    | {
        id: string;
        color: string;
        sphere?: Sphere;
        couldUpdate: boolean;
        axis?: AxisValue;
      }
    | undefined
  >(undefined);
  const [axes, setAxes] = useState<{ id: string; value?: number }[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadThinking = async (thinkingId: string) => {
      try {
        const thinkingResponse = await api.getThinking(thinkingId);

        const thinkingSphere =
          thinkingResponse && thinkingResponse.sphereId
            ? state.spheres.find((s) => s.id === thinkingResponse.sphereId)
            : undefined;

        const couldUpdate =
          !!thinkingResponse &&
          !!thinkingResponse.freezeDate &&
          !!moment(thinkingResponse.freezeDate).isAfter(moment());

        const axis = thinkingResponse
          ? getReferenceAxis(thinkingResponse)
          : undefined;
        setAxes(thinkingResponse?.axes || []);
        setThinkingData({
          id: thinkingId,
          sphere: thinkingSphere,
          color:
            !thinkingResponse || thinkingResponse.isBlank || !axis
              ? getEmptyColor()
              : getColor(axis.value),
          couldUpdate,
          axis,
        });
        setThinking(thinkingResponse);
        setLoading(false);
      } catch (error) {
        captureException(error);
        toast.error(t('common.error'));
        onSubmit();
      }
    };

    if (!loading) {
      setLoading(true);
      loadThinking(thinkingId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thinkingId]);

  const handleSaveThinking = async (thinking: Thinking) => {
    try {
      const result = await api.updateThinking(
        thinking.id,
        axes.filter((a) => !isNil(a.value)),
        geoPoint
      );
      toast.success(t('pages.thinking.updated.success'));
      dispatch({
        type: UPDATE_THINKING,
        payload: result,
      });
      onSubmit();
    } catch (error) {
      captureException(error);
      toast.error(t('pages.thinking.updated.error', { error }));
    }
  };

  const handleSelection = (id: string, value?: number) => {
    const newAxes = [...axes];
    const axisIndex = axes.findIndex((a) => id === a.id);
    if (axisIndex >= 0) {
      const axis = newAxes[axisIndex];
      newAxes[axisIndex] = { ...axis, value };
    } else {
      newAxes.push({
        id,
        value,
      });
    }
    setAxes(newAxes);
  };

  if (loading) {
    return <LoadingPage />;
  }
  if (!thinking || !thinkingData) {
    return <div>{t('common.not-found.thinking')}</div>;
  }
  const { sphere, couldUpdate } = thinkingData;
  return (
    <div className={styles.page}>
      <PageTitle title={t(`pages.thinking.update.title`)} />
      <div className={styles.content}>
        <Container className={styles.updateThinkingContainer}>
          <Row className={styles.updateThinkingRow}>
            <Col className={styles.updateThinkingCol}>
              <div className={styles.thinkingDetails}>
                <DateView date={thinking.creationDate} full />
                {thinking.session && (
                  <SessionNameView session={thinking.session} />
                )}
                <SphereNameView
                  sphere={sphere}
                  full
                  sphereName={thinking.sphereName}
                />
              </div>
            </Col>
          </Row>
          {(thinking.axes || []).map((axe) => {
            const axis = axes.find((a) => axe.id === a.id);
            return (
              <Question
                key={`axis-${axe.id}`}
                title={getAxisName({
                  axis: axe,
                  sphere: {
                    id: thinking.sphereId,
                    name: thinking.sphereName || '',
                  },
                  session: thinking.session,
                  ability,
                })}
                options={THINKING_OPTIONS}
                questionLabel={getAxisDescription({
                  axis: axe,
                  sphere: {
                    id: thinking.sphereId,
                    name: thinking.sphereName || '',
                  },
                  session: thinking.session,
                  ability,
                })}
                value={axis ? axis.value : undefined}
                onValueChange={(value) => handleSelection(axe.id, value)}
                disabled={!couldUpdate}
                isMobile={isMobile}
                forceHorizontal
              />
            );
          })}
          <Row className={styles.updateThinkingRow}>
            <Col sm={12} className={styles.buttons}>
              <Button variant='secondary' onClick={() => onSubmit()}>
                {t('common.action.close')}
              </Button>
              {thinking && couldUpdate && (
                <Button
                  variant='primary'
                  onClick={() => handleSaveThinking(thinking)}
                >
                  {t('common.action.save')}
                </Button>
              )}
            </Col>
          </Row>
          {couldUpdate && <ThinkingReminder sphere={sphere} />}
        </Container>
      </div>
    </div>
  );
};

export default withGeoPosition(UpdateThinkingPage);
