import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import styles from './LoadingPage.module.scss';

const LoadingPage: FC<{ title?: string }> = ({ title }) => {
  const { t } = useTranslation('i18n');
  return (
    <div className={styles.loader}>
      <div>
        <div className={styles.loaderImage}>
          <ReactLoading
            type={'bars'}
            color={'#DDD'}
            height={'126px'}
            width={'136px'}
          />
        </div>
        <div className={styles.loaderText}>
          <h4>{title ? title : t('common.loading')}</h4>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
