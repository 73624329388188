import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimeLineIcon } from '../RemixIcons';
import styles from './DateView.module.scss';
import moment from 'moment';

interface Props {
  full: boolean;
  date: string;
}

const DateView = ({ date, full }: Props) => {
  if (!date) {
    return <div></div>;
  }
  return !full ? (
    <Container>
      <Row>
        <Col xs={1} className={styles.iconCol}>
          <TimeLineIcon className={styles.icon} />
        </Col>
        <Col className={styles.noMarginText}>
          {moment(date).format('HH:mm')}
        </Col>
      </Row>
    </Container>
  ) : (
    <Container>
      <Row>
        <Col xs={1} className={styles.iconCol}>
          <TimeLineIcon className={styles.icon} />
        </Col>
        <Col className={styles.noMarginText}>
          {moment(date).format('LL')} {moment(date).format('HH:mm')}
        </Col>
      </Row>
    </Container>
  );
};

export default DateView;
