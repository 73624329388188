import React, { useEffect, useState } from 'react';
import { Form, DropdownButton, Dropdown, InputGroup } from 'react-bootstrap';
import styles from './MultiLingualInput.module.scss';
import { MultiLingual } from '../../@types/sphere-api';
import { useTranslation } from 'react-i18next';
import { getLanguages } from '../../i18n';
import {
  DeleteBinLineIcon,
  SetAsDefaultIcon,
  TranslateIcon,
} from '../RemixIcons';
import AutoHeightTextarea from '../input/AutoHeightTextarea';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import { getErrorTranslation } from '../../tools/errorTools';
import ReactLoading from 'react-loading';
import AbilityContext from '../../context/AbilityContext';
import { useAbility } from '@casl/react';
import api from '../../api/Api';

interface Props {
  value: MultiLingual | undefined;
  label?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
  onChange: (value: MultiLingual) => void;
  multiLine?: boolean;
  onlyDefault?: boolean;
}

const MultiLingualInput = ({
  autoFocus,
  value,
  label,
  onChange,
  disabled,
  required,
  maxLength,
  multiLine,
  onlyDefault,
}: Props) => {
  const { t } = useTranslation('i18n');
  const ability = useAbility(AbilityContext);

  const [canTranslate, setCanTranslate] = useState(false);
  const [remainingLanguages, setRemainingLanguages] = useState<string[]>(
    getLanguages()
  );
  const [languages, setLanguages] = useState<string[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCanTranslate(ability.can('post', 'translation'));
  }, [ability]);

  useEffect(() => {
    const languagesInused = Object.keys(value || {});
    setLanguages(languagesInused);

    const remainingLanguages = getLanguages().filter(
      (lang) => !languagesInused.some((l) => l === lang)
    );
    setRemainingLanguages(remainingLanguages);
    if (languagesInused.length > 0 && required && !value?.default) {
      setError(t('common.validations.required'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleInputChange = (text: string, lang: string) => {
    const newValue = { ...(value || {}), [lang]: text };
    onChange(newValue);

    if (required && !text && lang === 'default' && !error) {
      setError(t('common.validations.required'));
    } else if (error && lang === 'default') {
      setError(undefined);
    }
  };

  const handleAddLanguage = (lang: string) => {
    const newValue = { ...(value || {}), [lang]: '' };
    onChange(newValue);
  };
  const handleDefaultLanguage = (lang: string) => {
    const newValue = { ...(value || {}), default: value?.[lang] || '' };
    onChange(newValue);
  };
  const handleAutoTranslate = async (lang?: string) => {
    try {
      setLoading(true);
      const text = value?.[lang || 'default'];
      const result = !text
        ? {}
        : await api.translate({
            text,
            lang,
            targets: getLanguages().filter((l) => l !== lang),
          });
      onChange({ ...(value || {}), ...result });
      // const result = await
    } catch (error) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          error,
          t,
          defaultKey: `common.error`,
        })
      );
    }
    setLoading(false);
  };
  const handleDeleteLanguage = (lang: string) => {
    const newValue = { ...(value || {}) };
    delete newValue[lang];
    onChange(newValue);
  };

  return (
    <>
      <Form.Group controlId='default'>
        <Form.Label>
          {label}
          {required ? ' *' : ''}
        </Form.Label>
        <InputGroup className={`mb-3 ${styles.inputGroup}`}>
          <AutoHeightTextarea
            autoFocus={autoFocus}
            singleLine={!multiLine}
            isInvalid={!!error}
            className={styles.inputText}
            rows={3}
            value={value?.default || ''}
            maxLength={maxLength}
            onChange={(event: any) =>
              handleInputChange(event.target.value, 'default')
            }
            disabled={disabled}
          />

          {loading && !disabled && (
            <ReactLoading
              type={'bars'}
              color={'#DDD'}
              height={'30px'}
              width={'40px'}
            />
          )}

          {!onlyDefault && !loading && !disabled && (
            <DropdownButton
              className={styles.inputButton}
              disabled={
                loading || (!canTranslate && !remainingLanguages.length)
              }
              variant='outline-primary'
              title={t('common.action.addTranslation')}
              id='input-group-dropdown-1'
            >
              {canTranslate && (
                <Dropdown.Item
                  key={`LANG-auto`}
                  onClick={() => handleAutoTranslate()}
                >
                  <TranslateIcon /> {t('common.action.autoTranslation')}
                </Dropdown.Item>
              )}
              {remainingLanguages.map((lang) => (
                <Dropdown.Item
                  key={`LANG-${lang}`}
                  onClick={() => handleAddLanguage(lang)}
                >
                  {lang}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          )}
          <Form.Control.Feedback type='invalid'>{error}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      {languages
        .filter((l) => l !== 'default')
        .map((lang) => (
          <Form.Group
            className={styles.language}
            controlId={`lang-${lang}`}
            key={`INPUT-LANG-${lang}`}
          >
            <InputGroup className={`mb-3 ${styles.inputGroup}`}>
              <InputGroup.Text
                className={styles.langLabel}
                id={`lang-label-${lang}`}
              >
                {lang}
              </InputGroup.Text>

              <AutoHeightTextarea
                singleLine={!multiLine}
                className={styles.inputText}
                maxLength={maxLength}
                value={value?.[lang] || ''}
                onChange={(event: any) =>
                  handleInputChange(event.target.value, lang)
                }
                disabled={disabled || loading}
                autoFocus
              />

              {!disabled && (
                <DropdownButton
                  className={styles.inputButton}
                  disabled={loading}
                  variant='outline-primary'
                  title={'...'}
                  id='input-group-dropdown-1'
                >
                  <Dropdown.Item
                    key={`LANG-${lang}-delete`}
                    onClick={() => handleDeleteLanguage(lang)}
                  >
                    <DeleteBinLineIcon /> {t('common.action.delete')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    key={`LANG-${lang}-delete`}
                    onClick={() => handleDefaultLanguage(lang)}
                  >
                    <SetAsDefaultIcon /> {t('common.action.setDefault')}
                  </Dropdown.Item>
                  {canTranslate && (
                    <Dropdown.Item
                      key={`LANG-${lang}-delete`}
                      onClick={() => handleAutoTranslate(lang)}
                    >
                      <TranslateIcon /> {t('common.action.autoTranslation')}
                    </Dropdown.Item>
                  )}
                </DropdownButton>
              )}
            </InputGroup>
          </Form.Group>
        ))}
    </>
  );
};

export default MultiLingualInput;
