import React, { useEffect } from 'react';

export function withStonly(WrappedComponent: React.ComponentType) {
  return () => {
    useEffect(() => {
      const scripts: HTMLScriptElement[] = [];
      let script = document.createElement('script');
      scripts.push(script);
      script.innerHTML = `window.STONLY_WID = "4a4561b5-3303-11ec-83f1-062882f67cfe"`;
      document.body.appendChild(script);

      script = document.createElement('script');
      scripts.push(script);
      script.innerHTML = `!function(s,t,o,n,l,y,w,g){s.StonlyWidget||((w=s.StonlyWidget=function(){
        w._api?w._api.apply(w,arguments):w.queue.push(arguments)}).scriptPath=n,w.queue=[],(y=t.createElement(o)).async=!0,
        (g=new XMLHttpRequest).open("GET",n+"version?v="+Date.now(),!0),g.onreadystatechange=function(){
        4===g.readyState&&(y.src=n+"stonly-widget.js?v="+(200===g.status?g.responseText:Date.now()),
        (l=t.getElementsByTagName(o)[0]).parentNode.insertBefore(y,l))},g.send())
        }(window,document,"script","https://stonly.com/js/widget/v2/");
      `;
      document.body.appendChild(script);

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }, []);

    useEffect(() => {
      const closeStonlyPopup = () => {
        const stonlyWidgets = document.getElementsByClassName(
          'css-sn1vqs eo30uvs4'
        );
        for (let index = 0; index < stonlyWidgets.length; index++) {
          const element = stonlyWidgets.item(index);
          if (element) {
            (element as any).click();
          }
        }
      };

      document.addEventListener(`click`, closeStonlyPopup);
      return () => {
        document.removeEventListener(`click`, closeStonlyPopup);
      };
    }, []);

    return <WrappedComponent />;
  };
}
