import React, { useEffect, useState } from 'react';
import selectStyles from '../../../styles/react-select.module.scss';
import styles from './SelectAnswerComponent.module.scss';
import { SelectMeasureType } from '../../../@types/form-api';
import Select, { OptionsType } from 'react-select';
import { useTranslation } from 'react-i18next';
import { Badge } from 'react-bootstrap';
import AutoHeightTextarea from '../../input/AutoHeightTextarea';

interface AnswerComponentProps {
  id: string;
  measureType: SelectMeasureType;
  value?: string;
  onChange?: (value?: any) => void;
  disabled?: boolean;
  printMode?: boolean;
}

type OptionType = {
  value: string;
  label: string;
};

const SelectAnswerComponent: React.FC<AnswerComponentProps> = ({
  measureType,
  id,
  value,
  onChange,
  disabled,
  printMode,
}) => {
  const { t } = useTranslation('i18n');
  const [options, setOptions] = useState<OptionsType<OptionType>>();
  const [values, setValues] = useState<OptionsType<OptionType>>();
  const [isMaxReached, setMaxReached] = useState<boolean>(false);

  useEffect(() => {
    const options = measureType.selectOptions.values.map((val) => ({
      label: val.title,
      value: val.value,
    }));
    setOptions(options);

    const values = (value || '').split(';');
    setValues(
      options.filter((option) => values.find((val) => option.value === val))
    );
  }, [measureType.selectOptions.values, value]);

  useEffect(() => {
    const maxReached =
      measureType.selectOptions.multiChoiceMax &&
      values &&
      values.length >= measureType.selectOptions.multiChoiceMax;
    setMaxReached(maxReached || false);
  }, [measureType.selectOptions.multiChoiceMax, values]);

  const handleValueChange = (valueSelected: any) => {
    const data = Array.isArray(valueSelected)
      ? valueSelected.map((v) => v.value).join(';')
      : valueSelected.value;
    if (onChange) {
      onChange(data);
    }
  };

  if (printMode) {
    return measureType.selectOptions.multiChoice ? (
      <div className={styles.selectPrint}>
        {(values || []).map((value) => (
          <Badge key={`select-response-${value.value}`}>{value.label}</Badge>
        ))}
      </div>
    ) : (
      <div className={styles.text}>
        <AutoHeightTextarea
          className={`${styles.description} ${disabled ? styles.disabled : ''}`}
          key={`selected-${id}`}
          rows={1}
          value={values?.length ? values[0].label : ''}
          onChange={() => {}}
          disabled
          singleLine
        />
      </div>
    );
  }

  return (
    <div className={styles.select}>
      <div className={selectStyles.reactSelect}>
        <Select
          name={id}
          options={options}
          isSearchable
          isOptionDisabled={() => isMaxReached}
          isClearable={measureType.selectOptions.multiChoice}
          isMulti={measureType.selectOptions.multiChoice}
          noOptionsMessage={() => t('common.noValue')}
          className={selectStyles.reactSelect}
          classNamePrefix='custom-react-select'
          placeholder={
            !printMode && measureType.selectOptions.placeholder
              ? measureType.selectOptions.placeholder
              : ''
          }
          onChange={handleValueChange}
          value={values}
          disabled={disabled}
          isDisabled={disabled}
        />
        {!printMode && measureType.selectOptions.multiChoiceMax && (
          <div className={styles.condition}>
            <Badge variant='dark'>
              {values?.length || 0}/{measureType.selectOptions.multiChoiceMax}
            </Badge>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectAnswerComponent;
