import { useTranslation } from 'react-i18next';
import styles from './Login.module.scss';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { EmailIcon } from '../RemixIcons';
import { Auth } from 'aws-amplify';
import { toast, ToastOptions } from 'react-toastify';
import { getErrorTranslation } from '../../tools/errorTools';
import ReactLoading from 'react-loading';
import CodeValidationInput from './CodeValidationInput';
import api from '../../api/Api';
import { v4 as uuidv4 } from 'uuid';
import { getCurrentLanguage } from '../../i18n';
import { LoginFlow } from '../../@types/seen-apps';
import { useImmer } from 'use-immer';
import MarkdownComponent from '../Markdown/MarkdownComponent';

const TOAST_ERROR_OPTIONS: ToastOptions = {
  position: 'top-center',
  autoClose: 15000,
};

const Login = () => {
  const { t } = useTranslation('i18n');

  const [email, setEmail] = useState<string>('');
  const [flow, setFlow] = useImmer<LoginFlow>({
    mode: 'initial',
  });
  const [loading, setLoading] = useState(false);

  const componentWithFocus = useRef(null);

  useEffect(() => {
    setFlow({ mode: 'initial' });

    const delayDebounceFn = setTimeout(async () => {
      if (!email) {
        return;
      }
      const emailValue = email.trim().toLowerCase();
      const index = emailValue.indexOf('@');
      const lastIndexDot = emailValue.lastIndexOf('.');

      //Email not complete
      if (
        index <= 0 ||
        index >= emailValue.length - 1 ||
        index > lastIndexDot ||
        lastIndexDot >= emailValue.length - 1
      ) {
        return;
      }
      setLoading(true);
      try {
        await api.signIn({ email: emailValue });
        let flow: LoginFlow = {
          mode: 'selection',
          email: emailValue,
        };
        setFlow(flow);
      } catch (error) {
        toast.error(
          getErrorTranslation({
            t,
            error,
          }),
          {
            ...TOAST_ERROR_OPTIONS,
            autoClose: 15000,
          }
        );
      }
      setLoading(false);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, t]);

  const handleUsePasswordFlowClicked = () => {
    if (flow.mode !== 'code' && flow.mode !== 'selection') {
      return;
    }
    setFlow({
      mode: 'password',
      status: 'initial',
      email: flow.email,
    });
  };
  const handleSendCodeClicked = async () => {
    if (
      flow.mode !== 'password' &&
      flow.mode !== 'selection' &&
      flow.mode !== 'creation'
    ) {
      return;
    }
    setFlow({
      mode: 'code',
      status: 'initial',
      attempts: 0,
      email: flow.email,
    });

    setLoading(true);
    try {
      const cognitoUser = await Auth.signIn(flow.email, undefined, {
        authFlowType: 'CUSTOM_CHALLENGE',
      });
      setFlow({
        mode: 'code',
        status: 'requested',
        email: flow.email,
        attempts: 0,
        cognitoUser,
      });
    } catch (error: any) {
      if (error.code === 'InvalidLambdaResponseException') {
        toast.error(t('api.error.too-many-codes-sent'), {
          ...TOAST_ERROR_OPTIONS,
          autoClose: false,
        });
      } else if (error.code === 'UserNotFoundException') {
        setFlow({
          mode: 'creation',
          status: 'forced',
          email: flow.email,
        });
      } else {
        toast.error(
          getErrorTranslation({
            t,
            error,
          }),
          TOAST_ERROR_OPTIONS
        );
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const element: any = componentWithFocus?.current;
    if (element?.focus) {
      element.focus();
    }
  }, []);

  const handleLoginWithCode = async (code: string) => {
    if (loading || flow.mode !== 'code') {
      return;
    }
    setLoading(true);
    try {
      const cognitoUser = await Auth.sendCustomChallengeAnswer(
        flow.cognitoUser,
        code
      );
      if (cognitoUser.signInUserSession) {
        //User successed the connection and Will be automatically redirected to the app
        return;
      }
      toast.error(
        getErrorTranslation({
          t,
          error: {},
          defaultKey: `loginflow.code.error`,
        }),
        TOAST_ERROR_OPTIONS
      );
      setFlow((draftState) => {
        if (draftState.mode === 'code') {
          draftState.cognitoUser = cognitoUser;
          draftState.attempts = draftState.attempts + 1;
        }
      });
      console.log(`Connection response: `);
      console.log(cognitoUser);
    } catch (error: any) {
      if (error.code === 'NotAuthorizedException') {
        toast.error(
          t('api.error.code-expired', {
            ...TOAST_ERROR_OPTIONS,
            autoClose: false,
          })
        );
        setLoading(false);
        setFlow({
          mode: 'selection',
          email,
        });
        return;
      }

      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey:
            flow.attempts >= 5
              ? `loginflow.code.tooManyAttempts`
              : `loginflow.code.error`,
        }),
        TOAST_ERROR_OPTIONS
      );
      if (flow.attempts >= 5) {
        setFlow({
          mode: 'selection',
          email: flow.email,
        });
      }
    }
    setLoading(false);
  };
  const handleLoginWithPassword = async () => {
    if (loading || flow.mode !== 'password') {
      return;
    }

    setLoading(true);
    try {
      await Auth.signIn({
        password: flow.password || '',
        username: flow.email || '',
      });
    } catch (error: any) {
      if (error.code === 'UserNotFoundException') {
        setFlow({
          mode: 'creation',
          status: 'forced',
          email: flow.email,
        });
      } else {
        toast.error(
          getErrorTranslation({
            t,
            error,
            defaultKey: `loginflow.password.error`,
          }),
          TOAST_ERROR_OPTIONS
        );
      }
    }
    setLoading(false);
  };

  const handlePasswordKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleLoginWithPassword();
    }
  };
  const handleRequestToCreateAccountClicked = () => {
    setFlow({
      mode: 'creation',
      email,
      status: 'requested',
    });
  };
  const handleCancelCreationClicked = () => {
    setFlow({
      mode: 'selection',
      email,
    });
  };

  const handleCreateAccountClicked = async () => {
    if (flow.mode !== 'creation' || !flow.name || !flow.acceptPrivacy) {
      return;
    }

    setLoading(true);
    const password = `Pwd1_${uuidv4()}`;
    try {
      const cognitoUser = await Auth.signUp({
        username: flow.email,
        password,
        attributes: {
          email: flow.email,
          locale: getCurrentLanguage(),
          given_name: flow.name,
        },
      });
      setFlow((draftState) => {
        if (draftState.mode === 'creation') {
          draftState.status = 'validation';
          draftState.cognitoUser = cognitoUser;
          draftState.password = password;
        }
      });
    } catch (error: any) {
      // Utilisateur existant
      if (error.code === 'UsernameExistsException') {
        handleSendCodeClicked();
        return;
      }

      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey: `loginflow.creation.error`,
        }),
        TOAST_ERROR_OPTIONS
      );
    }
    setLoading(false);
  };
  const handleConfirmCreationWithCode = async (code: string) => {
    if (flow.mode !== 'creation' || !flow.name) {
      return;
    }

    setLoading(true);
    try {
      await Auth.confirmSignUp(flow.email, code);
      setFlow((draftState) => {
        if (draftState.mode === 'creation') {
          draftState.status = 'validation';
        }
      });

      await Auth.signIn(flow.email, flow.password);
    } catch (error: any) {
      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey:
            error.code === 'CodeMismatchException'
              ? `loginflow.creation.codeError`
              : `loginflow.creation.validationError`,
        }),
        TOAST_ERROR_OPTIONS
      );
    }
    setLoading(false);
  };

  return (
    <Container className={styles.root}>
      <Row className={styles.noMargin}>
        <Col sm={12} className={styles.noMargin}>
          <h3 className={styles.title}>{t(`login.Sign in to your account`)}</h3>
        </Col>
      </Row>
      {/* <Row className={styles.noMargin}>
          <Col sm={12} className={styles.noMargin}>
            <pre style={{ color: 'white' }}>
              {JSON.stringify(flow, null, 2)}
            </pre>
          </Col>
        </Row> */}

      <Row className={styles.noMargin}>
        <Col sm={12} className={`${styles.noMargin}`}>
          <Form.Group controlId='email' className={styles.email}>
            <Form.Label>{t('login.Email')}</Form.Label>
            <Form.Control
              ref={componentWithFocus}
              autoFocus
              required
              type='text'
              value={email}
              onChange={(event) =>
                setEmail(
                  event.target.value
                    ? event.target.value.trim().toLowerCase()
                    : ''
                )
              }
            />
            {flow.mode === 'initial' && loading && (
              <div className={styles.emailLoading}>
                <ReactLoading
                  type={'bars'}
                  color={'#DDD'}
                  height={'15px'}
                  width={'25px'}
                />
                {t('loginflow.checking')}
              </div>
            )}
            {flow.mode !== 'initial' &&
              flow.mode !== 'password' &&
              flow.mode !== 'creation' && (
                <div className={`${styles.link}`}>
                  <Button
                    disabled={loading}
                    variant='link'
                    onClick={handleUsePasswordFlowClicked}
                  >
                    {t(`loginflow.password.title`)}
                  </Button>
                </div>
              )}
          </Form.Group>
        </Col>
      </Row>
      {flow.mode === 'password' && (
        <>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin}`}>
              <Form.Group controlId='password'>
                <Form.Label>{t('login.Password')}</Form.Label>
                <Form.Control
                  required
                  autoFocus
                  type='password'
                  value={flow.password}
                  onChange={(event) => {
                    const password = event.target.value;
                    setFlow((draftState) => {
                      if (draftState.mode === 'password') {
                        draftState.password = password;
                      }
                    });
                  }}
                  onKeyDown={handlePasswordKeyDown}
                />
                <div className={`${styles.link}`}>
                  <Button
                    disabled={loading}
                    variant='link'
                    onClick={handleSendCodeClicked}
                  >
                    {t(`loginflow.code.title`)}
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin} ${styles.buttons}`}>
              <Button disabled={loading} onClick={handleLoginWithPassword}>
                {loading ? (
                  <div className={styles.loading}>
                    <ReactLoading
                      type={'bars'}
                      color={'#DDD'}
                      height={'20px'}
                      width={'30px'}
                    />
                    {t(`loginflow.processing`)}
                  </div>
                ) : (
                  <>{t(`loginflow.connect`)}</>
                )}
              </Button>
            </Col>
          </Row>
        </>
      )}
      {(flow.mode === 'selection' ||
        (flow.mode === 'code' && flow.status === 'initial')) && (
        <>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin} ${styles.buttons}`}>
              <Button disabled={loading} onClick={handleSendCodeClicked}>
                {loading ? (
                  <div className={styles.loading}>
                    <ReactLoading
                      type={'bars'}
                      color={'#DDD'}
                      height={'20px'}
                      width={'30px'}
                    />
                    {t(`loginflow.code.processing`)}
                  </div>
                ) : (
                  <>
                    <EmailIcon />
                    {t(`loginflow.code.title`)}
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </>
      )}
      {flow.mode === 'code' && flow.status === 'requested' && (
        <>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin}`}>
              <Form.Group controlId='code'>
                <Form.Label>{t('loginflow.code.code')}</Form.Label>
                <Form.Text>{t('loginflow.code.expiration')}</Form.Text>
                <div className={styles.codeContainer}>
                  <CodeValidationInput
                    autoFocus
                    onComplete={handleLoginWithCode}
                    onChange={(code) =>
                      setFlow((draftState) => {
                        if (draftState.mode === 'code') {
                          draftState.code = code;
                        }
                      })
                    }
                    value={flow.code}
                  />
                  {loading && (
                    <ReactLoading
                      type={'bars'}
                      color={'#DDD'}
                      height={'50px'}
                      width={'100px'}
                    />
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}

      {flow.mode === 'creation' && flow.status === 'forced' && (
        <>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin} ${styles.buttons}`}>
              <Alert variant='secondary' className={styles.info}>
                <MarkdownComponent
                  text={t(`loginflow.creation.noAccountFound`)}
                />
              </Alert>
            </Col>
          </Row>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin} ${styles.buttons}`}>
              <Button
                variant={'primary'}
                disabled={loading}
                onClick={handleRequestToCreateAccountClicked}
              >
                {t('loginflow.creation.noAccount')}
              </Button>
            </Col>
          </Row>
        </>
      )}
      {flow.mode === 'creation' && flow.status !== 'forced' && (
        <>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin}`}>
              <Form.Group controlId='name'>
                <Form.Label>{t('login.Given_name')}*</Form.Label>
                <Form.Control
                  required
                  autoFocus
                  type='text'
                  disabled={loading || flow.status === 'validation'}
                  value={flow.name}
                  onChange={(event) => {
                    const name = event.target.value;
                    setFlow((draftState) => {
                      if (draftState.mode === 'creation') {
                        draftState.name = name;
                      }
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin}`}>
              <Form.Check
                className={styles.checkbox}
                id={`acceptPrivacy`}
                type='checkbox'
                disabled={loading || flow.status === 'validation'}
                label={
                  <MarkdownComponent
                    className={styles.privacy}
                    text={`${t(`loginflow.creation.acceptPrivacy`, {
                      locale: getCurrentLanguage(),
                    })} *`}
                  />
                }
                checked={flow.acceptPrivacy}
                onChange={(event: any) => {
                  const checked = event.target.checked;
                  setFlow((draftState) => {
                    if (draftState.mode === 'creation') {
                      draftState.acceptPrivacy = checked;
                    }
                  });
                }}
              />
            </Col>
          </Row>
        </>
      )}
      {flow.mode === 'creation' && flow.status === 'requested' && (
        <>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin} ${styles.buttons}`}>
              <div className={`${styles.creationButtons}`}>
                <div className={`${styles.link} ${styles.cancelLink}`}>
                  <Button
                    disabled={loading}
                    variant='link'
                    onClick={handleCancelCreationClicked}
                  >
                    {t(`common.action.cancel`)}
                  </Button>
                </div>
                <Button
                  disabled={loading || !flow.name || !flow.acceptPrivacy}
                  onClick={handleCreateAccountClicked}
                >
                  {loading ? (
                    <div className={styles.loading}>
                      <ReactLoading
                        type={'bars'}
                        color={'#DDD'}
                        height={'20px'}
                        width={'30px'}
                      />
                      {t(`loginflow.creation.creating`)}
                    </div>
                  ) : (
                    <>{t('loginflow.creation.title')}</>
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
      {flow.mode === 'creation' && flow.status === 'validation' && (
        <>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin}`}>
              <Form.Group controlId='code'>
                <Form.Label>{t('loginflow.code.code')}</Form.Label>
                <div className={styles.codeContainer}>
                  <CodeValidationInput
                    autoFocus
                    onComplete={handleConfirmCreationWithCode}
                    onChange={(code) =>
                      setFlow((draftState) => {
                        if (draftState.mode === 'creation') {
                          draftState.code = code;
                        }
                      })
                    }
                    value={flow.code}
                  />
                  {loading && (
                    <ReactLoading
                      type={'bars'}
                      color={'#DDD'}
                      height={'50px'}
                      width={'100px'}
                    />
                  )}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      {flow.mode !== 'creation' && flow.mode !== 'initial' && (
        <>
          <Row className={styles.noMargin}>
            <Col sm={12} className={`${styles.noMargin} ${styles.noAccount}`}>
              {t('login.No account? ')}
              <Button
                variant={'link'}
                disabled={loading}
                onClick={handleRequestToCreateAccountClicked}
              >
                {t('loginflow.creation.title')}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};
export default Login;
