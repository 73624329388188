import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './NoteCard.module.scss';

import RoundedButton from '../../RoundedButton/RoundedButton';
import { DeleteBinLineIcon } from '../../RemixIcons';
import { useTranslation } from 'react-i18next';
import AutoHeightTextarea from '../../input/AutoHeightTextarea';
import { Note } from '../../../@types/seen-apps';

interface Props extends RouteComponentProps {
  note: Note;
  isPersonal?: boolean;
  updated?: boolean;
  disabled?: boolean;
  couldDelete?: boolean;
  onDelete?: () => void;
  onChange?: (note: Note) => void;
  variant: 'primary' | 'secondary';
}

const NoteCard = ({
  note,
  variant,
  disabled,
  updated = false,
  isPersonal = false,
  onDelete,
  onChange,
}: Props) => {
  const { t } = useTranslation('i18n');

  const [value, setValue] = useState<string>();

  useEffect(() => {
    setValue(note.value);
  }, [note]);

  const handleDelete = (event: any) => {
    event.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };

  const handleChange = (note: Note) => {
    if (onChange) {
      onChange(note);
    }
  };

  const handleValueChange = (value: string) => {
    setValue(value);
    handleChange({ ...note, value });
  };

  return (
    <div className={styles.root}>
      <AutoHeightTextarea
        className={`${styles.value} ${
          disabled ? styles.disabled : styles.active
        }`}
        key='value'
        rows={3}
        placeholder={t(`pages.note.value`)}
        value={value}
        maxLength={280}
        onChange={(event: any) => handleValueChange(event.target.value)}
        disabled={disabled}
      />
      {!disabled && (
        <RoundedButton className={styles.delete} onClick={handleDelete}>
          <DeleteBinLineIcon />
        </RoundedButton>
      )}
    </div>
  );
};
export default withRouter(NoteCard);
