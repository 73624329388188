import styles from './Question.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { Legend } from '../../@types/form-api';
import ThinkingPolygon from '../sphere/ThinkingPolygon';

import { useEffect, useState } from 'react';
import MarkdownComponent from '../Markdown/MarkdownComponent';

export const NO_COLOR = '#00E5DB';
export const THINKING_OPTIONS: Option[] = [
  {
    color: '#FF0000',
    value: -1,
  },
  {
    color: '#FF7B00',
    value: -0.5,
  },
  {
    color: '#FFD747',
    value: 0,
  },
  {
    color: '#99FF99',
    value: 0.5,
  },
  {
    color: '#00FF00',
    value: 1,
  },
];
export const getStrategyAssessmentOptions = ({ t }: { t: any }): Option[] => [
  {
    color: '#FF0000',
    value: -1,
    legend: t(`pages.strategyAssessment.legend.veryLow`),
  },
  {
    color: '#FF7B00',
    value: -0.5,
    legend: t(`pages.strategyAssessment.legend.low`),
  },
  {
    color: '#FFD747',
    value: 0,
    legend: t(`pages.strategyAssessment.legend.neutral`),
  },
  {
    color: '#99FF99',
    value: 0.5,
    legend: t(`pages.strategyAssessment.legend.high`),
  },
  {
    color: '#00FF00',
    value: 1,
    legend: t(`pages.strategyAssessment.legend.veryHigh`),
  },
];

type Option = {
  value: number;
  color?: string;
  legend?: Legend;
};
interface Props {
  title?: string;
  superTitle?: string;
  questionLabel?: string;
  value?: number;
  disabled?: boolean;
  onValueChange: (value: number) => void;
  options: Option[];
  isMobile?: boolean;
  forceHorizontal?: boolean;
}

const Question = ({
  title,
  superTitle,
  questionLabel,
  value,
  disabled,
  options,
  isMobile,
  forceHorizontal,
  onValueChange,
}: Props) => {
  const handleBarSelection = (value: number) => {
    onValueChange(value);
  };

  const [verticalView, setVerticalView] = useState(isMobile);
  const [size, setSize] = useState<'small' | 'medium'>('medium');

  useEffect(() => {
    setSize(isMobile || window.innerWidth < 530 ? 'small' : 'medium');
    setVerticalView(!forceHorizontal && (isMobile || options.length > 7));
  }, [isMobile, options, forceHorizontal]);

  return (
    <Container className={styles.noMargin}>
      <Row className={styles.noMargin}>
        <Col className={styles.noMargin}>
          <Container className={styles.noMargin}>
            {title && (
              <Row className={styles.noMargin}>
                <Col className={styles.noMargin}>
                  <div className={styles.titleContainer}>
                    <span className={styles.title}>{title}</span>
                    {/* <img src={srcIcon} alt='icon' style={{ marginRight: '9px' }} /> */}
                    <div className={styles.line}></div>
                  </div>
                  {superTitle && (
                    <div className={styles.superTitle}>{superTitle}</div>
                  )}
                </Col>
              </Row>
            )}

            {questionLabel && (
              <Row className={styles.noMargin}>
                <Col className={styles.noMargin}>
                  <MarkdownComponent text={questionLabel} />
                </Col>
              </Row>
            )}
          </Container>
        </Col>
      </Row>
      <Row className={styles.noMargin}>
        <Col className={styles.noMargin}>
          <div className={verticalView ? styles.rootVertical : styles.root}>
            {options.map((option, index) => {
              let statusClassName = disabled ? styles.disabled : styles.active;
              const color = option.color || NO_COLOR;
              let fill = disabled ? color : 'none';
              if (option.value === value) {
                fill = color;
                statusClassName = styles.selected;
              }

              if (verticalView) {
                return (
                  <div
                    key={`QUESTION-${index}-${option.value}`}
                    className={`${styles.questionVertical} ${statusClassName}`}
                    onClick={() => handleBarSelection(option.value)}
                  >
                    <div className={styles.polygon}>
                      <ThinkingPolygon
                        color={color}
                        fillColor={fill}
                        size={'small'}
                      />
                      <span className={`${styles.legendMobile}`}>
                        {option.legend || ''}
                      </span>
                    </div>
                  </div>
                );
              }

              return (
                <div
                  key={`QUESTION-${option.value}`}
                  className={`${
                    size === 'small' ? styles.questionMobile : styles.question
                  } ${statusClassName}`}
                  onClick={() => handleBarSelection(option.value)}
                >
                  <div
                    className={
                      size === 'small' ? styles.polygonMobile : styles.polygon
                    }
                  >
                    <ThinkingPolygon
                      color={color}
                      fillColor={fill}
                      size={size}
                    />
                  </div>
                  <div className={`${styles.legendTxt}`}>
                    <span className={styles.legend}>{option.legend || ''}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </Container>

    // <div className={styles.root}>
    //   <div className={styles.header}>
    //     <span className={styles.questionLabel}>{}</span>
    //   </div>
    //   <div className={styles.selection}>
    //     <div className={styles.leftPart}>
    //       <span className={styles.title}>{title}</span>
    //     </div>

    //   </div>
    // </div>
  );
};

export default Question;
