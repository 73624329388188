import React from 'react';
import styles from './SignUpPage.module.scss';
import GuestRegistrationPage from '../guest/GuestRegistrationPage';

interface SignUpPageProps {
  onSubmit?: (state?: any) => void;
  onCancel?: (state?: any) => void;
}

const SignUpPage: React.FC<SignUpPageProps> = ({ onSubmit, onCancel }) => {
  return (
    <div className={styles.root}>
      <GuestRegistrationPage
        feature={'newAccount'}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default SignUpPage;
