import { Filters, FacetValue } from '../../@types/seen-apps';
import MultiSelectFacet, { OptionType } from './MultiSelectFacet';
import { useTranslation } from 'react-i18next';

const GoalLevelMultiSelectFacet = () => {
  const { t } = useTranslation('i18n');

  return (
    <MultiSelectFacet
      label={t('common.filters.feeling.goals.hit.title')}
      placeholder=''
      isMulti={true}
      isClearable={true}
      isSearchable={false}
      facetName='goalLevels'
      convertFacetsToOptions={async (facets: FacetValue[]) => {
        const data = [...facets].sort(
          (f1, f2) => parseInt(f1.value) - parseInt(f2.value)
        );
        return data.map((f) => {
          return {
            count: f.count,
            label: t(`common.filters.feeling.goals.hit.${f.label || f.value}`),
            value: f.value,
          };
        });
      }}
      updateFilters={(filters: Filters, options: OptionType[]) => {
        const updates = {
          ...filters,
          feeling: {
            ...(filters.feeling || {}),
            goalLevels: options.map((o) => parseInt(o.value)),
          },
        };
        return updates;
      }}
      optionsSelected={(filters: Filters, options: OptionType[]) => {
        const goalLevels = (filters.feeling.goalLevels || []) as string[];
        return options.filter((option) =>
          goalLevels.some((id) => option.value === id)
        );
      }}
    />
  );
};

export default GoalLevelMultiSelectFacet;
