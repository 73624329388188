import React, { useEffect, useState } from 'react';

import { Step } from '../../@types/form-api';
import AnswerComponent from './AnswerComponent';
import { hasDynamicValues, replaceDynamicValues } from '../../tools/formTools';
import MarkdownComponent from '../Markdown/MarkdownComponent';
// import * as remarkSubSuper from 'remark-supersub';

interface StepComponentProps {
  step: Step;
  disabled?: boolean;
  printMode?: boolean;
  saving?: boolean;
  showError: boolean;
  values: Record<string, any>;
  onChange?: (id: string, value: any) => void;
}

const StepComponent: React.FC<StepComponentProps> = ({
  onChange = () => {},
  step,
  values,
  showError,
  disabled,
  saving,
  printMode,
}) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [isDynamic, setDynamic] = useState(false);

  useEffect(() => {
    setTitle(step.title);
    setDescription(step.description || '');
    setDynamic(
      hasDynamicValues(step.title) || hasDynamicValues(step.description)
    );
  }, [step.title, step.description]);

  useEffect(() => {
    if (isDynamic) {
      setTitle(replaceDynamicValues(step.title, values));
      setDescription(replaceDynamicValues(step.description, values));
    }

    setDynamic(
      hasDynamicValues(step.title) || hasDynamicValues(step.description)
    );
  }, [values, isDynamic, step.title, step.description]);
  return (
    <div>
      <h3>
        <MarkdownComponent text={title} />
      </h3>

      {step.description && <MarkdownComponent text={description} />}

      {step.answers.map((answer) => {
        return (
          <div id={`answer-${answer.id}`}>
            <AnswerComponent
              printMode={printMode}
              showError={showError}
              key={`${answer.id}`}
              onChange={(value) => onChange(answer.id, value)}
              answer={answer}
              values={values || {}}
              disabled={disabled || saving}
            />
          </div>
        );
      })}
    </div>
  );
};

export default StepComponent;
