import React, { useEffect, useState } from 'react';
import styles from './KeywordInput.module.scss';
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  ButtonGroup,
} from 'react-bootstrap';
import { AddLineIcon } from '../RemixIcons';

const KeywordInput: React.FC<{
  label: string;
  keywords: string[];
  onChange: (keywords: string[]) => void;
}> = ({ keywords, onChange, label }) => {
  const [keywordInput, setKeywordInput] = useState<string>('');

  const handleAddition = (keyword: string, disableAutoFocus?: boolean) => {
    const domain = keyword.trim().toLocaleLowerCase();
    const exist = !!keywords.find(
      (d) => d.trim().toLocaleLowerCase() === domain
    );
    if (!exist) {
      const result = [...keywords, domain];
      onChange(result);
    }
    setKeywordInput('');

    const element = document.getElementById('keywords');
    if (element) {
      element.focus();
    }
  };

  const handleDelete = (index: number) => {
    const result = [...keywords];
    result.splice(index, 1);
    onChange(result);
  };
  const handleInputChange = (event: any) => {
    setKeywordInput(event.target.value || '');
  };

  const handleInputBlur = (event: any) => {
    if (keywordInput) {
      handleAddition(keywordInput, true);
    }
  };

  const addTag = (value: string) => {
    handleAddition(value);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      addTag(keywordInput);
    }
  };

  useEffect(() => {});

  return (
    <Form.Group controlId='keywords'>
      <Form.Label>{label}</Form.Label>

      <Container className={styles.noMargin}>
        <Row className={styles.noMargin}>
          <Col sm={12} className={`${styles.noMargin} ${styles.domains}`}>
            {keywords.map((keyword, index) => (
              <ButtonGroup className={styles.domain} key={`keyword-${keyword}`}>
                <Button disabled variant='outline-primary'>
                  {keyword}
                </Button>
                <Button
                  onClick={() => handleDelete(index)}
                  className={styles.delete}
                  variant='primary'
                >
                  X
                </Button>
              </ButtonGroup>
            ))}
          </Col>
        </Row>
        <Row className={styles.noMargin}>
          <Col sm={12} className={`${styles.noMargin} ${styles.tagsContainer}`}>
            <Form.Control
              name='domainTag'
              type='text'
              placeholder={label}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={keywordInput}
            />
            <Button
              variant='outline-primary'
              disabled={keywordInput.length < 1}
              onClick={() => addTag(keywordInput)}
            >
              <AddLineIcon />
            </Button>
          </Col>
        </Row>
      </Container>
    </Form.Group>
  );
};

export default KeywordInput;
