import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from '../PageTitle/PageTitle';
import stylesModal from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import stylesChat from './ChatPage.module.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import ChatDialog, {
  ChatMessage,
  ChatSuggestion,
  ContextQuestion,
  ContextQuestionResponse,
} from './ChatDialog';
import api from '../../api/Api';
import { ChatResponse, FeelingContext } from '../../@types/ai-api';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import al from '../../assets/images/al.webp';
import AbilityContext from '../../context/AbilityContext';
import { subject } from '@casl/ability';
import { Redirect } from 'react-router-dom';
import { getDefaultPage } from '../../tools/routeTools';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/react';
import { sleep } from '../../tools/asyncTools';
import RoundedButton from '../RoundedButton/RoundedButton';
import { ChatSendIcon, CloseLineIcon } from '../RemixIcons';

const styles = {
  ...stylesModal,
  ...stylesChat,
};

interface ChatPageProps {
  onSubmit?: (state?: any) => void;
  mode?: string;
  embedded?: boolean;
  initiationText: string;
  suggestions?: ChatSuggestion[];
  disableFreeText?: boolean;
  contextQuestions?: ContextQuestion[];
  getFeelingContext?: (
    data: ContextQuestionResponse[]
  ) => FeelingContext | undefined;
  onClose?: (text?: string) => void;
}

const ChatPage: React.FC<ChatPageProps> = ({
  mode,
  embedded,
  suggestions,
  initiationText,
  disableFreeText,
  contextQuestions,
  getFeelingContext,
  onClose,
}) => {
  const { t } = useTranslation('i18n');

  const { state } = useContext(GlobalStateContext);

  const [threadId, setThreadId] = useState<string>();
  const [conversation, setConversation] = useState<ChatMessage[]>([]);
  const [message, setMessage] = useState('');
  const [isloading, setLoading] = useState(false);
  const [context, setContext] = useState<ContextQuestionResponse[]>(
    contextQuestions || []
  );

  const [remaingSuggestions, setRemaingSuggestions] = useState(
    suggestions || []
  );

  const [isFreeTextDisabled, setFreeTextDisabled] = useState(
    disableFreeText || false
  );

  const ability = useContext(AbilityContext);

  const chatComponent = useRef(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (isloading) {
      return;
    }

    callApi({
      text: message,
      conversation,
    });
    setMessage('');

    if ((chatComponent?.current as any)?.focus) {
      (chatComponent.current as any).focus();
    }
  };

  const callApi = async ({
    text,
    suggestionId,
    conversation,
    systemMessage,
    initMessage,
  }: {
    text: string;
    suggestionId?: string;
    conversation: ChatMessage[];
    systemMessage?: boolean;
    initMessage?: boolean;
  }): Promise<{ response: string; success: boolean }> => {
    if (threadId && !text) {
      return { success: true, response: '' };
    }

    const chatMessage: ChatMessage = {
      id: new Date().toISOString(),
      text,
      user: 'me',
    };

    let currentContext = [...context];
    let previousQuestion = currentContext.find((c) => !c.answerText);
    if (!initMessage && previousQuestion) {
      previousQuestion.answerText = text;
      previousQuestion.answerId = suggestionId;
    }
    setContext(currentContext);
    const nextQuestion = currentContext.find((c) => !c.answerText);

    let dialog: ChatMessage[] = [...conversation];
    if (!systemMessage) {
      dialog = [chatMessage, ...dialog];
    }

    //First call to the AI then activate default behaviours
    if (previousQuestion && !nextQuestion) {
      setRemaingSuggestions(suggestions || []);
      setFreeTextDisabled(disableFreeText || false);
    }

    setConversation(dialog);
    setLoading(true);
    let result = {
      success: false,
      response: '',
    };

    try {
      let responseTask: Promise<ChatResponse>;
      //Follow internal instructions
      if (nextQuestion) {
        responseTask = Promise.resolve({
          id: '',
          text: nextQuestion.question,
        });
        setFreeTextDisabled(!nextQuestion.allowFreeText);
        setRemaingSuggestions(nextQuestion.suggestions);

        await sleep(1000);
      }
      //Call API
      else {
        setRemaingSuggestions(suggestions || []);
        setFreeTextDisabled(disableFreeText || false);

        if (threadId) {
          responseTask = api.sendMessageToChatThread(threadId, text);
        } else {
          responseTask = api.createChatThread(
            mode || 'help-center',
            initiationText || text,
            state?.profile?.firstName,
            getFeelingContext ? getFeelingContext(context) : undefined
          );
        }

        if (!systemMessage) {
          await sleep(1500);
        }
      }

      setConversation([
        {
          id: new Date().toISOString(),
          text: t(`pages.chat.loading`),
          isWriting: true,
          user: 'assistant',
        },
        ...dialog,
      ]);

      const response = await responseTask;
      setThreadId(response.id);
      dialog = [
        {
          id: new Date().toISOString(),
          text: response.text,
          user: 'assistant',
        },
        ...dialog,
      ];
      setConversation(dialog);
      result = {
        success: true,
        response: response.text,
      };
    } catch (error) {
      captureException(error);
      toast.error(t('common.error'));
      setConversation(conversation);
      setMessage(text);
    }
    setLoading(false);
    return result;
  };

  useEffect(() => {
    if (initiationText) {
      callApi({ text: initiationText, conversation, initMessage: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiationText]);

  const handleSuggestionClicked = async (suggestion: ChatSuggestion) => {
    if (suggestion.closeDialogAfter) {
      const response = await callApi({
        text: t('pages.chat.closeMessage'),
        suggestionId: suggestion.id,
        conversation,
        systemMessage: true,
      });
      if (response.success && suggestion.closeDialogAfter && onClose) {
        onClose(response.response);
      }
      return;
    }

    const response = await callApi({
      text: suggestion.text,
      conversation,
      suggestionId: suggestion.id,
    });
    if (response && suggestion.onlyOnce) {
      setRemaingSuggestions(
        remaingSuggestions.filter((s) => s.id !== suggestion.id)
      );
    }
  };

  return ability.cannot('post', subject('chat-thread', {})) ? (
    <Redirect to={getDefaultPage()} />
  ) : (
    <div className='container'>
      <PageTitle
        rendertitle={() => (
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              <img className={styles.profileImg} src={al} alt='AL' />
              {t('pages.chat.title')}
            </div>
            {!!onClose && (
              <RoundedButton
                className={styles.closeButton}
                onClick={() => onClose()}
              >
                <CloseLineIcon />
              </RoundedButton>
            )}
          </div>
        )}
      />
      <div
        className={`${styles.row} ${
          isFreeTextDisabled
            ? styles.bodyNoText
            : embedded
            ? styles.bodyEmbedded
            : styles.body
        } ${styles.rowScrollY}`}
      >
        <ChatDialog
          disabled={isloading}
          messages={conversation}
          suggestions={{
            onClick: handleSuggestionClicked,
            values: remaingSuggestions,
          }}
        />
      </div>
      {!isFreeTextDisabled && (
        <div
          className={`${styles.row} ${
            embedded ? styles.footerEmbedded : styles.footer
          }`}
        >
          <Form onSubmit={handleSubmit}>
            <InputGroup className={styles.textContainer}>
              <Form.Control
                autoFocus
                ref={chatComponent}
                type='text'
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
              <Button>
                <ChatSendIcon onClick={handleSubmit} />
              </Button>
            </InputGroup>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ChatPage;
