import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { FeelingContext } from '../../@types/ai-api';
import styles from './FeelingAIModal.module.scss';
import ChatPage from '../../components/Chat/ChatPage';
import {
  ChatSuggestion,
  ContextQuestion,
  ContextQuestionResponse,
} from '../../components/Chat/ChatDialog';
import { Feeling } from '../../@types/seen-apps';
import { useTranslation } from 'react-i18next';
type Props = {
  show: boolean;
  mode?: string;
  initiationText: string;
  suggestions?: ChatSuggestion[];
  contextQuestions?: ContextQuestion[];
  disableFreeText?: boolean;
  onClose: (text?: string) => void;
  feeling?: Feeling;
};

const FeelingAIModal: FC<Props> = ({
  show,
  onClose,
  mode,
  initiationText,
  feeling,
  suggestions,
  disableFreeText,
  contextQuestions,
}) => {
  const { t } = useTranslation('i18n');
  const handleClose = (text?: string) => {
    onClose(text);
  };

  const getFeelingContext = (
    data: ContextQuestionResponse[]
  ): FeelingContext | undefined => {
    if (!feeling) {
      return undefined;
    }

    const context: FeelingContext = data.reduce(
      (acc, response) => {
        if (!response.answerText) {
          return context;
        }
        return {
          ...acc,
          [`${response.contextId}`]: response.answerText,
          [`${response.contextId}Id`]: response.answerId,
        } as FeelingContext;
      },
      {
        color: feeling.color as any,
        energy: feeling.energy,
        wordId: feeling.color,
        id: feeling.id,
        word: t(`words.${feeling.wordId}`),
        value: feeling.value,
      } as FeelingContext
    );
    return context;
  };

  return (
    <Modal className={styles.root} show={show} onHide={handleClose}>
      <ChatPage
        onClose={handleClose}
        embedded
        mode={mode}
        initiationText={initiationText}
        getFeelingContext={getFeelingContext}
        suggestions={suggestions}
        disableFreeText={disableFreeText}
        contextQuestions={contextQuestions}
      />
    </Modal>
  );
};

export default FeelingAIModal;
