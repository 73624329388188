import { isNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { HistoMeasureType } from '../../../@types/form-api';
import Histogram from '../../histogram/Histogram';
import { getLegend } from '../AnswerComponent';
import styles from './HistoAnswerComponent.module.scss';

interface HistoAnswerComponentProps {
  id: string;
  measureType: HistoMeasureType;
  value?: number | any;
  onChange?: (value?: any) => void;
  disabled?: boolean;
  printMode?: boolean;
}

const HistoAnswerComponent: React.FC<HistoAnswerComponentProps> = ({
  id,
  measureType,
  value,
  onChange,
  disabled,
}) => {
  const [lowLegend, setLowLegend] = useState<any>();
  const [highLegend, setHighLegend] = useState<any>();
  const [indexSelected, setIndexSeleted] = useState<number>(-1);

  useEffect(() => {
    let indexSelected: number;
    const numberValue = isNumber(value) ? value : Number.parseFloat(`${value}`);
    if (isNaN(numberValue) || value === undefined || value === null) {
      indexSelected = -1;
    } else {
      indexSelected = 0;
      // check step by step the right indexSelected
      while (indexSelected < 10) {
        const stepValue =
          indexSelected * measureType.histoOptions.stepValue +
          measureType.histoOptions.firstValue;
        if (stepValue.toFixed(2) === numberValue.toFixed(2)) {
          break;
        }
        indexSelected++;
      }
    }
    setIndexSeleted(indexSelected);
  }, [id, measureType.histoOptions, value]);

  useEffect(() => {
    setLowLegend(getLegend(measureType.histoOptions.legend.low));
    setHighLegend(getLegend(measureType.histoOptions.legend.high));
  }, [measureType.histoOptions]);

  const handleValueChange = (indexSelected: any) => {
    if (onChange) {
      let value = measureType.histoOptions.firstValue;
      let inc = 0;
      while (inc !== indexSelected) {
        value += measureType.histoOptions.stepValue;
        inc++;
      }

      onChange(value.toFixed(2));
    }
  };

  return (
    <div className={styles.histo}>
      <Histogram
        disabled={disabled}
        colorScheme={measureType.histoOptions.color || 'green'}
        legendHighComp={highLegend}
        legendLowComp={lowLegend}
        selectedIndex={indexSelected}
        onSelectedIndexChange={handleValueChange}
      />
    </div>
  );
};

export default HistoAnswerComponent;
