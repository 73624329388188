import React, { FC, useContext, useEffect, useState } from 'react';
import linkStyles from './ShowUrlAliasPage.module.scss';
import modalStyles from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';

import { useTranslation } from 'react-i18next';

import { Button, Col, Container, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { ToastOnClose } from '../../components/ModalWrapper/ModalSlideShowWrapper';
import QrcodeLink from '../../components/qrcodeLink/QrcodeLink';
import PageTitle from '../../components/PageTitle/PageTitle';
import { UrlAlias } from '../../@types/shorten-url-api';
import ReactLoading from 'react-loading';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import api from '../../api/Api';
import { captureException } from '@sentry/react';
import { getErrorTranslation } from '../../tools/errorTools';
import { toast } from 'react-toastify';

const styles = { ...modalStyles, ...linkStyles };
const dicoPrefix = 'pages.sphere.urlAliases';

type Props = {
  urlAlias?: any;
  linkId?: string;
  onSubmit?: (stateProps: any) => void;
  variants?: any;
  onCloseNotifications?: ToastOnClose[];
  disableThinkingCreation?: boolean;
};

const ShowUrlAliasPage: FC<Props> = ({
  onSubmit = () => {},
  variants,
  linkId,
  urlAlias,
}) => {
  const { t } = useTranslation('i18n');

  const {
    state: { urlAliases },
  } = useContext(GlobalStateContext);

  const [alias, setAlias] = useState<UrlAlias | undefined>(urlAlias);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (urlAlias) {
      return;
    }
    const alias = urlAliases?.find((u) => u.id === linkId);
    setAlias(alias);
    if (!alias && linkId) {
      setLoading(true);
      api
        .getUrlAlias(linkId)
        .then((alias) => {
          setAlias(alias);
          setLoading(false);
        })
        .catch((error) => {
          captureException(error);
          toast.error(
            getErrorTranslation({
              error,
              t,
              defaultKey: `common.error`,
            })
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.div
      variants={variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={styles.page}
    >
      <div className={styles.page}>
        <PageTitle title={alias?.name?.default || ''} />
        <div className={styles.content}>
          <Container className={styles.root}>
            <Row className={styles.noMargin}>
              <Col sm={6} className={styles.noMargin}>
                {loading && (
                  <div className={styles.loading}>
                    <ReactLoading
                      type={'bars'}
                      color={'#DDD'}
                      height={'30px'}
                      width={'40px'}
                    />
                    {t('common.loading')}
                  </div>
                )}
                {alias && (
                  <QrcodeLink
                    link={alias.redirectUrl}
                    subTitleBottom={alias.id}
                    subTitleTop={alias.name?.default}
                    linkTitle={t(`${dicoPrefix}.section.show.link`)}
                    qrCodeTitle={t(`${dicoPrefix}.section.show.qrCode`)}
                  />
                )}
              </Col>
            </Row>
            <Row className={styles.noMargin}>
              <Col sm={6} className={styles.right}>
                <Button onClick={onSubmit}>{t('common.action.close')}</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </motion.div>
  );
};

export default ShowUrlAliasPage;
