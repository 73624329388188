import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AddLineIcon, SubElementIcon } from '../RemixIcons';
import selectStyles from '../../styles/react-select.module.scss';
import styles from './SelectInput.module.scss';
import AsyncSelect from 'react-select/async';
import { fetchTags } from '../../pages/session/SessionAdminPage';

import AddOrUpdateTagForm from '../../pages/sphere/Tags/AddOrUpdateTagForm';
import { getRelevantTranslationFor } from '../../tools/multiLingualTools';
import { Tag } from '../../@types/sphere-api';
import AbilityContext from '../../context/AbilityContext';
import { useAbility } from '@casl/react';
import { subject } from '@casl/ability';
import ModuleIcon from '../../pages/sphere/Tags/ModuleIcon';

import { components, ControlProps } from 'react-select';

export type Item = { label: string; value: string };

type Props = {
  sphereId: string;
  disableAddTag?: boolean;
  module?: string;
  isDisabled?: boolean;
  defaultValueTagWhenCreation?: Partial<Tag>;
} & (
  | {
      tagsSelected: Item[];
      onChange: (items: Item[]) => void;
      enableNotExist?: false;
    }
  | {
      tagsSelected: Item[] | null;
      onChange: (items: Item[] | null) => void;
      enableNotExist: true;
    }
);

const TagsInput: React.FC<Props> = ({
  tagsSelected,
  sphereId,
  onChange,
  disableAddTag = false,
  module,
  isDisabled,
  defaultValueTagWhenCreation,
  enableNotExist,
}) => {
  const { t } = useTranslation('i18n');

  const ability = useAbility(AbilityContext);

  const [couldAddTag, setCouldAddTag] = useState<boolean>(false);

  useEffect(() => {
    setCouldAddTag(
      ability.can('post', subject('sphere-tag', { sphereId })) || false
    );
  }, [ability, sphereId]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleAddTag = () => {
    setShow(true);
  };

  const handleInputTagChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    return inputValue;
  };
  const handleTagSelected = (items: any) => {
    onChange(items);
  };
  const handleDoNotExitsChanged = (value: boolean) => {
    if (enableNotExist) {
      onChange(value ? null : []);
    }
  };

  const loadTagsOptions = (
    search: string,
    callback: (data: { label: string; value: string }[]) => void
  ) => {
    fetchTags({
      sphereId,
      search,
      filters: module ? [`modules=${module}`] : undefined,
    }).then((tags) => {
      callback(tags);
    });
  };
  const handleSaved = (tag: Tag) => {
    handleTagSelected([
      ...(tagsSelected || []),
      { value: tag.id, label: getRelevantTranslationFor(tag.name) },
    ]);
    handleClose();
  };

  const Control = ({ children, ...props }: ControlProps<any, false>) => {
    return (
      <components.Control {...props}>
        {module && (
          <div className={styles.module}>
            <ModuleIcon module={module} />
          </div>
        )}
        {children}
      </components.Control>
    );
  };

  return (
    <>
      <div className={styles.global}>
        <InputGroup className={`mb-3 ${styles.root}`}>
          <AsyncSelect
            isDisabled={isDisabled}
            className={`${selectStyles.reactSelect} ${styles.input}`}
            classNamePrefix='custom-react-select'
            loadOptions={loadTagsOptions}
            defaultOptions
            isMulti
            components={{ Control }}
            onInputChange={handleInputTagChange}
            onChange={handleTagSelected}
            placeholder={t(`pages.sphere.sessions.tags`)}
            autoFocus
            value={tagsSelected || []}
            required
            noOptionsMessage={() => t('common.noValue')}
          />
          {couldAddTag && !disableAddTag && (
            <Button variant='outline-primary' onClick={handleAddTag}>
              <AddLineIcon />
            </Button>
          )}
        </InputGroup>
        {enableNotExist && (
          <Form.Group controlId='tagDoNotExist' className={styles.subComponent}>
            <SubElementIcon />
            <Form.Check
              type='switch'
              disabled={isDisabled}
              label={<>{t(`common.filters.isEmpty`)}</>}
              checked={tagsSelected === null}
              onChange={() => handleDoNotExitsChanged(tagsSelected !== null)}
            />
          </Form.Group>
        )}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        style={{
          zIndex: 999999,
        }}
      >
        <Modal.Header>{t(`pages.sphere.tags.section.add.title`)}</Modal.Header>
        <Modal.Body
          style={{
            position: 'relative',
            display: 'table',
            overflowY: 'auto',
            overflowX: 'auto',
            width: 'auto',
            minWidth: '300px',
          }}
        >
          <AddOrUpdateTagForm
            defaultValue={defaultValueTagWhenCreation}
            sphereId={sphereId}
            onClose={handleClose}
            onSaved={handleSaved}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TagsInput;
