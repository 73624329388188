import { useTranslation } from "react-i18next";
import Select, { FormatOptionLabelMeta } from "react-select";
import { GOALS_AND_SUB } from "../../../tools/goalTools";
import { useContext, useEffect, useState } from "react";

import selectStyles from "../../../styles/react-select.module.scss";
import styles from "./GoalForm.module.scss";
import api from "../../../api/Api";
import moment from "moment-timezone";
import { Alert, Badge } from "react-bootstrap";
import { confirmWrapper } from "../../../tools/confirm";
import MarkdownComponent from "../../Markdown/MarkdownComponent";
import { useLocation } from "react-router-dom";
import {
  getGuestRegistrationRoute,
  getSubscriptionRoute,
} from "../../../tools/routeTools";
import MyselfAddFormModal from "../../../pages/feeling/MyselfAddFormModal";
import { FormSurvey } from "../../../@types/form-api";
import GlobalStateContext from "../../../context/globalState/GlobalStateContext";
import { SET_GOAL_RANKINGS } from "../../../context/globalState/globalStateReducer";

export type Option = {
  label: string;
  value: string;
  ranking: number;
};

type GoalSelectProps = {
  className?: string;
  featureAvailable: boolean;
  disabled?: boolean;
  goalsExluded?: string[];
  onSelect: (id: string, ranking: number) => void;
};

const GoalSelect = ({
  disabled,
  onSelect: onChange,
  goalsExluded,
  featureAvailable,
  className,
}: GoalSelectProps) => {
  const { t } = useTranslation("i18n");

  const {
    state: { goalRankings, profile },
    dispatch,
  } = useContext(GlobalStateContext);

  const location = useLocation();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [formGoalVisible, setFormGoalVisible] = useState(false);
  const [goalCompleted, setGoalCompleted] = useState(true);

  const [emptyOption] = useState<Option>({
    value: "",
    label: "",
    ranking: 0,
  });

  useEffect(() => {
    const loadGoals = async () => {
      const [survey] = await api.getFormSurveys(
        moment().startOf("year").subtract(3, "year").toDate(),
        moment().endOf("year").toDate(),
        {
          formId: "AQ8579T6LZR",
          max: 1,
        }
      );
      dispatch({
        type: SET_GOAL_RANKINGS,
        payload: survey?.ranking || {},
      });
    };

    if (featureAvailable && !goalRankings) {
      loadGoals();
    } else {
      setInitialized(true);
    }
  }, [dispatch, featureAvailable, goalRankings]);

  useEffect(() => {
    setGoalCompleted(!!Object.keys(goalRankings || {})?.length);
  }, [goalRankings]);

  useEffect(() => {
    if (!goalRankings) {
      return;
    }
    const options = GOALS_AND_SUB.filter(
      (g) => !goalsExluded?.some((e) => e === g.id)
    ).map((goal) => {
      return {
        value: goal.id,
        label: t(`report.goalForm.axes.${goal.id}.label`),
        ranking: goalRankings[`goal_${goal.id}`] || 20,
      };
    });

    options.sort((a, b) => a.ranking - b.ranking);
    setOptions(options);
    setInitialized(true);
  }, [goalRankings, goalsExluded, t]);

  const formatOptionLabel = (
    option: Option,
    _labelMeta: FormatOptionLabelMeta<Option, false>
  ): React.ReactNode => {
    let label: string;
    if (option.value) {
      label = option.label;
    } else {
      label = goalsExluded?.length
        ? t("pages.feeling.goal.noValueOther")
        : t("pages.feeling.goal.noValue");
    }

    return (
      <div className={styles.item}>
        <span className={styles.title}>{label} </span>
        {!!option.ranking && option.ranking <= 5 && (
          <Badge variant='secondary' className={styles.ranking}>
            {option.ranking}
          </Badge>
        )}
      </div>
    );
  };

  const handleMenuClicked = async () => {
    if (featureAvailable) {
      return;
    }

    if (profile?.isGuest) {
      if (
        !(await confirmWrapper(
          <MarkdownComponent
            text={t(`pages.user.subscription.guest.reserved.message`)}
          />,
          {
            btnPrimaryLabel: t(
              `pages.user.subscription.guest.reserved.confirm`
            ),
            btnSecondaryLabel: t(`common.action.close`),
            title: t(`pages.user.subscription.guest.reserved.title`),
          }
        ))
      ) {
        return;
      }
      document.location.href = getGuestRegistrationRoute({
        callerUrl: location.pathname,
      });
      return;
    }

    if (
      !(await confirmWrapper(
        <MarkdownComponent
          text={t(`pages.user.subscription.essentials.reserved.message`)}
        />,
        {
          btnPrimaryLabel: t(
            `pages.user.subscription.essentials.reserved.confirm`
          ),
          btnSecondaryLabel: t(`common.action.close`),
          title: t(`pages.user.subscription.essentials.reserved.title`),
        }
      ))
    ) {
      return;
    }
    window.open(
      getSubscriptionRoute({
        callerUrl: location.pathname,
      }),
      "_blank"
    );
  };

  const handleOnChange = (selected: Option) => {
    if (selected && selected.value) {
      onChange(selected.value, selected.ranking);
    }
  };

  const handleFormClose = (survey?: FormSurvey) => {
    if (survey) {
      dispatch({
        type: SET_GOAL_RANKINGS,
        payload: survey.ranking,
      });
    }
    setFormGoalVisible(false);
  };

  return featureAvailable && !goalCompleted ? (
    <div className={styles.form}>
      <Alert
        className={styles.formLink}
        variant='info'
        onClick={() => setFormGoalVisible(true)}
      >
        {t(`report.goalForm.notCompleted`)}
      </Alert>
      {formGoalVisible && (
        <MyselfAddFormModal
          formId='AQ8579T6LZR'
          show={formGoalVisible}
          onClose={handleFormClose}
        />
      )}
    </div>
  ) : (
    <Select
      key={`goal-selection-${options.length}`}
      onMenuOpen={handleMenuClicked}
      options={options}
      isDisabled={disabled || !initialized}
      isLoading={!initialized}
      className={`${selectStyles.reactSelect} ${className || ""}`}
      classNamePrefix='custom-react-select'
      formatOptionLabel={formatOptionLabel}
      onChange={handleOnChange as any}
      isSearchable={false}
      value={emptyOption}
    />
  );
};

export default GoalSelect;
