/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import stylesModal from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import stylesSpheres from './SessionNotAllowed.module.scss';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { Forbid2LineIcon } from '../../components/RemixIcons';
import PageTitle from '../../components/PageTitle/PageTitle';

const styles = { ...stylesModal, ...stylesSpheres };

interface SessionNotAllowedProps {
  match: any;
  location: any;
}

const SessionNotAllowed: React.FC<SessionNotAllowedProps> = () => {
  const { t } = useTranslation('i18n');

  return (
    <div className={styles.page}>
      <PageTitle title={t(`pages.sphere.sessions.notAllowed.title`)} />
      <div className={styles.content}>
        <Container className={styles.root}>
          <Row>
            <Col sm={12}>
              <h3 className={styles.description}>
                <Forbid2LineIcon className={styles.icon} />
                {t(`pages.sphere.sessions.notAllowed.desc`)}
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withRouter(SessionNotAllowed);
