import { useContext, useEffect, useState } from 'react';
// import selectStyles from './SelectInput.module.scss';
import styles from './MultiSelectFacet.module.scss';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { Badge, Form } from 'react-bootstrap';
import { SET_FILTERS } from '../../context/globalState/globalStateReducer';
import { Filters, FacetValue } from '../../@types/seen-apps';
import Select from 'react-select';
import selectStyles from '../../styles/react-select.module.scss';

export type OptionType = {
  value: string;
  label: string;
  count: number;
};

type MultiSelectFacetProps = {
  label: string;
  facetName: string;
  isLoading?: boolean;
  convertFacetsToOptions: (facets: FacetValue[]) => Promise<OptionType[]>;
  updateFilters: (filters: Filters, options: OptionType[]) => Filters;
  optionsSelected: (filters: Filters, options: OptionType[]) => OptionType[];

  placeholder?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  displayCount?: boolean;
};

const MultiSelectFacet = ({
  label,
  facetName,
  isLoading,
  convertFacetsToOptions,
  updateFilters,
  optionsSelected,
  displayCount,
  ...selectOptions
}: MultiSelectFacetProps) => {
  const {
    state: { filters, feelingFacets },
    dispatch,
  } = useContext(GlobalStateContext);

  const [itemsSelected, setItemsSelected] = useState<OptionType[]>([]);
  const [options, setOptions] = useState<OptionType[]>([]);

  useEffect(() => {
    const facets = feelingFacets[facetName];
    convertFacetsToOptions(facets || []).then((options) => {
      setOptions(options);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feelingFacets, facetName, isLoading]);

  useEffect(() => {
    const selected = optionsSelected(filters, options);
    setItemsSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, options]);

  const onChanged = async (valueSelected: any) => {
    const updates = updateFilters(
      filters,
      Array.isArray(valueSelected)
        ? valueSelected
        : valueSelected !== undefined && valueSelected !== null
        ? [valueSelected]
        : []
    );
    dispatch({
      type: SET_FILTERS,
      payload: updates,
    });
  };

  return (
    <Form.Group controlId='code'>
      <Form.Label>{label}</Form.Label>
      <div className={styles.select}>
        <div className={selectStyles.reactSelect}>
          <Select
            isLoading={isLoading}
            key={`filter_${facetName}`}
            name={facetName}
            options={options}
            className={selectStyles.reactSelect}
            classNamePrefix='custom-react-select'
            onChange={onChanged}
            value={itemsSelected}
            formatOptionLabel={(item, labelMeta) => {
              if (labelMeta.context === 'menu') {
                return (
                  <div className={styles.item}>
                    <span>{item.label}</span>
                    {displayCount && (
                      <div className={styles.count}>
                        <Badge variant='dark'>{item.count}</Badge>
                      </div>
                    )}
                  </div>
                );
              }
              return <span>{item.label}</span>;
            }}
            {...selectOptions}
          />
        </div>
      </div>
    </Form.Group>
  );
};

export default MultiSelectFacet;
