import { isString } from 'lodash';
import { MultiLingual } from '../@types/sphere-api';
import { getCurrentLanguage } from '../i18n';

export const getRelevantTranslationFor = (
  multiligual: MultiLingual | undefined | string | null
): string => {
  if (!multiligual) {
    return '';
  }
  if (isString(multiligual)) {
    return multiligual;
  }
  if (typeof multiligual === 'string') {
    return multiligual;
  }
  if (Array.isArray(multiligual)) {
    return multiligual.join(', ');
  }

  const lang = getCurrentLanguage();
  const translate = multiligual[lang] || multiligual.default;

  if (translate) {
    return translate;
  }

  return Object.keys(multiligual).reduce((acc, lang) => {
    if (acc) {
      return acc;
    }
    return multiligual[lang];
  }, '');
};

export const clearEmptyLanguage = (value?: MultiLingual): MultiLingual => {
  if (!value) {
    return {};
  }

  const response = Object.keys(value).reduce((acc, lang) => {
    const data = value[lang];
    if (!data) {
      return acc;
    }
    return { ...acc, [lang]: data };
  }, {} as MultiLingual);

  return response;
};
