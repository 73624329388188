import { useContext, useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './StrategyGoals.module.scss';

import { Strategy } from '../../@types/strategy-api';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { useTranslation } from 'react-i18next';

interface Props extends RouteComponentProps {
  className?: string;
  strategy: Strategy;
}

const StrategyGoals = ({ strategy, className }: Props) => {
  const {
    state: { goalRankings },
  } = useContext(GlobalStateContext);
  const { t } = useTranslation('i18n');

  const [goals, setGoals] = useState<
    { id: string; label: string; ranking?: number }[]
  >([]);

  useEffect(() => {
    const goals = strategy.goalsLinked?.map((goalId) => {
      return {
        id: goalId,
        label: t(`report.goalForm.axes.${goalId}.label`),
        ranking: goalRankings?.[`goal_${goalId}`],
      };
    });
    setGoals(goals || []);
  }, [goalRankings, strategy.goalsLinked, t]);

  return (
    <div className={`${styles.root} ${className || ''}`}>
      {goals.map((goal) => (
        <div key={`goal-${goal.id}`} className={styles.goal}>
          <div className={styles.goalTitle}>{goal.label}</div>
          {goal.ranking && (
            <div className={styles.goalRanking}>{goal.ranking}</div>
          )}
        </div>
      ))}
    </div>
  );
};
export default withRouter(StrategyGoals);
