import React from 'react';
import styles from './QrScanner.module.scss';
const QrReader = require('react-qr-reader');

interface QrScannerProps {
  onScan: (data: string) => void;
}

const QrScanner: React.FC<QrScannerProps> = ({ onScan }) => {
  const handleScan = (data: string | null) => {
    if (data) {
      onScan(data);
    }
  };
  const handleError = (err: Error) => {
    console.log(err);
  };

  return (
    <div className={styles.container}>
      <div className={styles.scan} />
      <QrReader
        constraints={{
          focusMode: 'continuous',
          facingMode: { ideal: 'environment' },
          frameRate: { ideal: 25, min: 10 },
        }}
        resolution={600}
        className={styles.scanner}
        delay={300}
        onError={handleError}
        onScan={handleScan}
        showViewFinder={false}
      />
    </div>
  );
};

export default QrScanner;
