import { useEffect, useState } from 'react';
import styles from './CampaignNameView.module.scss';
import { CampaignResponse } from '../../@types/shorten-url-api';
import { getRelevantTranslationFor } from '../../tools/multiLingualTools';
import { CampaignIcon } from '../RemixIcons';

interface Props {
  campaign?: CampaignResponse | { id: string; name: string };
}

const CampaignNameView = ({ campaign }: Props) => {
  const [label, setLabel] = useState<string>();
  const [iconUrl, setIconUrl] = useState<string>();

  useEffect(() => {
    setIconUrl((campaign as any)?.campaign?.iconUrl || undefined);
  }, [campaign]);

  useEffect(() => {
    setLabel(getRelevantTranslationFor(campaign?.name));
  }, [campaign]);

  return !campaign ? (
    <div />
  ) : (
    <div className={styles.title}>
      {iconUrl ? (
        <img alt='logo' src={iconUrl} className={styles.logo} />
      ) : (
        <CampaignIcon className={styles.logo} />
      )}
      <div>{label}</div>
    </div>
  );
};

export default CampaignNameView;
