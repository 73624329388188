/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GuestRegistrationPage.module.scss';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { Container, Row, Col, Form, Alert, Button } from 'react-bootstrap';
import HorizontalRule from '../../components/horizontalRule/HorizontalRule';
import PageTitle from '../../components/PageTitle/PageTitle';
import api, { extractErrorFromApiResponse } from '../../api/Api';
import { toast } from 'react-toastify';
import { Email } from '../../@types/webapp-api';
import { confirmWrapper } from '../../tools/confirm';
import { captureException } from '@sentry/minimal';
import { getErrorTranslation } from '../../tools/errorTools';
import CodeValidationInput from '../../components/Auth/CodeValidationInput';
import MarkdownComponent from '../../components/Markdown/MarkdownComponent';
import { getCurrentLanguage } from '../../i18n';

const dicoPrefix = 'pages.guestRegistration';

interface GuestRegistrationPageProps {
  match?: any;
  location?: any;
  onSubmit?: (state?: any) => void;
  onCancel?: (state?: any) => void;
  feature?: 'guest' | 'newAccount';
}

const GuestRegistrationPage: React.FC<GuestRegistrationPageProps> = ({
  onSubmit = () => {},
  feature,
  onCancel,
}) => {
  const { t } = useTranslation('i18n');
  const globalContext = useContext(GlobalStateContext);
  const { state, dispatch } = globalContext;

  const [saving, setSaving] = useState(false);

  const [email, setEmail] = useState('');
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [code, setCode] = useState('');

  const [firstName, setFirstName] = useState(
    globalContext.state.profile?.firstName === 'Guest'
      ? ''
      : globalContext.state.profile?.firstName
  );

  const [emailError, setEmailError] = useState<string>('');
  const [primaryEmail, setPrimaryEmail] = useState<Email | undefined>();
  const [step, setStep] = useState<'email' | 'profile' | 'finalized'>('email');

  useEffect(() => {
    if (!state.profile) {
      return;
    }
    if (!state.profile || !state.profile.isGuest) {
      setStep('finalized');
      return;
    }
    if (!state.profile.guestConvertionStep) {
      setStep('email');
      return;
    }
    const primaryEmail = state.emails.find((e) => e.primary);
    setPrimaryEmail(primaryEmail);
    setEmail(primaryEmail?.email || '');
    if (
      state.profile.guestConvertionStep === 'email' &&
      !primaryEmail?.isVerified
    ) {
      setStep('profile');
      return;
    }

    setStep(state.profile.guestConvertionStep || 'finalized');
  }, [state]);

  const handleSaveProfile = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (saving) {
      return;
    }

    setSaving(true);
    try {
      await api.finalizeGuestRegistration({
        firstName: firstName!,
        validationCode: code,
        acceptPrivatePolicy: acceptPrivacy,
      });
      setStep('finalized');
      //Trigger other apps (websocket)
      await api.saveUserProfile({
        firstName: firstName!,
      });
    } catch (error) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          error,
          t,
          defaultKey: `${dicoPrefix}.error`,
        })
      );
    }
    setSaving(false);
  };

  const handleSaveEmail = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (saving) {
      return;
    }
    setSaving(true);
    try {
      await api.registerGuest({ email });
      //Trigger other apps (websocket)
      await api.saveUserProfile({
        firstName: globalContext.state.profile?.firstName,
      });
      setStep('profile');
      toast.success(t(`${dicoPrefix}.step1.success`));
    } catch (error) {
      captureException(error);
      const err = extractErrorFromApiResponse(error);
      if (
        err.data &&
        Array.isArray(err.data) &&
        err.data.find((item) => item.key === 'email-already-used')
      ) {
        setEmailError(t('pages.emails.alreadyUsed'));
      } else {
        toast.error(
          getErrorTranslation({
            defaultKey: t(`${dicoPrefix}.error`),
            t,
            error,
          })
        );
      }
    }
    setSaving(false);
  };

  const handleSendValidation = async () => {
    if (
      primaryEmail &&
      (await confirmWrapper(
        t('pages.emails.sendEmailConfirmation', {
          email: email,
        }),
        {
          btnPrimaryLabel: t('pages.emails.sendEmailAction'),
          title: t('pages.emails.sendEmail'),
        }
      ))
    ) {
      try {
        await api.sendEmailValidation(primaryEmail);
        toast.success(t('pages.emails.validationSent.success'));
      } catch (error) {
        captureException(error);
        toast.error(t('pages.emails.validationSent.error', { error }));
      }
    }
  };

  const isStepEmailActive = step === 'email' || step === 'profile';
  const isStepValidationActive = step === 'profile';
  const isStepProfileActive = step === 'profile';

  return step == 'finalized' ? (
    <div className={styles.page}>
      <PageTitle title={t(`${dicoPrefix}.title`)} />
      <div className={styles.content}>
        <Container>
          <Row className={styles.noMargin}>
            <Col sm={12} className={styles.noMargin}>
              <Alert variant='info'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(`${dicoPrefix}.finalized`),
                  }}
                />
              </Alert>
            </Col>
          </Row>
          <Row className={styles.noMargin}>
            <Col sm={12} className={styles.noMargin}>
              <div className={styles.center}>
                <Button variant='secondary' onClick={() => onSubmit()}>
                  {t('common.action.close')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  ) : (
    <div className={styles.page}>
      <PageTitle title={t(`${dicoPrefix}.title`)} />
      <div className={styles.content}>
        <Container>
          {feature !== 'newAccount' && (
            <Row className={styles.noMargin}>
              <Col sm={12} className={styles.noMargin}>
                <Alert variant='info'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t(`${dicoPrefix}.description`),
                    }}
                  />
                </Alert>
              </Col>
            </Row>
          )}
          <div
            className={`${styles.step} ${
              isStepEmailActive ? styles.active : styles.disabled
            }`}
          >
            <Row className={styles.noMargin}>
              <Col sm={12} className={styles.noMargin}>
                <HorizontalRule
                  text={t(`${dicoPrefix}.step1.title`)}
                  disabled={!isStepEmailActive}
                />
              </Col>
            </Row>
            <Form onSubmit={handleSaveEmail}>
              <Row className={styles.noMargin}>
                <Col sm={6} className={styles.noMargin}>
                  <Form.Group controlId='email'>
                    <Form.Label>{t(`${dicoPrefix}.step1.email`)}</Form.Label>
                    <Form.Control
                      required
                      disabled={saving || !isStepEmailActive}
                      type='email'
                      value={email}
                      onChange={(event) => {
                        setEmailError('');
                        setEmail(event.target.value);
                      }}
                      isInvalid={!!emailError}
                      autoFocus
                      isValid={
                        !emailError && primaryEmail && primaryEmail.isVerified
                      }
                    />
                    <Form.Control.Feedback type='invalid'>
                      {emailError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {step === 'email' && (
                <Row className={styles.noMargin}>
                  <Col sm={6} className={styles.noMargin}>
                    <div className={styles.buttons}>
                      <div className={styles.saveButton}>
                        <Button
                          type='submit'
                          variant='primary'
                          disabled={saving}
                        >
                          {t(`${dicoPrefix}.step1.save`)}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {step === 'profile' && (
                <Row className={styles.noMargin}>
                  <Col sm={6} className={styles.noMargin}>
                    <div className={styles.buttons}>
                      <div className={styles.saveButton}>
                        <Button
                          type='submit'
                          variant='primary'
                          disabled={saving || email === primaryEmail?.email}
                        >
                          {t(`${dicoPrefix}.step1.update`)}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </div>

          {step === 'profile' && (
            <div
              className={`${styles.step} ${
                isStepValidationActive ? styles.active : styles.disabled
              }`}
            >
              <Row className={styles.noMargin}>
                <Col sm={12} className={styles.noMargin}>
                  <hr />
                  <HorizontalRule
                    text={t(`${dicoPrefix}.step2.title`)}
                    disabled={!isStepValidationActive}
                  />
                </Col>
              </Row>

              {primaryEmail?.isVerified ? (
                <Row className={styles.noMargin}>
                  <Col sm={6} className={styles.noMargin}>
                    <Form.Control
                      required
                      disabled={true}
                      value={`${t(`${dicoPrefix}.step2.success`)}`}
                      isValid={primaryEmail?.isVerified}
                    />
                  </Col>
                </Row>
              ) : (
                <>
                  <Row className={styles.noMargin}>
                    <Col sm={6} className={styles.noMargin}>
                      <div className={styles.buttons}>
                        <div className={styles.saveButton}>
                          <Button
                            variant='primary'
                            onClick={handleSendValidation}
                            disabled={saving || !isStepValidationActive}
                          >
                            {t(`${dicoPrefix}.step2.resend`)}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className={styles.noMargin}>
                    <Col sm={6} className={styles.noMargin}>
                      <Form.Group controlId='code'>
                        <Form.Label>{t(`${dicoPrefix}.code`)} *</Form.Label>
                        <CodeValidationInput
                          autoFocus
                          value={code}
                          onComplete={(code) => setCode(code)}
                          onChange={(code) => setCode(code)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
              <Form onSubmit={handleSaveProfile}>
                <Row className={styles.noMargin}>
                  <Col sm={6} className={styles.noMargin}>
                    <Form.Group controlId='firstName'>
                      <Form.Label>{t('pages.user.firstName')} *</Form.Label>
                      <Form.Control
                        required
                        disabled={saving || !isStepProfileActive}
                        type='text'
                        value={firstName}
                        onChange={(event) => {
                          setFirstName(event.target.value);
                        }}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className={styles.noMargin}>
                  <Col sm={6} className={styles.noMargin}>
                    <Form.Check
                      className={styles.checkbox}
                      id={`acceptPrivacy`}
                      type='checkbox'
                      required
                      disabled={saving || !isStepProfileActive}
                      label={
                        <MarkdownComponent
                          className={styles.privacy}
                          text={`${t(`loginflow.creation.acceptPrivacy`, {
                            locale: getCurrentLanguage(),
                          })} *`}
                        />
                      }
                      checked={acceptPrivacy}
                      onChange={(event: any) => {
                        setAcceptPrivacy(event.target.checked);
                      }}
                    />
                  </Col>
                </Row>

                <Row className={styles.noMargin}>
                  <Col sm={6} className={styles.noMargin}>
                    <div className={styles.buttons}>
                      {onCancel && (
                        <div className={styles.cancelButton}>
                          <Button
                            variant='secondary'
                            onClick={onCancel}
                            disabled={saving}
                          >
                            {t(`common.action.cancel`)}
                          </Button>
                        </div>
                      )}
                      <div className={styles.saveButton}>
                        <Button
                          type='submit'
                          variant='primary'
                          disabled={
                            saving || !isStepProfileActive || code.length < 6
                          }
                        >
                          {t(`${dicoPrefix}.step2.save`)}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};
export default GuestRegistrationPage;
