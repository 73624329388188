import { FC, useState, useEffect } from 'react';
import linkStyles from './UpdateUrlAliasPage.module.scss';
import modalStyles from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

import { captureException } from '@sentry/minimal';
import { motion } from 'framer-motion';
import { getErrorTranslation } from '../../tools/errorTools';
import { ToastOnClose } from '../../components/ModalWrapper/ModalSlideShowWrapper';
import PageTitle from '../../components/PageTitle/PageTitle';

import api from '../../api/Api';
import AddOrUpdateCampaign from './AddOrUpdateCampaign';
import LoadingPage from '../loading/LoadingPage';
import { UrlAlias } from '../../@types/shorten-url-api';

const styles = { ...modalStyles, ...linkStyles };
const dicoPrefix = 'pages.sphere.urlAliases';

type Props = {
  onClose?: () => void;
  onSubmit?: (stateProps: any) => void;
  variants?: any;
  linkId?: string;
  onCloseNotifications?: ToastOnClose[];
};

const UpdateUrlAliasPage: FC<Props> = (props: Props) => {
  const { t } = useTranslation('i18n');
  const [loading, setLoading] = useState<boolean>(false);
  const [urlAlias, setUrlAlias] = useState<UrlAlias>();

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);

      try {
        const urlAlias = props.linkId
          ? await api.getUrlAlias(props.linkId)
          : undefined;
        if (!urlAlias) {
          setLoading(false);
          props.onCloseNotifications?.push({
            content: t('common.not-found.url'),
            options: {
              type: 'error',
            },
          });
          if (props.onClose) {
            props.onClose();
          }
          return;
        }
        setUrlAlias(urlAlias);
      } catch (error) {
        captureException(error);
        toast.error(
          getErrorTranslation({
            error,
            t,
            defaultKey: `${dicoPrefix}.section.update.error`,
          })
        );
      }
      setLoading(false);
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.linkId, t]);

  return (
    <motion.div
      key={`UpdateUrlAliasPage-${urlAlias?.id}`}
      variants={props.variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={styles.page}
    >
      <div className={styles.page}>
        <PageTitle title={t(`${dicoPrefix}.section.update.title`)} />
        <div className={styles.content}>
          {loading || !urlAlias ? (
            <LoadingPage />
          ) : (
            <AddOrUpdateCampaign mode='update' urlAlias={urlAlias} {...props} />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default UpdateUrlAliasPage;
