import { Ability, subject } from '@casl/ability';
import orderBy from 'lodash.orderby';
import { FormEntitlement, LightSession } from '../@types/form-api';
import { MemberResponse } from '../@types/member-api';
import { GlobalState, SessionLight } from '../@types/seen-apps';
import { Session } from '../@types/session-api';
import { MultiLingual } from '../@types/sphere-api';
import { MySession, SessionTree } from '../@types/webapp-api';
import { getRelevantTranslationFor } from './multiLingualTools';

export const isSessionMember = (
  session: Session | SessionLight | LightSession,
  ability: Ability
) => {
  const isMember =
    session.category === 'contributor' &&
    ability.can('smartReport', subject('session', session));
  return isMember;
};

export const getSessionName = (
  session: Session | SessionLight | undefined | LightSession,
  ability: Ability
) => {
  if (!session) {
    return '';
  }
  let name: string | MultiLingual;
  const isMember = isSessionMember(session, ability);
  if (!isMember) {
    name = (session as any).publicName || session.name;
  } else {
    name = session.name;
  }
  return typeof name === 'string' ? name : getRelevantTranslationFor(name);
};

const getMySessionFromMember = (
  member: MemberResponse,
  state: GlobalState
): MySession[] => {
  const sessions = member.sessionMembers.reduce(
    (acc, sessionMember) => {
      const session = state.sessions.find((s) => s.id === sessionMember.id);
      if (!session) {
        return acc;
      }
      const mySession: MySession = {
        ...session,
        isFavorite: !!state.profile?.favoriteSessionIds.some(
          (id) => id === session.id
        ),
        roles: sessionMember.roles,
      };

      return [...acc, mySession];
    },

    [] as MySession[]
  );
  return sessions;
};

export const getAllMySessions = (state: GlobalState): MySession[] => {
  const mySessions = state.members.reduce((acc, member) => {
    if (
      !member.active ||
      !member.sessionMembers ||
      member.sessionMembers.length === 0
    ) {
      return acc;
    }
    return [...acc, ...getMySessionFromMember(member, state)];
  }, [] as MySession[]);

  return mySessions;
};

export const getMemberFromSession = (
  sessionId: string,
  state: {
    members: MemberResponse[];
  }
): MemberResponse | undefined => {
  const member = state.members.find(
    (member) =>
      member.active &&
      member.sessionMembers &&
      member.sessionMembers.some((sm) => sm.id === sessionId)
  );
  return member;
};

export const composeTreeView = (sessions: Session[]) => {
  const orderedSessions = orderBy(sessions, [
    'level',
    'childNumber',
    (session) => getRelevantTranslationFor(session.name),
  ]);

  const root: SessionTree[] = [];
  const sessionsTree: SessionTree[] = [];
  orderedSessions.forEach((session) => {
    const parentId =
      session.parentSessionIds.length > 0
        ? session.parentSessionIds[session.parentSessionIds.length - 1]
        : undefined;

    let parentTree: SessionTree | undefined = parentId
      ? sessionsTree.find((s) => s.session.id === parentId)
      : undefined;

    const sessionTree: SessionTree = {
      children: [],
      key: session.id,
      value: session.id,
      session,
      label: getRelevantTranslationFor(session.name),
    };

    if (!parentTree) {
      root.push(sessionTree);
    } else {
      parentTree.children = [...(parentTree.children || []), sessionTree];
    }
    sessionsTree.push(sessionTree);
  });

  return root;
};

export const isSessionAdmin = (session: Session, ability: Ability) => {
  let isAllowed = ability.can('post', subject('session', session));

  if (
    !isAllowed &&
    session.parentSessionIds &&
    session.parentSessionIds.length > 0
  ) {
    //Could update if I could update one of my parents

    isAllowed = session.parentSessionIds.some((parentId) =>
      ability.can(
        'post',
        subject('session', {
          id: parentId,
        })
      )
    );
  }
  return isAllowed;
};

export const isFormAvailableForSession = ({
  formEntitlement,
  session,
}: {
  formEntitlement: FormEntitlement;
  session: Session | LightSession;
}) => {
  return (
    (formEntitlement.availabilitiesThrough === 'sphereMember' &&
      formEntitlement.sphereId === session.sphere.id) ||
    (formEntitlement.availabilitiesThrough === 'sessionMember' &&
      (formEntitlement.sessionIds || []).some(
        (sessionId) => sessionId === session.id
      ))
  );
};


export const isFeatureLimitedForSession = (session: Session | undefined) => {
  return !!session && session.category === "contributor" && session.subscription === "starter"

};
