import React from 'react';
import styles from './PageTitle.module.scss';

type Props = {
  renderLeft?(): JSX.Element;
  rendertitle?(): JSX.Element;
  renderRight?(): JSX.Element;
  title?: string;
  className?: string;
  doNotUseHeaderTag?: boolean;
};

const PageTitle: React.FC<Props> = ({
  renderLeft,
  rendertitle,
  title,
  className,
  renderRight,
  doNotUseHeaderTag,
}) => {
  return (
    <div className={`${styles.root} ${className || ''}`}>
      {renderLeft && <div className={styles.left}>{renderLeft()}</div>}

      {doNotUseHeaderTag ? (
        <div className={styles.title}>
          {rendertitle ? rendertitle() : title}
        </div>
      ) : (
        <h2 className={styles.title}>
          <div>{rendertitle ? rendertitle() : title}</div>
        </h2>
      )}
      {renderRight && <div className={styles.right}>{renderRight()}</div>}
    </div>
  );
};

export default PageTitle;
