/* eslint-disable react/jsx-no-target-blank */
import React, { FC, memo, useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './Footer.module.scss';
import {
  getFeelingRoute,
  getScanRoute,
  getSphereJoinRoute,
  getUrlParams,
} from '../../tools/routeTools';
import happyFace from '../../assets/images/Happy_64.png';
import notHappyFace from '../../assets/images/NotHappy_64.png';
import { FeelingMode } from '../../tools/routeTools';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { ScanIcon } from '../RemixIcons';
import RoundedButton from '../RoundedButton/RoundedButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        float: 'right',
        display: 'contents',
      },
    },
    sectionMobile: {
      float: 'right',
      position: 'fixed',
      bottom: '10px',
      right: '5px',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);

const Footer: FC<{}> = () => {
  const {
    state: {
      context: { sessionCtx },
    },
  } = useContext(GlobalStateContext);

  const { t } = useTranslation('i18n');
  const history = useHistory();
  const classes = useStyles();
  let location = useLocation();

  const [isFeelingVisible, setIsFeelingVisible] = useState<boolean>(false);
  const [isScanVisible, setIsScanVisible] = useState<boolean>(false);

  useEffect(() => {
    const pathParams = getUrlParams(location.pathname);
    const isJoinRoute = getSphereJoinRoute(pathParams) === location.pathname;

    const isScanVisible =
      !isJoinRoute &&
      !!sessionCtx &&
      sessionCtx.session.category === 'consumer';

    setIsScanVisible(isScanVisible);
    setIsFeelingVisible(!isScanVisible && !isJoinRoute);
  }, [location.pathname, sessionCtx]);

  const onFeelingIconClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.dataset.mode) {
      const feelingRoute = getFeelingRoute({
        callerUrl: location.pathname,
        mode: event.currentTarget.dataset.mode as FeelingMode,
      });
      if (feelingRoute !== location.pathname + location.search) {
        history.push(feelingRoute);
      }
    }
  };
  const onScanIconClick = (event: React.MouseEvent<HTMLElement>) => {
    history.push(getScanRoute({ callerUrl: location.pathname }));
  };
  return (
    <div className={styles.root2}>
      {isScanVisible && (
        <div className='feelingBtns'>
          <RoundedButton
            className={styles.scanButton}
            onClick={onScanIconClick}
          >
            <ScanIcon className={styles.scanIcon} />
          </RoundedButton>
        </div>
      )}
      {isFeelingVisible && (
        <div className='feelingBtns'>
          <div
            className={styles.wrapperBtn}
            data-mode='bad'
            onClick={onFeelingIconClick}
          >
            <img src={notHappyFace} alt='nothappyface' />
          </div>
          <div
            className={styles.wrapperBtn}
            data-mode='good'
            onClick={onFeelingIconClick}
          >
            <img src={happyFace} alt='happyface' />
          </div>
        </div>
      )}
      <div className={classes.sectionDesktop}>
        <div className='powerBy'>
          <div
            className={styles.powerbyText}
            dangerouslySetInnerHTML={{ __html: t('footer.title') }}
          />
          <a target='_blank' href='https://www.seen-apps.com'>
            <img className={styles.logo} src='/logo.png' alt='logo' />
          </a>
        </div>
      </div>
      <div className={classes.sectionMobile}>
        <a target='_blank' href='https://www.seen-apps.com'>
          <img className={styles.logo} src='/logo.png' alt='logo' />
        </a>
      </div>
    </div>
  );
};

export default memo(Footer);
