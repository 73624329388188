import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropDownContextToggle from './DropDownContextToggle';
import DropDownMenu from './DropDownContextMenu';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import styles from './DropDownContexts.module.scss';
import { useTranslation } from 'react-i18next';
import { SphereIcon, MyselfIcon } from '../header/Header';
import {
  getDefaultPage,
  getSessionRoute,
  getSphereContributionRoute,
} from '../../tools/routeTools';
import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';
import { Sphere } from '../../@types/sphere-api';
import { FavoriteFillIcon } from '../RemixIcons';
import SessionIcon from '../session/SessionIcon';
import { MySession } from '../../@types/webapp-api';
import { getSessionName } from '../../tools/sessionTools';
import AbilityContext from '../../context/AbilityContext';

const DropDownNotifications = ({ isGuest }: { isGuest?: boolean }) => {
  const { state } = useContext(GlobalStateContext);
  const { t } = useTranslation('i18n');
  const [itemSelectedKey, setItemSelectedKey] = useState<string | undefined>();
  const ability = useContext(AbilityContext);

  const [spheres, setSpheres] = useState<(Sphere & { favorite: boolean })[]>(
    []
  );
  const [sessions, setSessions] = useState<MySession[]>([]);

  useEffect(() => {
    const spheres = state.spheres
      .filter((s) => state.members.some((m) => m.sphereId === s.id))
      .map((s) => ({
        ...s,
        favorite: !!state.profile?.favoriteSphereIds.some((id) => id === s.id),
      }));

    setSessions(sortBy(state.mySessions, (s) => !s.isFavorite, 'name'));
    setSpheres(sortBy(spheres, (s) => !s.favorite, 'name'));

    if (state.context.sphereCtx) {
      setItemSelectedKey(`SPHERE-${state.context.sphereCtx.sphere.id}`);
    } else if (state.context.sessionCtx) {
      setItemSelectedKey(`SESSION-${state.context.sessionCtx.session.id}`);
    } else {
      setItemSelectedKey(undefined);
    }
  }, [state]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={DropDownContextToggle}
        id='dropdown-custom-components'
      />
      <Dropdown.Menu as={DropDownMenu} style={{}} className={styles.menu}>
        <div key={`context-myself`} className={'font-weight-normal'}>
          <Dropdown.Item
            as={Link}
            to={getDefaultPage()}
            active={itemSelectedKey === undefined}
          >
            <div className={styles.item}>
              <div className={styles.itemIcon}>
                <MyselfIcon />
              </div>
              <div className={styles.itemText}>{t('app.myself.title')}</div>
            </div>
          </Dropdown.Item>
        </div>

        {sessions.map((session) => {
          const sphere =
            spheres.find((s) => s.id === session.sphere.id) || session.sphere;
          return (
            <Dropdown.Item
              as={Link}
              key={`context-sessions-${session.id}`}
              to={getSessionRoute({
                sessionId: session.id,
              })}
              active={itemSelectedKey === `SESSION-${session.id}`}
            >
              <div className={styles.item}>
                <div className={styles.itemIcon}>
                  <SessionIcon session={session} />
                </div>
                <div className={styles.itemText}>
                  {t('app.session.title', {
                    session: `${getSessionName(session, ability)}`,
                  })}
                  <div className={styles.sphereName}> {sphere.name}</div>
                </div>
                {session.isFavorite && (
                  <div className={styles.itemFavorite}>
                    <FavoriteFillIcon className={styles.favorite} />
                  </div>
                )}
              </div>
            </Dropdown.Item>
          );
        })}
        {spheres
          .filter(
            (s) =>
              s.favorite ||
              !sessions.some((session) => session.sphere.id === s.id)
          )
          .map((sphere) => {
            return (
              sphere && (
                <Dropdown.Item
                  as={Link}
                  key={`context-spheres-${sphere.id}`}
                  to={getSphereContributionRoute({
                    sphereId: sphere.id,
                  })}
                  active={itemSelectedKey === `SPHERE-${sphere.id}`}
                >
                  <div className={styles.item}>
                    <div className={styles.itemIcon}>
                      <SphereIcon />
                    </div>
                    <div className={styles.itemText}>
                      {t('app.sphere.title', { sphere: sphere!.name })}
                    </div>
                    {sphere.favorite && (
                      <div className={styles.itemFavorite}>
                        <FavoriteFillIcon className={styles.favorite} />
                      </div>
                    )}
                  </div>
                </Dropdown.Item>
              )
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownNotifications;
