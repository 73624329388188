import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { confirmable } from 'react-confirm';
import styles from './ConfirmModal.module.scss';
import { ButtonVariant } from 'react-bootstrap/esm/types';

export type ConfirmModalOptions = {
  title?: string;
  btnPrimaryLabel?: string;
  btnPrimaryVariant?: ButtonVariant;
  btnSecondaryLabel?: string;
  hideSecondaryButton?: boolean;
};

type Props = {
  show: boolean;
  proceed: (value: boolean) => void;
  confirmation: string | JSX.Element;
  options?: ConfirmModalOptions;
};

const ConfirmModal: React.FC<Props> = ({
  show,
  proceed,
  confirmation,
  options: {
    title,
    btnPrimaryVariant,
    btnPrimaryLabel,
    btnSecondaryLabel,
    hideSecondaryButton,
  } = {},
}) => {
  const { t } = useTranslation('i18n');
  return (
    <Modal
      backdropClassName={styles.modalBackdrop}
      show={show}
      onHide={() => proceed(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title || t('common.confirmDlgTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmation}</Modal.Body>
      <Modal.Footer>
        {!hideSecondaryButton && (
          <Button variant='secondary' onClick={() => proceed(false)}>
            {btnSecondaryLabel || t('common.action.cancel')}
          </Button>
        )}

        <Button
          variant={btnPrimaryVariant || 'primary'}
          onClick={() => proceed(true)}
        >
          {btnPrimaryLabel || t('common.action.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default confirmable(ConfirmModal);
