import React, { FC, useEffect, useState } from 'react';
import { Workbox } from 'workbox-window';
import useInterval from '../tools/useInterval';
import { usePageVisibility } from 'react-page-visibility';

const CHECK_UPDATE_INTERVAL_IN_MILLIS = process.env
  .REACT_APP_CHECK_UPDATE_INTERVAL_IN_MILLIS
  ? parseInt(`${process.env.REACT_APP_CHECK_UPDATE_INTERVAL_IN_MILLIS}`)
  : 3600000;

export type ServiceWorkerContextState = {
  isEnable: boolean;
  appVisible: boolean;
  callUpdate: boolean;
};

const ServiceWorkerContext = React.createContext<ServiceWorkerContextState>({
  isEnable: false,
  appVisible: false,
  callUpdate: false,
});

export type Props = {
  enableCheckForUpdatePolling?: boolean;
};

export const ServiceWorkerProvider: FC<Props> = ({
  children,
  enableCheckForUpdatePolling = true,
}) => {
  const [wb, setWb] = useState<Workbox>();
  const isAppVisible = usePageVisibility();
  const [callUpdate, setCallUpdate] = useState<boolean>(false);

  useInterval(
    () => {
      if (wb) {
        setCallUpdate(true);
      }
      wb?.update();
    },
    enableCheckForUpdatePolling ? CHECK_UPDATE_INTERVAL_IN_MILLIS : null
  );

  useEffect(() => {
    if (isAppVisible) {
      if (wb) {
        setCallUpdate(true);
      }
      wb?.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppVisible]);

  useEffect(() => {
    console.log(
      `PUBLIC_URL: ${process.env.PUBLIC_URL} - origin: ${window.location.origin} - href: ${window.location.href}`
    );
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        return;
      }

      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      const wb = new Workbox(swUrl);
      setWb(wb);

      wb.addEventListener('waiting', () => {
        wb?.messageSkipWaiting();
      });
      wb.addEventListener('activated', (e) => {
        console.log(`activated v${process.env.REACT_APP_VERSION}`);
        if (e.isUpdate) {
          window.location.reload();
        }
      });
      wb.register();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ServiceWorkerContext.Provider
      value={{
        isEnable: !!wb,
        appVisible: isAppVisible,
        callUpdate,
      }}
    >
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export const useServiceWorker = () => {
  return React.useContext(ServiceWorkerContext);
};
