import React, { useEffect, useRef } from 'react';
import styles from './ChatDialog.module.scss';
import Chat from './Chat';
import { Button } from 'react-bootstrap';

export type ChatMessage = {
  id: string;
  text: string;
  isWriting?: boolean;
  user: 'me' | 'assistant';
};

export type ChatSuggestion = {
  id: string;
  text: string;
  onlyOnce?: boolean;
  closeDialogAfter?: boolean;
};

export type ContextQuestion = {
  contextId: string;
  question: string;
  suggestions: ChatSuggestion[];
  allowFreeText?: boolean;
};

export type ContextQuestionResponse = ContextQuestion & {
  answerId?: string;
  answerText?: string;
};

export type Context = {
  contextId: string;
  value: string;
  answerId?: string;
};

interface ChatDialogProps {
  messages: ChatMessage[];
  suggestions: {
    onClick: (suggestion: ChatSuggestion) => void;
    values: ChatSuggestion[];
  };
  disabled?: boolean;
}

const ChatDialog: React.FC<ChatDialogProps> = ({
  messages,
  suggestions,
  disabled,
}) => {
  const lastMessage = useRef(null);

  useEffect(() => {
    const scroll = async () => {
      await 200;
      const lastMessageElement: any = lastMessage?.current;
      if (lastMessageElement?.scrollIntoView) {
        lastMessageElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };
    scroll();
  }, [messages]);

  const handleSuggestionClicked = (suggestion: ChatSuggestion) => {
    suggestions?.onClick(suggestion);
  };

  return (
    <div className={`${styles.root}`}>
      <div className={`${styles.suggestionContainer}`}>
        {!disabled &&
          suggestions?.values.map((suggestion) => (
            <div
              key={`chat_suggestion_${suggestion.id}`}
              className={`${styles.suggestionBox} ${styles.messageBoxMe}`}
            >
              <Button
                onClick={() => handleSuggestionClicked(suggestion)}
                disabled={disabled}
              >
                {suggestion.text}
              </Button>
            </div>
          ))}
      </div>

      {messages.map((message, index) => (
        <div
          key={`chat_${message.id}`}
          ref={index === 0 ? lastMessage : undefined}
          className={`${styles.messageBox} ${
            message.user === 'assistant'
              ? styles.messageBoxAssistant
              : styles.messageBoxMe
          } ${index === 0 ? styles.last : ''}`}
        >
          <Chat message={message} key={message.id} />
        </div>
      ))}
    </div>
  );
};

export default ChatDialog;
