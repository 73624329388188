import { FC, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import styles from './StrategiesForm.module.scss';
import { Strategy, StrategyPost } from '../../@types/strategy-api';
import { useTranslation } from 'react-i18next';
import { getStrategyIcon } from '../../tools/strategyTools';
import { confirmWrapper } from '../../tools/confirm';
import api from '../../api/Api';
import ReactLoading from 'react-loading';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import { getErrorTranslation } from '../../tools/errorTools';
import MultiLingualInput from '../MulitLingual/MultiLingualInput';
import MultiGoalSelect from '../feeling/goal/MultiGoalSelect';
import { HiddenIcon, VisibleIcon } from '../RemixIcons';

type Props = {
  show: boolean;
  onClose: () => void;
  value: StrategyPost & { id?: string };
  onChange: (value: Strategy) => void;
  onDelete: () => void;
  isMyself?: boolean;
  dicoPrefix?: string;
};

const StrategyFormModal: FC<Props> = ({
  show,
  value,
  onClose,
  onChange,
  onDelete,
  isMyself,
  dicoPrefix,
}) => {
  const { t } = useTranslation('i18n');

  const [saving, setSaving] = useState<false | 'save' | 'delete'>(false);

  const [title, setTitle] = useState(value.title);
  const [description, setDescription] = useState(value.description);
  const [goalsLinked, setGoalsLinked] = useState(value.goalsLinked || []);
  const [isHidden, setIsHidden] = useState(value.isHidden || false);

  const handleSave = async (event: any) => {
    if (!title?.default) {
      return;
    }
    let strategy = { ...value, title, description, goalsLinked, isHidden };
    setSaving('save');
    try {
      const response = await (strategy.id
        ? api.updateStrategy(strategy.id, {
            title: strategy.title,
            description: strategy.description || {},
            goalsLinked: strategy.goalsLinked,
            isHidden: strategy.isHidden,
          })
        : api.addStrategy(strategy));
      onChange(response);
    } catch (error: any) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          error,
          t,
        })
      );
    }
    setSaving(false);
  };

  const handleDelete = async () => {
    if (!value.id) {
      return;
    }
    if (
      await confirmWrapper(t(`${dicoPrefix}.delete.confirm`), {
        btnPrimaryLabel: t(`${dicoPrefix}.delete.confirmAction`),
        btnPrimaryVariant: 'danger',
      })
    ) {
      setSaving('delete');
      try {
        await api.deleteStrategy(value.id);
        onDelete();
      } catch (error: any) {
        captureException(error);
        toast.error(
          getErrorTranslation({
            error,
            t,
          })
        );
      }
      setSaving(false);
    }
  };

  return (
    <Modal className={styles.modal} size='lg' show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalHeader}>
          {getStrategyIcon(value.type, { className: styles.icon })}
          {t(`${dicoPrefix}.type.${value.type}`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col sm={12}>
              <MultiLingualInput
                onChange={setTitle}
                autoFocus
                value={title}
                maxLength={250}
                required
                multiLine={false}
                label={t(`${dicoPrefix}.form.title`)}
                onlyDefault={isMyself}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <MultiLingualInput
                onChange={setDescription}
                value={description}
                maxLength={2500}
                required={false}
                multiLine
                label={t(`${dicoPrefix}.form.description`)}
                onlyDefault={isMyself}
              />
            </Col>
          </Row>
          {isMyself && (
            <Row>
              <Col sm={12}>
                <Form.Group controlId='default'>
                  <Form.Label>{t(`${dicoPrefix}.form.goals`)}</Form.Label>
                  <InputGroup className={`mb-3 ${styles.inputGroup}`}>
                    <MultiGoalSelect
                      className={styles.goals}
                      onChange={setGoalsLinked}
                      value={goalsLinked}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={12}>
              <Form.Group controlId='visible'>
                <Form.Label></Form.Label>
                <ButtonGroup className={`${styles.btnGroup}`}>
                  <Button
                    variant={!isHidden ? 'primary' : 'secondary'}
                    onClick={() => setIsHidden(false)}
                  >
                    <VisibleIcon />
                    {t(`${dicoPrefix}.form.visible`)}
                  </Button>

                  <Button
                    variant={isHidden ? 'primary' : 'secondary'}
                    onClick={() => setIsHidden(true)}
                  >
                    <HiddenIcon />
                    {t(`${dicoPrefix}.form.hidden`)}
                  </Button>
                </ButtonGroup>
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className={styles.buttons}>
        <div className={styles.buttonLeft}>
          {value.id && (
            <Button variant='danger' disabled={!!saving} onClick={handleDelete}>
              {saving === 'delete' ? (
                <div className={styles.submitContent}>
                  <ReactLoading
                    type={'bars'}
                    color={'#DDD'}
                    height={'30px'}
                    width={'40px'}
                  />
                  <div className={styles.submitText}>
                    {t('common.action.saving')}
                  </div>
                </div>
              ) : (
                t('common.action.delete')
              )}
            </Button>
          )}
        </div>
        <div className={styles.buttonRight}>
          <Button variant='secondary' onClick={onClose}>
            {t('common.action.close')}
          </Button>
          <Button disabled={!!saving} onClick={handleSave}>
            {saving === 'save' ? (
              <div className={styles.submitContent}>
                <ReactLoading
                  type={'bars'}
                  color={'#DDD'}
                  height={'30px'}
                  width={'40px'}
                />
                <div className={styles.submitText}>
                  {t('common.action.saving')}
                </div>
              </div>
            ) : (
              t('common.action.save')
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default StrategyFormModal;
