import { TFunction } from 'i18next';

export const getErrorTranslation = ({
  t,
  error,
  defaultKey,
}: {
  t: TFunction;
  error: any;
  defaultKey?: string;
}) => {
  const defaultError = defaultKey ? t(defaultKey) : t('common.error');
  if (error?.response?.data?.message) {
    return t(error.response.data.message);
  }

  if (error.code) {
    return t(`api.error.${error.code}`);
  }

  const data = error.response?.data;
  if (data && Array.isArray(data)) {
    const messages = error.response.data
      .map((data: any) => {
        return data.key ? t(`api.error.${data.key}`, data.value ?? {}) : undefined
      })
      .filter((d: any) => !!d);
    return messages.length > 0 ? messages.join('; ') : defaultError;
  }
  if (data && data.key) {
    return t(`api.error.${data.key}`, data.value ?? {});
  }

  return defaultError;
};
