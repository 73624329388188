import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from '../../../api/Api';
import { toast } from 'react-toastify';
import Select, { ValueType, OptionsType } from 'react-select';
import selectStyles from '../../../styles/react-select.module.scss';
import differenceWith from 'lodash.differencewith';
import modalStyles from '../../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import memberPageStyles from './AddMemberPage.module.scss';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import GlobalStateContext from '../../../context/globalState/GlobalStateContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ADD_OR_UPDATE_SPHERE_MEMBER } from '../../../context/globalState/globalStateReducer';
import { Forbid2LineIcon } from '../../../components/RemixIcons';
import { captureException } from '@sentry/minimal';
import TagsInput from '../../../components/input/TagsInput';
import { getRelevantTranslationFor } from '../../../tools/multiLingualTools';
import LoadingPage from '../../loading/LoadingPage';
import { extractLocationData } from '../../../tools/routeTools';
import ReactLoading from 'react-loading';
import { getErrorTranslation } from '../../../tools/errorTools';
import { confirmWrapper } from '../../../tools/confirm';
import { MemberResponse } from '../../../@types/member-api';

const styles = { ...modalStyles, ...memberPageStyles };

type Props = {
  currentPageIdx?: number;
  onSubmit?: (state?: any) => void;
};

type FormInputs = {
  id: string;
  email: string;
  name: string;
  roles: ValueType<OptionType, true>;
  subscription: ValueType<OptionType, false>;
  active: boolean;
};

type OptionType = {
  value: string;
  label: string;
  isDisabled?: boolean;
};

const AddMemberPage: React.FC<Props> = ({ onSubmit = () => {} }) => {
  const { t } = useTranslation('i18n');
  const location = useLocation();
  const {
    state: {
      user,
      context: { sphereCtx },
      sphereTags,
    },
    dispatch,
  } = useContext(GlobalStateContext);
  const [accessMessage, setAccessMessage] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [saving, setSaving] = useState<false | 'save' | 'disable'>(false);
  const [couldActivateMember, setCouldActivateMember] =
    useState<boolean>(false);
  const [tags, setTags] = useState<{ value: string; label: string }[]>([]);
  const [memberId, setMemberId] = useState<string>();
  const [sphereId, setSphereId] = useState<string>();
  const [member, setMember] = useState<MemberResponse>();

  const [subscriptionOptions, setSubscriptionOptions] = useState<
    OptionsType<OptionType>
  >([
    {
      value: 'starter',
      label: t(`pages.user.subscription.starter.title`),
      isDisabled: false,
    },
    {
      value: 'essentials',
      label: t(`pages.user.subscription.essentials.title`),
      isDisabled: true,
    },
  ]);

  const { register, handleSubmit, reset, errors, formState, control } =
    useForm<FormInputs>({
      defaultValues: {
        id: '',
        email: '',
        name: '',
        roles: [],
        subscription: subscriptionOptions[0],
        active: true,
      },
    });

  const [roleOptions] = useState<OptionsType<OptionType>>(() => {
    const eOptions = [{ value: 'admin', label: t(`common.roles.admin`) }];
    // if (sphereCtx?.sphere.entitlements?.engagementUp?.active) {
    eOptions.push({
      value: 'contributor',
      label: t(`common.roles.contributor`),
    });
    // }
    if (sphereCtx?.sphere.entitlements?.salesUp?.active) {
      eOptions.push({ value: 'sales-up', label: t(`common.roles.sales-up`) });
    }
    return eOptions;
  });

  useEffect(() => {
    const options = subscriptionOptions.map((option) =>
      option.value === 'essentials'
        ? {
            ...option,
            isDisabled:
              !sphereCtx?.sphere.subscriptionOptions?.essentials?.licenseCount,
          }
        : option
    );
    console.log(sphereCtx);
    setSubscriptionOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sphereCtx, t]);

  useEffect(() => {
    const fetchMember = async (sphereId: string, memberId: string) => {
      setLoading(true);
      const member = await api.getSphereMember(sphereId, memberId);
      const formToReset = {
        ...member,
        roles: roleOptions.filter((option: OptionType) =>
          (member.roles as any[])?.find((role) => role === option.value)
        ),
        subscription:
          subscriptionOptions.find((s) => s.value === member.subscription) ||
          subscriptionOptions[0],
      };
      setMember(member);

      setTags(
        member?.tags?.map((t) => {
          const tag = sphereTags?.items.find((tag) => tag.id === t.key);

          if (tag) {
            return {
              value: tag.id,
              label: getRelevantTranslationFor(tag.name),
            };
          }

          return {
            value: t.key,
            label: t.value,
          };
        })
      );

      const result = differenceWith(
        member.roles as string[],
        roleOptions as OptionType[],
        (roleStr: string, { value }: OptionType) => roleStr === value
      );
      setCouldActivateMember(!member.removedByUser);
      result.forEach((missingRole: string) =>
        formToReset.roles.push({
          value: missingRole,
          label: t(`common.roles.${missingRole}`),
        })
      );
      reset(formToReset);

      if (!member.userLinked) {
        setAccessMessage(t('pages.sphere.members.section.access.userNotFound'));
      } else if (member.removedByUser) {
        setAccessMessage(t('pages.sphere.members.section.access.userLeave'));
      } else if (member.removedByAdmin) {
        setAccessMessage(t('pages.sphere.members.section.access.adminRevoke'));
      } else {
        setAccessMessage(undefined);
      }

      setLoading(false);
    };

    const data = extractLocationData(location);
    setSphereId(data.params?.sphereId);
    setMemberId(data.queryString?.memberId);

    if (data.params?.sphereId && data.queryString?.memberId) {
      fetchMember(data.params.sphereId, data.queryString.memberId);
    } else {
      setMember(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleDisable = async () => {
    if (!member || !sphereCtx) {
      return;
    }
    if (
      await confirmWrapper(
        t(`pages.sphere.members.section.delete.confirm`, {
          name: member.name,
          sphereName: sphereCtx.sphere.name,
        }),
        {
          btnPrimaryLabel: t(
            `pages.sphere.members.section.delete.confirmAction`
          ),
          btnPrimaryVariant: 'danger',
          title: t(`pages.sphere.members.section.delete.confirmAction`),
        }
      )
    ) {
      setSaving('disable');
      try {
        const updatedMember = await api.updateSphereMember(
          sphereCtx.sphere.id,
          member.id,
          {
            active: false,
            roles: member.roles,
          }
        );
        toast.success(t(`pages.sphere.members.section.delete.succeed`));
        dispatch({
          type: ADD_OR_UPDATE_SPHERE_MEMBER,
          payload: updatedMember,
        });
        onSubmit();
      } catch (error) {
        captureException(error);

        toast.error(
          getErrorTranslation({
            t,
            error,
            defaultKey: 'pages.sphere.members.section.delete.error',
          })
        );
      }
      setSaving(false);
    }
  };

  const onSubmitForm = async (form: FormInputs, event: any) => {
    if (!sphereId) {
      toast.error(t('common.error'));
      return;
    }
    setSaving('save');
    if (form.id) {
      try {
        const updatedMember = await api.updateSphereMember(sphereId, form.id, {
          name: form.name,
          roles: (form.roles! as any[]).map((role: any) => role.value),
          subscription: form.subscription?.value || 'starter',
          active: form.active,
          tags: tags?.map((t) => ({ key: t.value, value: t.label })) as any,
        });
        toast.success(t(`pages.sphere.members.section.update.succeed`));
        dispatch({
          type: ADD_OR_UPDATE_SPHERE_MEMBER,
          payload: updatedMember,
        });
        onSubmit();
      } catch (error) {
        captureException(error);

        toast.error(
          getErrorTranslation({
            t,
            error,
            defaultKey: 'pages.sphere.members.section.add.error',
          })
        );
      }
    } else {
      try {
        const addedMember = await api.addSphereMember({
          name: form.name,
          email: form.email,
          roles: (form.roles! as any[]).map((role: any) => role.value),
          requestByUserId: user.userId,
          sphereId,
          subscription: form.subscription?.value || 'starter',
          tags: tags?.map((t) => ({ key: t.value, value: t.label })) as any,
        });
        toast.success(t(`pages.sphere.members.section.add.succeed`));
        dispatch({
          type: ADD_OR_UPDATE_SPHERE_MEMBER,
          payload: addedMember,
        });
        onSubmit();
      } catch (error) {
        captureException(error);
        toast.error(
          getErrorTranslation({
            t,
            error,
            defaultKey: 'pages.sphere.members.section.add.error',
          })
        );
      }
    }
    setSaving(false);
  };

  return (
    <div className={styles.page}>
      <PageTitle
        title={t(
          `pages.sphere.members.section.${memberId ? 'update' : 'add'}.title`
        )}
      />{' '}
      {isLoading ? (
        <LoadingPage />
      ) : !sphereId ? (
        <div></div>
      ) : (
        <div className={styles.content}>
          <Container>
            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              autoComplete='off'
              noValidate
            >
              <input type='hidden' name='id' ref={register} />
              <Row>
                <Col sm={6}>
                  <Form.Group controlId='name'>
                    <Form.Label>
                      {t(`pages.sphere.members.section.form.labels.name`)} *
                    </Form.Label>
                    <Form.Control
                      name='name'
                      type='text'
                      placeholder={t(
                        `pages.sphere.members.section.form.labels.name`
                      )}
                      ref={register({
                        required: t(
                          `pages.sphere.members.section.form.validations.required`
                        ) as string,
                      })}
                      isInvalid={formState.isSubmitted && !!errors.name}
                    />
                    {errors.name && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.name.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group controlId='email'>
                    <Form.Label>
                      {t(`pages.sphere.members.section.form.labels.email`)} *
                    </Form.Label>
                    <Form.Control
                      disabled={!!memberId}
                      name='email'
                      placeholder={t(
                        `pages.sphere.members.section.form.labels.email`
                      )}
                      ref={register({
                        required: t(
                          `pages.sphere.members.section.form.validations.required`
                        ) as string,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: t(
                            `pages.sphere.members.section.form.validations.valid_email`
                          ),
                        },
                      })}
                      isInvalid={formState.isSubmitted && !!errors.email}
                    />
                    {errors.email && (
                      <Form.Control.Feedback type='invalid'>
                        {errors.email.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group controlId='subscription'>
                    <Form.Label>
                      {t(
                        `pages.sphere.members.section.form.labels.subscription`
                      )}{' '}
                      *
                    </Form.Label>
                    <div className={selectStyles.reactSelect}>
                      <Controller
                        as={Select}
                        name='subscription'
                        control={control}
                        options={subscriptionOptions}
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        className={selectStyles.reactSelect}
                        classNamePrefix='custom-react-select'
                        placeholder={t(
                          `pages.sphere.members.section.form.placeholder`
                        )}
                        rules={{
                          required: t(
                            `pages.sphere.members.section.form.validations.required`
                          ) as string,
                        }}
                      />
                      {errors.subscription && errors.subscription.message}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group controlId='roles'>
                    <Form.Label>
                      {t(`pages.sphere.members.section.form.labels.roles`)} *
                    </Form.Label>
                    <div className={selectStyles.reactSelect}>
                      <Controller
                        as={Select}
                        name='roles'
                        control={control}
                        options={roleOptions}
                        isSearchable
                        isClearable
                        isMulti
                        noOptionsMessage={() =>
                          t(`pages.sphere.members.section.form.noOptionMessage`)
                        }
                        className={selectStyles.reactSelect}
                        classNamePrefix='custom-react-select'
                        placeholder={t(
                          `pages.sphere.members.section.form.placeholder`
                        )}
                        rules={{
                          required: t(
                            `pages.sphere.members.section.form.validations.required`
                          ) as string,
                        }}
                      />
                      {errors.roles && errors.roles.message}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group controlId='tags'>
                    <Form.Label>{t(`pages.sphere.sessions.tags`)}</Form.Label>
                    <TagsInput
                      module={`member`}
                      sphereId={sphereId}
                      onChange={setTags}
                      tagsSelected={tags}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {member && !member?.active && (
                <Row style={{ display: memberId ? 'block' : 'none' }}>
                  <Col sm={6}>
                    <Form.Group controlId='active'>
                      <Form.Check
                        disabled={!couldActivateMember}
                        id='active'
                        name='active'
                        label={t(
                          `pages.sphere.members.section.form.labels.active`
                        )}
                        ref={register}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {accessMessage && (
                <Row style={{ display: memberId ? 'block' : 'none' }}>
                  <Col sm={6}>
                    <div className={styles.comment}>
                      <Forbid2LineIcon className={styles.noAccess} />{' '}
                      {accessMessage}
                    </div>
                  </Col>
                </Row>
              )}

              <Row>
                <Col sm={11} className={styles.buttons}>
                  {!!member?.active && (
                    <Button
                      disabled={!!saving || isLoading}
                      variant='danger'
                      className={styles.right}
                      onClick={() => handleDisable()}
                    >
                      {saving === 'disable' && (
                        <div className={styles.submitContent}>
                          <ReactLoading
                            type={'bars'}
                            color={'#DDD'}
                            height={'30px'}
                            width={'40px'}
                          />
                          <div className={styles.submitText}>
                            {t('common.action.saving')}
                          </div>
                        </div>
                      )}
                      {!saving &&
                        t('pages.sphere.members.section.delete.confirmAction')}
                    </Button>
                  )}
                  <Button
                    variant='secondary'
                    className={styles.right}
                    onClick={() => onSubmit()}
                  >
                    {t('common.action.close')}
                  </Button>

                  <Button
                    disabled={!!saving || isLoading}
                    variant='primary'
                    className={styles.left}
                    name='submit'
                    type='submit'
                  >
                    {saving === 'save' && (
                      <div className={styles.submitContent}>
                        <ReactLoading
                          type={'bars'}
                          color={'#DDD'}
                          height={'30px'}
                          width={'40px'}
                        />
                        <div className={styles.submitText}>
                          {t('common.action.saving')}
                        </div>
                      </div>
                    )}
                    {!saving && t('common.action.save')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      )}
    </div>
  );
};

export default AddMemberPage;
