export const GOALS_AND_SUB = [{
  id: "social",
  sub: [
    "world_peace",
    "equality",
    "justice",
    "committed",
    "ethical",
    "honest",
    "humble",
    "firm_values",
    "loyal",

  ],
}, {
  id: "virtuous",
  sub: [
    "charitable",
    "helping_others",
    "selfless",
    "empathy",
    "listen_others",
    "please_others",
    "respected_by_others",
    "other_trust_you",
    "inspiring",
    "teaching",
    "good_examples",
  ],
}, {
  id: "belief",
  sub: [
    "achieving_salvation",
    "maintaining_faith",
    "pleasing_god",
    "practicing_traditions",
    "growing_spiritually",
    "avoiding_impure_acts",
    "achieving_harmony_oneness",
    "meaning_in_life",
    "wisdom",

  ],
}, {
  id: "to_come_true",
  sub: [
    "achieving_personal_growth",
    "knowing_myself",
    "true_to_myself",
    "get_in_tune_with_emotions",
    "accept_myself_and_other",
    "happy_content",
    "satisfied",
    "feeling_good",

  ],
}, {
  id: "open_mind",
  sub: [
    "appreciating_design",
    "arts",
    "creative",
    "exp_natural_beauty",
    "take_risks",
    "being_curious",
    "being_unique_or_different",
    "flexibility_viewpoint",
    "passionate",
    "pursuing_ideals",
    "playful_carefree",
    "spontaneous",
    "adventurous",
    "exciting_life",
    "live_today",
    "devoting_time_amusements",

  ],
}, {
  id: "protect_yourself",
  sub: [
    "avoiding_anxiety",
    "avoiding_stress",
    "avoiding_feelings_guilt",
    "avoiding_regrets",
    "avoiding_physical_harm",
    "avoiding_criticisms",
    "avoiding_rejection",
    "avoid_conflict",
    "avoid_hurting",

  ],
}, {
  id: "avoid_hassle",
  sub: [
    "alone",
    "avoid_being_noticed",
    "avoid_others",
    "avoid_effort",
    "avoid_responsibility",
    "procrastinate",

  ],
}, {
  id: "belonging",
  sub: [
    "more_assertive",
    "Sharing_feelings",
    "express_myself",
    "social_group",
    "do_with_people",
    "close_friends",
    "making_friends",
    "rely_on_others",
    "entertaining",
    "popular",
    "attract_sexual_partner",
    "having_sexual_exp",
    "close_with_romantic_partner",
    "love",
    "being_clean",
    "active",
    "controlling_physical_env",
    "not_hurry",
    "fashionable",
    "attractive",
    "conventional",
    "safe_secure",
    "avoid_change",
    "being_taken_care_of",
    "having_mentor",

  ],
}, {
  id: "power",
  sub: [
    "beat_people",
    "being_better_than_others",
    "controlling_others",
    "making_decisions_for_others",
    "get_revenge",
    "have_others_give_what_i_want",
    "leader",
    "persuading_others",
    "enforce_accountability",

  ],
}, {
  id: "health",
  sub: [
    "physically_active",
    "physically_fit",
    "physically_healthy",
    "physically_able_to_do_routine",
    "athletic_ability",

  ],
}, {
  id: "family",
  sub: [
    "be_good_parent",
    "emotionally_close_to_my_children",
    "secure_family_life",
    "good_marriage",
    "close_to_my_parents",
    "receiving_help_from_my_parents",
    "obeying_my_parents",
    "respecting_elders",

  ],
}, {
  id: "ambition",
  sub: [
    "challenges",
    "overcoming_failure",
    "mastering",
    "ambitious",
    "highly_competent",
    "avoiding_failure",
    "strive_perfection",
    "confident",
    "confident_in_my_judgment",
    "full_control",
    "independent",
    "guidelines",
    "disciplined",
    "self-controlled",
    "responsible",
    "logical",
    "practical",
    "carefully_think_decisions",
    "attentive",
    "be_orderly",
    "keep_things_manageable",
    "make_plans",
    "attend_details",
    "get_things_right",
    "efficient",
    "on_time",
    "do_things_quickly",

  ],
}, {
  id: "intellectual_skills",
  sub: [
    "analyst",
    "engineer",
    "physicist",
    "smart",
    "having_intellectual_exp",
    "education",
    "obtaining_diploma",

  ],
}, {
  id: "success",
  sub: [
    "achieving_financial_security",
    "able_to_meet_financial_needs",
    "making_money",
    "having_money_for_my_descendants",
    "providing_for_family",
    "buying_things",
    "easy_life",
    "successful_job",
    "good_job",
    "having_job",
    "keeping_up_to_date_job_knowledge",
    "love_my_work",
  ]
}
]
