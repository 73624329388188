import { useContext, useEffect } from 'react';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';

const Stonly = () => {
  const { state } = useContext(GlobalStateContext);

  useEffect(() => {
    if (state?.user?.userId && state?.profile) {
      const scripts: HTMLScriptElement[] = [];
      try {
        let script = document.createElement('script');
        scripts.push(script);
        script.innerHTML = `
        StonlyWidget('identify', '${state.user.userId}', ${JSON.stringify({
          language: state.profile.locale,
          isGuest: !!state.profile.isGuest,
          lastFeelingTimestamp: state.lastFeelingTimestamp,
        })});
        StonlyWidget('setWidgetLanguage', '${state.profile.locale}');
        `;
        document.body.appendChild(script);
      } catch (error) {}

      return () => {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.profile?.locale]);

  return <div></div>;
};

export default Stonly;
