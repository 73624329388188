/* eslint react-hooks/exhaustive-deps: 0 */
import React, { FC } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { StudyProgramIcon } from '../../components/RemixIcons';
import { Sphere } from '../../@types/sphere-api';

type Props = {
  sphere?: Pick<Sphere, 'isStudyProgram'>;
};

const ThinkingReminder: FC<Props> = ({ sphere }) => {
  const { t } = useTranslation('i18n');
  return (
    <div style={{ marginTop: '30px' }}>
      {sphere && sphere.isStudyProgram && (
        <Alert key={'warning'} variant='warning'>
          <StudyProgramIcon />
          <div
            dangerouslySetInnerHTML={{
              __html: t('pages.thinking.linkToSphereStudyProgramInfo'),
            }}
          />
        </Alert>
      )}
      {sphere && !sphere.isStudyProgram && (
        <Alert key={'info'} variant='info'>
          <div
            dangerouslySetInnerHTML={{
              __html: t('pages.thinking.linkToSphereInfo'),
            }}
          />
        </Alert>
      )}
    </div>
  );
};

export default ThinkingReminder;
