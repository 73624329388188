import React, { useState, FC } from 'react';
import LayoutContext from './LayoutContext';
import { MenuProperties } from '../../@types/seen-apps';
import { navigationMenu } from '../../components/side-menus/NavigationMenu';
import { useMediaPredicate } from 'react-media-hook';

type Props = {};

const LayoutContextProvider: FC<Props> = ({ children }) => {
  const [navigationMenuOpen, setNavigationMenuOpen] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState<boolean>(false);
  const [filtersMenuOpen, setFiltersMenuOpen] = useState<boolean>(false);
  const [portalMenuOpen, setPortalMenuOpen] = useState<boolean>(false);
  const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
  const [selectedNavigationItem, setSelectedNavigationItem] =
    useState<MenuProperties>(navigationMenu[0]);

  const isNavigationMenuDocked = useMediaPredicate('(min-width: 768px)');

  const toggleNavigationMenu = () => {
    setSettingsMenuOpen(false);
    setFiltersMenuOpen(false);
    setPortalMenuOpen(false);
    setContextMenuOpen(false);
    setNavigationMenuOpen(!navigationMenuOpen);
  };
  const toggleContextMenu = () => {
    setSettingsMenuOpen(false);
    setFiltersMenuOpen(false);
    setPortalMenuOpen(false);
    setNavigationMenuOpen(false);
    setContextMenuOpen(!contextMenuOpen);
  };

  const toggleSettingsMenu = () => {
    setNavigationMenuOpen(false);
    setFiltersMenuOpen(false);
    setPortalMenuOpen(false);
    setContextMenuOpen(false);
    setSettingsMenuOpen(!settingsMenuOpen);
  };

  const toggleFiltersMenu = () => {
    setNavigationMenuOpen(false);
    setSettingsMenuOpen(false);
    setPortalMenuOpen(false);
    setContextMenuOpen(false);
    setFiltersMenuOpen(!filtersMenuOpen);
  };

  const togglePortalMenu = () => {
    setNavigationMenuOpen(false);
    setSettingsMenuOpen(false);
    setFiltersMenuOpen(false);
    setContextMenuOpen(false);
    setPortalMenuOpen(!portalMenuOpen);
  };

  return (
    <LayoutContext.Provider
      value={{
        isNavigationMenuOpen: navigationMenuOpen,
        isSettingsMenuOpen: settingsMenuOpen,
        isFiltersMenuOpen: filtersMenuOpen,
        isPortalMenuOpen: portalMenuOpen,
        isContextMenuOpen: contextMenuOpen,
        isNavigationMenuDocked,
        toggleContextMenu: toggleContextMenu,
        toggleNavigationMenu: toggleNavigationMenu,
        toggleSettingsMenu: toggleSettingsMenu,
        togglePortalMenu: togglePortalMenu,
        setSelectedNavigationMenuItem: (item) => {
          setSelectedNavigationItem(item);
          setNavigationMenuOpen(false);
        },
        toggleFiltersMenu: toggleFiltersMenu,

        selectedNavigationMenuItem: selectedNavigationItem,
        navigationStateChangeHandler: (newState) =>
          setNavigationMenuOpen(newState.isOpen),
        settingsStateChangeHandler: (newState) =>
          setSettingsMenuOpen(newState.isOpen),
        filtersStateChangeHandler: (newState) =>
          setFiltersMenuOpen(newState.isOpen),
        portalStateChangeHandler: (newState) =>
          setPortalMenuOpen(newState.isOpen),
        contextStateChangeHandler: (newState) =>
          setContextMenuOpen(newState.isOpen),
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;
