import { slide as Menu } from 'react-burger-menu';
import LayoutContext from '../../context/layout/LayoutContext';
import burgerStyles from './react-burger-menu.module.scss';
import menuStyles from './Menu.module.scss';
import pageStyles from './FilterMenu.module.scss';
import { useContext } from 'react';
const styles = { ...burgerStyles, ...menuStyles, ...pageStyles };

const PortalMenu = () => {
  const layoutContext = useContext(LayoutContext);
  return (
    <div className={`${styles.root}`}>
      <Menu
        width={375}
        customBurgerIcon={false}
        customCrossIcon={false}
        right
        isOpen={layoutContext.isPortalMenuOpen}
        onStateChange={(state: any) => {
          layoutContext.portalStateChangeHandler(state);
        }}
      >
        <div id="portalMenu" />
      </Menu>
    </div>
  )
}

export default PortalMenu;