import { FC, useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form, FormSurvey } from '../../@types/form-api';
import FormComponent from '../../components/forms/FormComponent';
import LoadingPage from '../loading/LoadingPage';
import { captureException } from '@sentry/react';
import api from '../../api/Api';
import { toast } from 'react-toastify';
import { getErrorTranslation } from '../../tools/errorTools';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { ADD_OR_UPDATE_FORM_SURVEYS } from '../../context/globalState/globalStateReducer';
type Props = {
  show: boolean;
  formId: string;
  onClose: (survey?: FormSurvey) => void;
};

const MyselfAddFormModal: FC<Props> = ({ show, onClose, formId }) => {
  const { dispatch } = useContext(GlobalStateContext);
  const { t } = useTranslation('i18n');
  const [fullForm, setFullForm] = useState<Form>();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const fullForm = await api.getForm(formId!);
        setFullForm(fullForm);
      } catch (error: any) {
        captureException(error);
        toast.error(
          getErrorTranslation({
            t,
            error,
          })
        );
        onClose();
      }
    };
    fetchForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, t]);

  const handleClose = () => {
    onClose(undefined);
  };
  const handleSave = async (values: Record<string, any>) => {
    try {
      setSaving(true);
      const survey = await api.createFormSurvey({
        formId,
        values,
        locale: moment.tz.guess(),
        fromDirectLink: false,
      });

      dispatch({
        type: ADD_OR_UPDATE_FORM_SURVEYS,
        payload: [survey],
      });

      onClose(survey);
    } catch (error: any) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          t,
          error,
        })
      );
    }
    setSaving(false);
  };

  return (
    <Modal
      size='xl'
      show={show}
      onHide={handleClose}
      style={{
        zIndex: 2100,
      }}
    >
      <Modal.Body
        style={{
          position: 'relative',
          display: 'table',
          overflowY: 'auto',
          overflowX: 'auto',
          width: 'auto',
          height: 'calc(100vh - 50px)',
        }}
      >
        {!fullForm ? (
          <LoadingPage />
        ) : (
          <FormComponent
            form={fullForm}
            printable={false}
            saving={saving}
            onSave={handleSave}
            onClose={handleClose}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MyselfAddFormModal;
