import { routes } from '../AppRoutes';
import { matchPath } from 'react-router-dom';
import { Category, feelingCategory } from '../@types/webapp-api';
import { SessionType } from '../@types/session-api';
import { Location } from 'history';

export enum ShowDialog {
  password = 'password',
  profile = 'profile',
  subscription = 'subscription',
  emails = 'emails',
  feeling = 'feeling',
  salesUp = 'salesUp',
  thinking = 'thinking',
  feelingUpdate = 'update-feeling',
  thinkingUpdate = 'update-thinking',
  logout = 'logout',
  sphereNotAllowed = 'sphere-not-allowed',
  addSphereMember = 'addSphereMember',
  addOrUpdateTag = 'addOrUpdateTag',
  addOrUpdateFormEntitlement = 'addOrUpdateFormEntitlement',
  createThinkingAxis = 'createThinkingAxis',
  updateThinkingAxis = 'updateThinkingAxis',
  scan = 'scan',
  registration = 'registration',
  createSession = 'createSession',
  sessionNotAllowed = 'session-not-allowed',
  addOrUpdateMemberSession = 'addOrUpdateSessionMember',
  addOrUpdateMember = 'addOrUpdateMember',
  addOrUpdatePromotionCode = 'addOrUpdatePromotionCode',
  addForm = 'addForm',
  selectFormContext = 'formContext',
  updateForm = 'updateForm',
  strategyAssessment = 'strategyAssessment',
  sphereStrategyAssessment = 'sphereStrategyAssessment',
  sessionStrategyAssessment = 'sessionStrategyAssessment',
  updateStrategyAssessment = 'updateStrategyAssessment',
  lifeCompass = 'life-compass',
  myselfReport = 'myself-report',
  campaignEnd = 'campaign-end',

  link = 'link',
  updateLink = 'updateLink',
  addLink = 'addLink',
}

/*
 * Global
 */
export const getDefaultPage = () => '/';

export const getCampaignRoute = (options: { campaignId: string }) =>
  `/apps/campaigns/${options.campaignId}/`;

export const getMyselfFullReportPage = () => '/apps/myself-report';
export const getMyFormsPage = () => '/apps/myforms-report';
export const getMyHistoryPage = () => '/apps/history';
export type FeelingMode = 'good' | 'bad';
export const getFeelingRoute = (options: {
  mode: FeelingMode;
  sphereId?: string;
  sessionId?: string;
  category?: feelingCategory;
  callerUrl: string;
}) =>
  `${options.callerUrl}${options.sphereId
    ? getSphereContributionRoute({ sphereId: options.sphereId })
    : options.sessionId
      ? getSessionRoute({ sessionId: options.sessionId })
      : ''
  }?&show=${ShowDialog.feeling}&mode=${options.mode}${options.category ? '&category=' + options.category : ''
  }`;
export const getFeelingUpdateRoute = (options: {
  feelingId: string;
  callerUrl: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.feelingUpdate}&feelingId=${options.feelingId}`;
export const getThinkingUpdateRoute = (options: {
  thinkingId: string;
  callerUrl: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.thinkingUpdate}&thinkingId=${options.thinkingId}`;

export const getThinkingRoute = (options: { callerUrl: string }) =>
  `${options.callerUrl}?&show=thinking`;

export const getProfileRoute = (options: {
  callerUrl: string;
  emailValidated?: {
    email: string;
  };
}) =>
  `${options.callerUrl}?&show=${ShowDialog.profile}${options.emailValidated
    ? `&email_validated=${options.emailValidated.email}`
    : ''
  }`;

export const getSubscriptionRoute = (options: {
  callerUrl: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.subscription}`;
export const getEmailsRoute = (options: { callerUrl: string }) =>
  `${options.callerUrl}?&show=${ShowDialog.emails}`;

export const getChangePasswordRoute = (options: { callerUrl: string }) =>
  `${options.callerUrl}?&show=${ShowDialog.password}`;

export const getPrivacyRoute = () => `/apps/privacy`;
export const getAdminRoute = () => `https://admin.seen-apps.com`;

export const getSphereNotAllowedRoute = (options: { callerUrl: string }) =>
  `${options.callerUrl}?&show=${ShowDialog.sphereNotAllowed}`;
export const getSphereJoinRoute = (options: {
  sphereId: string;
  accessCode: string;
}) =>
  `${getSphereContributionRoute({ sphereId: options.sphereId })}/join/${options.accessCode
  }`;
/*
 * Sphere APP
 */
export const getSphereContributionRoute = (options: { sphereId: string }) =>
  `/apps/spheres/${options.sphereId}`;
export const getAdminSphereRoute = (options: {
  sphereId: string;
  tabSelected?: 'admin' | 'strategy';
}) =>
  `/apps/spheres/${options.sphereId}/settings${options.tabSelected === 'strategy' ? '?&tab=strategy' : ''
  }`;
export const getAdminSphereListingRoute = () => `/apps/spheres`;
export const getAdminCreateSphereRoute = () => `/apps/create-sphere`;
export const getAdminSphereEngagementUpRoute = (options: {
  sphereId: string;
}) => `/apps/spheres/${options.sphereId}/engagement-up`;
export const getAdminSphereSalesUpRoute = (options: { sphereId: string }) =>
  `/apps/spheres/${options.sphereId}/sales-up`;
export const addMemberSphereRoute = (options: { callerUrl: string }) =>
  `${options.callerUrl}?&show=${ShowDialog.addSphereMember}`;

export const addOrUpdateSphereTagRoute = (options: {
  callerUrl: string;
  tagId?: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.addOrUpdateTag}&tagId=${options.tagId}`;
export const addOrUpdateSphereFormEntitlementRoute = (options: {
  callerUrl: string;
  formId?: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.addOrUpdateFormEntitlement}&formId=${options.formId}`;
export const updateMemberSphereRoute = (options: {
  callerUrl: string
  memberId: string;
}) =>
  `${options.callerUrl}?show=${ShowDialog.addSphereMember}&memberId=${options.memberId}`;
export const getSphereFullReportRoute = (options: { sphereId: string }) =>
  `/apps/spheres/${options.sphereId}/reports`;

export const getSphereSessionsRoute = (options: {
  sphereId: string;
  category: Category | string;
}) => `/apps/spheres/${options.sphereId}/sessions/${options.category}`;
export const getSphereFormEntitlementsRoute = (options: { sphereId: string }) =>
  `/apps/spheres/${options.sphereId}/form-entitlements`;
export const getSphereSessionsChoicesRoute = (options: {
  sphereId: string;
  category: Category | string;
}) => `/apps/spheres/${options.sphereId}/${options.category}/tags/choices`;

export const getSessionRoute = (options: {
  sessionId: string;
  accessCode?: string;
}) => `/apps/sessions/${options.sessionId}`;
export const getSessionJoinRoute = (options: {
  sessionId: string;
  accessCode: string;
}) =>
  `${getSessionRoute({ sessionId: options.sessionId })}/join/${options.accessCode
  }`;
export const getSessionNotAllowedRoute = (options: { callerUrl: string }) =>
  `${options.callerUrl}?&show=${ShowDialog.sessionNotAllowed}`;
export const getAdminSessionRoute = (options: { sessionId: string }) =>
  `/apps/sessions/${options.sessionId}/settings`;

export const getSessionReportRoute = (options: { sessionId: string }) =>
  `/apps/sessions/${options.sessionId}/reports`;
export const createSessionRoute = (options: {
  callerUrl: string;
  type: SessionType;
  category: Category;
  sphereId: string;
  parentSessionId?: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.createSession}&type=${options.type
  }&category=${options.category}&sphereId=${options.sphereId}${options.parentSessionId ? `&parentSessionId=${options.parentSessionId}` : ''
  }`;

export const addOrUpdateMemberSessionRoute = (options: {
  callerUrl: string;
  memberId?: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.addOrUpdateMemberSession
  }${options.memberId ? `&memberId=${options.memberId}` : ''}`;

export const getAdminThinkingAxesListingRoute = () => `/apps/thinking-axes`;
export const getAdminThinkingAxisRoute = (options: {
  axisId: string;
  callerUrl: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.updateThinkingAxis}&id=${options.axisId}`;

export const getAdminCreateThinkingAxisRoute = (options: {
  callerUrl: string;
}) => `${options.callerUrl}?&show=${ShowDialog.createThinkingAxis}`;

export const getScanRoute = (options: { callerUrl: string }) =>
  `${options.callerUrl}?&show=${ShowDialog.scan}`;

export const getGuestRegistrationRoute = (options: { callerUrl: string }) =>
  `${options.callerUrl}?&show=${ShowDialog.registration}`;

export const getAddFormRoute = (options: {
  callerUrl: string;
  formId: string;
  sphereId?: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.selectFormContext},${ShowDialog.addForm}&formId=${options.formId}${options.sphereId ? `&sphereId=${options.sphereId}` : ""}`;
export const getAddMyselfFormRoute = (options: {
  callerUrl: string;
  formId: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.addForm}&formId=${options.formId}`;

export const getUpdateFormRoute = (options: {
  callerUrl: string;
  surveyId: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.updateForm}&surveyId=${options.surveyId}`;

export const getChatRoute = (options: {}) =>
  `/apps/chat`;

export const getGlobalMembersRoute = (options: {}) =>
  `/apps/global/members`;


export const getAddOrUpdateSuperAdminMemberRoute = (options: {
  callerUrl: string;
  memberId?: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.addOrUpdateMember
  }${options.memberId ? `&memberId=${options.memberId}` : ''}`;

export const getPromotionCodesRoute = (options: {}) =>
  `/apps/global/promotion-codes`;
export const getAddOrUpdatePromotionCodeRoute = (options: {
  callerUrl: string;
  memberId?: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.addOrUpdatePromotionCode}`;
export const getLifeCompassRoute = (options: {
  callerUrl: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.lifeCompass}`;
export const getMyselfFormReportRoute = (options: {
  reportId: string
}) => {
  return `/apps/global/myself-report/${options.reportId}`;
}


export const getUrlParams = (url: string) => {
  return routes.reduce((accumulator: any, route) => {
    const match = matchPath(url, {
      path: route.path,
      exact: route.exact,
    });
    return {
      ...accumulator,
      ...match?.params,
    };
  }, {});
};
export const getUrlQueryString = (searchQuery: string) => {
  const qs = new URLSearchParams(searchQuery);
  const response: Record<string, string> = {};
  qs.forEach((value: string, key: string) => {
    response[key] = value;
  });
  return response;
};

export const extractLocationData = (location: Location | URL) => {
  return {
    params: getUrlParams(location?.pathname),
    queryString: getUrlQueryString(location?.search),
  };
};

export const getShowLinkRoute = (options: {
  linkId: string;
  callerUrl: string;
}) => `${options.callerUrl}?&show=${ShowDialog.link}&linkId=${options.linkId}`;
export const getUpdateLinkRoute = (options: {
  linkId: string;
  callerUrl: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.updateLink}&linkId=${options.linkId}`;
export const getAddSessionLinkRoute = (options: { callerUrl: string }) =>
  `${options.callerUrl}?&show=${ShowDialog.addLink},${ShowDialog.link}`;

export const getAddUrlAliasRoute = (options: {
  category?: 'contributor' | 'consumer' | string;
  callerUrl: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.addLink},${ShowDialog.link}&${options.category ? `category=${options.category}` : ''
  }`;


export const getUpdateStrategyAssessmentRoute = (options: {
  callerUrl: string;
  assessmentId: string;
}) =>
  `${options.callerUrl}?&show=${ShowDialog.updateStrategyAssessment}&assessmentId=${options.assessmentId}`;

export const getStrategyAssessmentRoute = (options: {
  callerUrl: string;
  sphereId?: string
  sessionId?: string
}) =>
  `${options.callerUrl}?&show=${ShowDialog.strategyAssessment}${options.sphereId ? `&sphereId=${options.sphereId}` : ""}${options.sessionId ? `&sphereId=${options.sessionId}` : ""}`;