/* eslint react-hooks/exhaustive-deps: 0 */
import React, { FC, useEffect } from 'react';
import GlobalStateContext from './GlobalStateContext';
import { GlobalStateCtx } from '../../@types/seen-apps';
import { useLocation, matchPath } from 'react-router-dom';
import { routes } from '../../AppRoutes';
import { SET_CONTEXT } from './globalStateReducer';

const getUrlParams = (url: string) => {
  return routes.reduce((accumulator: any, route) => {
    const match = matchPath(url, {
      path: route.path,
      exact: route.exact,
    });
    return {
      ...accumulator,
      ...match?.params,
    };
  }, {});
};

type Props = {
  value: GlobalStateCtx;
};

type UrlParams = {
  sphereId?: string;
  sessionId?: string;
};

const GlobalStateContextProvider: FC<Props> = ({ value, children }) => {
  let location = useLocation();

  const fillContext = (urlParams: UrlParams) => {
    const { dispatch } = value;
    if (urlParams.sphereId) {
      const {
        state: { spheres, sphereCategories },
      } = value;
      const sphere = spheres.find((sphere) => sphere.id === urlParams.sphereId);
      if (sphere) {
        dispatch({
          type: SET_CONTEXT,
          payload: {
            sphereCtx: {
              sphere,
              category: sphereCategories.find(
                (cat) => cat.id === sphere.category.id
              ),
            },
          },
        });
      }
    } else if (urlParams.sessionId) {
      const {
        state: { sessions },
      } = value;
      const session = sessions.find(
        (session) => session.id === urlParams.sessionId
      );
      if (session) {
        dispatch({
          type: SET_CONTEXT,
          payload: {
            sessionCtx: {
              session,
            },
          },
        });
      }
    } else {
      dispatch({
        type: SET_CONTEXT,
        payload: { mySelfCtx: {} },
      });
    }
  };

  useEffect(() => {
    const urlParams = getUrlParams(location.pathname);
    fillContext(urlParams);
  }, [location]);

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateContextProvider;
