import { Filters, FacetValue } from '../../@types/seen-apps';
import MultiSelectFacet, { OptionType } from './MultiSelectFacet';
import { useTranslation } from 'react-i18next';

const SphereMultiSelectFacet = () => {
  const { t } = useTranslation('i18n');

  return (
    <MultiSelectFacet
      label={t('common.filters.spheres')}
      placeholder=''
      isMulti={true}
      isClearable={true}
      isSearchable={true}
      facetName='sphere'
      convertFacetsToOptions={async (facets: FacetValue[]) => {
        return facets.map((f) => {
          return {
            count: f.count,
            label: f.label || f.value,
            value: f.value,
          };
        });
      }}
      updateFilters={(filters: Filters, options: OptionType[]) => {
        const updates = {
          ...filters,
          sphereIds: options.map((o) => o.value),
        };
        return updates;
      }}
      optionsSelected={(filters: Filters, options: OptionType[]) => {
        const sphereIds = filters.sphereIds || [];
        return options.filter((option) =>
          sphereIds.some((id) => option.value === id)
        );
      }}
    />
  );
};

export default SphereMultiSelectFacet;
