import React, { useState, useEffect } from 'react';
import { LocationData } from '../../@types/seen-apps';
import { get } from 'lodash';
import { Container, Row, Col } from 'react-bootstrap';
import { MapPin2LineIcon } from '../../components/RemixIcons';
import styles from './LocationView.module.scss';

interface Props {
  full: boolean;
  location:
    | {
        address: LocationData;
        city: LocationData;
        country: LocationData;
        postalCode: LocationData;
      }
    | undefined;
}

const LocationView = ({ location, full }: Props) => {
  const [locationData, setLocationData] = useState<
    | {
        route?: string;
        country?: string;
        city?: string;
      }
    | undefined
  >();

  useEffect(() => {
    setLocationData(
      location
        ? {
            route: full
              ? get(location, 'route.longName')
              : get(location, 'route.shortName'),
            country: full
              ? get(location, 'country.longName')
              : get(location, 'country.shortName'),
            city: full
              ? get(location, 'city.longName')
              : get(location, 'city.shortName'),
          }
        : undefined
    );
  }, [location, full]);

  if (!locationData) {
    return <div></div>;
  }
  return !full ? (
    <Container>
      <Row>
        <Col xs={1} className={styles.iconCol}>
          <MapPin2LineIcon className={styles.icon} />
        </Col>
        <Col className={styles.noMarginText}>
          {locationData.country} - {locationData.city}
        </Col>
      </Row>
    </Container>
  ) : (
    <Container>
      <Row>
        <Col xs={1} className={styles.iconCol}>
          <MapPin2LineIcon className={styles.icon} />
        </Col>
        <Col className={styles.noMarginText}>
          <Container>
            {locationData.route && (
              <Row>
                <Col className={styles.noMarginText}>{locationData.route}</Col>
              </Row>
            )}
            <Row>
              <Col className={styles.noMarginText}>{locationData.city}</Col>
            </Row>
            <Row>
              <Col className={styles.noMarginText}>{locationData.country}</Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default LocationView;
