import React, { useEffect, useState } from 'react';
import styles from './LikertAnswerComponent.module.scss';
import { LickertMeasureType, LikertOption } from '../../../@types/form-api';
import { getLegend } from '../AnswerComponent';
import Question, { THINKING_OPTIONS } from '../../question/Question';
import { isMobile } from 'react-device-detect';

interface AnswerComponentProps {
  id: string;
  measureType: LickertMeasureType;
  value?: number;
  onChange?: (value?: any) => void;
  disabled?: boolean;
  printMode?: boolean;
}

const LikertAnswerComponent: React.FC<AnswerComponentProps> = ({
  measureType,
  id,
  value,
  onChange,
  disabled,
  printMode,
}) => {
  const [options, setOptions] = useState<LikertOption[]>([]);

  useEffect(() => {
    //TO BE REMOVED COMPATIBILITY MODE
    if (!Array.isArray(measureType.likertOptions.options)) {
      const options: LikertOption[] = Object.keys(
        measureType.likertOptions
      ).map((value, index) => {
        const option: { value: number; legend: string } = (
          measureType.likertOptions as any
        )[value];

        return {
          value: option.value,
          legend: getLegend(option.legend),
          color: (measureType as any).noColor
            ? undefined
            : THINKING_OPTIONS[index]?.color,
        };
      });
      setOptions(options);
      return;
    }
    //END TO BE REMOVED COMPATIBILITY MODE

    setOptions(
      measureType.likertOptions.options.map((option) => ({
        ...option,
        legend: getLegend(option.legend),
      }))
    );
  }, [measureType, measureType.likertOptions]);

  const handleValueChange = (value: number) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className={styles.likert}>
      <Question
        disabled={disabled}
        onValueChange={handleValueChange}
        options={options}
        value={value}
        isMobile={!printMode && isMobile}
      />
    </div>
  );
};

export default LikertAnswerComponent;
