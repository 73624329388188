import React, { FC, useEffect, useState } from 'react';
import { Session } from '../../@types/session-api';
import { SessionTree } from '../../@types/webapp-api';
import api from '../../api/Api';
import { composeTreeView } from '../../tools/sessionTools';
import Select from 'react-select';
import selectStyles from '../../styles/react-select.module.scss';
import styles from './SelectParentSessionInput.module.scss';
import Tree, { TreeNode } from 'rc-tree';
import SessionIcon from './SessionIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getRelevantTranslationFor } from '../../tools/multiLingualTools';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';

type Props = {
  defaultValue?: string;
  value?: Session;
  excludeSessionId?: string;
  sphereId: string;
  category: string;
  onChange: (session?: Session) => void;
};

const composeSessionNodes = (
  sessionTree: SessionTree,
  level: number,
  indent: number
) => {
  return sessionTree.children.length > 0 ? (
    <TreeNode
      expanded
      className='sessionTree'
      icon={
        <SessionIcon className={styles.icon} session={sessionTree.session} />
      }
      key={sessionTree.key}
      title={sessionTree.label}
    >
      {sessionTree.children.map((session) =>
        composeSessionNodes(session, level + 1, indent + 32)
      )}
    </TreeNode>
  ) : (
    <TreeNode
      isLeaf
      icon={
        <SessionIcon className={styles.icon} session={sessionTree.session} />
      }
      key={sessionTree.key}
      title={sessionTree.label}
    />
  );
};

const SelectParentSessionInput: FC<Props> = ({
  value,
  defaultValue,
  excludeSessionId,
  sphereId,
  category,
  onChange,
}) => {
  const [sessionTree, setSessionTree] = useState<SessionTree[]>([]);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [selection, setSelection] = useState<Session>();
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [expandedSessions, setExpandedSessions] = useState<string[]>([]);

  const { t } = useTranslation('i18n');

  useEffect(() => {
    const loadSessions = async () => {
      const response = await api.getSessions({
        max: 10000,
        offset: 0,
        filters: [
          `sphereId=${sphereId}`,
          `isLeaf=false`,
          `category=${category}`,
        ],
      });
      const sessions = excludeSessionId
        ? response.items.filter(
            (s) =>
              s.id !== excludeSessionId &&
              !s.parentSessionIds.some((id) => id === excludeSessionId)
          )
        : response.items;
      setSessionTree(composeTreeView(sessions));
      setSessions(sessions);

      if (defaultValue) {
        const session = sessions.find((s) => s.id === defaultValue);
        setSelection(session);
        if (session) {
          setExpandedSessions([session.id]);
          onChange(session);
        }
      }

      setLoading(false);
    };
    loadSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeSessionId, sphereId, defaultValue]);

  useEffect(() => {
    setSelection(value);
    if (value) {
      setExpandedSessions([value.id]);
    }
  }, [value]);

  const TreeOption = (props: any) => {
    return loading ? (
      <div className={styles.menu}>
        <ReactLoading
          className={styles.loader}
          type={'bars'}
          color={'#DDD'}
          height={'20px'}
          width={'25px'}
        />
        {t('common.loading')}
      </div>
    ) : (
      <Tree
        key='sessionSelection'
        className={styles.menu}
        selectedKeys={selection ? [selection.id] : []}
        selectable
        defaultExpandedKeys={expandedSessions}
        onSelect={(idSelected: any) => {
          setOpen(false);
          onChange(
            sessions.find((s) => idSelected.some((id: string) => s.id === id))
          );
        }}
      >
        {props.options.map((session: SessionTree) =>
          composeSessionNodes(session, 1, 0)
        )}
      </Tree>
    );
  };
  const Selection = (props: any) => {
    return selection ? (
      <div>
        <SessionIcon className={styles.icon} session={selection} />
        {getRelevantTranslationFor(selection.name)}
      </div>
    ) : (
      <div></div>
    );
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
      setExpandedSessions(selection ? [selection.id] : []);
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <Select
          isClearable
          onChange={() => {
            setOpen(false);
            onChange();
          }}
          onMenuOpen={() => {
            setOpen(true);
          }}
          menuIsOpen={open}
          className={selectStyles.reactSelect}
          classNamePrefix='custom-react-select'
          options={sessionTree}
          components={{ Menu: TreeOption, SingleValue: Selection }}
          // onChange={handleRolesSelected}
          placeholder={t(`pages.sphere.sessions.defaultParent`)}
          // autoFocus={!!memberId}
          value={selection as any}
          noOptionsMessage={() => t('common.noValue')}
        />
      </ClickAwayListener>
    </>
  );
};

export default SelectParentSessionInput;
