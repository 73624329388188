import React, { useContext, useEffect, useState } from 'react';
import styles from './Favorite.module.scss';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { FavoriteFillIcon, FavoriteIcon } from '../RemixIcons';
import { useTranslation } from 'react-i18next';
import api from '../../api/Api';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { captureException } from '@sentry/minimal';

interface Props {
  entityId: string;
  profileAttributeName: 'favoriteSphereIds' | 'favoriteSessionIds';
}

const Favorite = ({ entityId, profileAttributeName }: Props) => {
  const { state: globalState } = useContext(GlobalStateContext);
  const { t } = useTranslation('i18n');

  const [favorite, setFavorite] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const favorite = globalState.profile?.[profileAttributeName]?.some(
      (id) => id === entityId
    );
    setFavorite(!!favorite);
  }, [globalState, profileAttributeName, entityId]);

  const handleFavoriteClicked = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (saving) {
      return;
    }
    setSaving(true);

    try {
      let favoriteIds = globalState.profile?.[profileAttributeName] || [];

      // Remove all sessions that do not exists anymore from favorite
      if (
        profileAttributeName === 'favoriteSessionIds' &&
        globalState.sessions
      ) {
        favoriteIds = favoriteIds.filter((id) =>
          globalState.sessions.some((s) => s.id === id)
        );
      }

      if (favorite) {
        favoriteIds = favoriteIds.filter((id) => id !== entityId);
      } else {
        favoriteIds.push(entityId);
      }
      await api.saveUserProfile({
        [profileAttributeName]: favoriteIds,
      });
      toast.success(t(`pages.user.profileUpdated.success`));
    } catch (error) {
      captureException(error);
      toast.error(t(`pages.user.profileUpdated.error`));
    }
    setSaving(false);
  };

  return (
    <div
      className={favorite ? styles.favoriteFilled : styles.favorite}
      onClick={handleFavoriteClicked}
    >
      {saving ? (
        <ReactLoading
          type={'spin'}
          color={'#DDD'}
          height={'20px'}
          width={'20px'}
        />
      ) : favorite ? (
        <FavoriteFillIcon />
      ) : (
        <FavoriteIcon />
      )}
    </div>
  );
};

export default Favorite;
