import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './SessionNameView.module.scss';
import SessionIcon from './SessionIcon';
import { SessionLight } from '../../@types/seen-apps';
import { LightSession as FormLightSession } from '../../@types/form-api';
import { getSessionReportRoute, getSessionRoute } from '../../tools/routeTools';
import { Link } from 'react-router-dom';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { getSessionName } from '../../tools/sessionTools';
import AbilityContext from '../../context/AbilityContext';

interface Props {
  session?: SessionLight | FormLightSession;
  disableLink?: boolean;
}

const SessionNameView = ({ session, disableLink }: Props) => {
  const globalContext = useContext(GlobalStateContext);
  const ability = useContext(AbilityContext);
  const [sessionLink, setSessionLink] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!session) {
      setSessionLink(undefined);
    } else if (
      session.category === 'consumer' &&
      !globalContext.state.mySessions.some((s) => s.id === session.id)
    ) {
      setSessionLink(
        getSessionReportRoute({
          sessionId: session.id,
        })
      );
    } else {
      setSessionLink(
        getSessionRoute({
          sessionId: session.id,
        })
      );
    }
  }, [globalContext.state.mySessions, session]);

  if (!session) {
    return <div></div>;
  }
  const content = (
    <Container className={styles.noMargin}>
      <Row className={styles.noMargin}>
        <Col xs={1} className={styles.iconCol}>
          <SessionIcon className={styles.icon} session={session} />
        </Col>
        <Col className={styles.noMarginText}>
          {getSessionName(session, ability)}
        </Col>
      </Row>
    </Container>
  );

  return sessionLink && !disableLink ? (
    <Link to={sessionLink} className={styles.link}>
      {content}
    </Link>
  ) : (
    <div>{content}</div>
  );
};

export default SessionNameView;
